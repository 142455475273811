import React, {useEffect, useState} from 'react';
import {
    useGoCardlessDropin,
    GoCardlessDropinOptions,
    GoCardlessDropinOnSuccess,
} from '@gocardless/react-dropin';
import rest from "../../../api/http-common";
import {Box, Button, CircularProgress, Typography, useTheme} from "@mui/material";
import {CreditCardOutlined, ErrorOutline, ErrorOutlined, NavigateBeforeOutlined} from "@mui/icons-material";
import {tokens} from "../../../theme";
import {useNavigate} from "react-router-dom";
import {OrderStatus} from "../../../types/OrderType";
import {useOrderContext} from "../../../api/provider/OrderProvider";

function GoCardless(props: any) {
    const [flowID, setFlowID] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    useEffect(() => {
        if(props.order && props.order._id && props.order.status === OrderStatus.checkedOut) {
            let serverResponse: string;
            setError(null);
            rest.post('/payment/gocardless/createBillingRequestFlow', {orderId: props.order._id}).then((response) => {
                if(response.data){
                    serverResponse = response.data;
                }
                serverResponse = response.data ?? JSON.stringify(response);
                setFlowID(response.data.billingRequestFlow.id);
            }).catch((error) => {
                console.log(error)
                setError('Server-Fehler: ' + (serverResponse !== undefined ? serverResponse : error.response.data) + ' - ' + 'Client-Fehler: ' + error.message);
                if(!props.readOnly){
                    props.validate(false);
                }
            });
        } else if (props.order && props.order._id && props.order.status !== OrderStatus.checkedOut && props.order.status !== OrderStatus.open) {
            setError('Bestellung wurde bereits bezahlt oder ist nicht mehr gültig.');
        }
    }, [props.order, props.order.status]);

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: colors.primary[400],
                p: 3,
                borderRadius: 3,
                gap: 3
            }}
        >
            {error ? (
                <>
                    <ErrorOutlined color={"error"} sx={{width: 50, height: 50}}/>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1
                        }}
                    >
                        <Typography variant={"body1"} sx={{color: colors.grey[100]}}>Fehler beim Laden des Zahlungsformulars von GoCardless: <br /> {error}</Typography>
                        <Typography variant={"body1"} sx={{color: colors.grey[100]}}>{'Versuchen Sie einmal die Seite neuzuladen. Für weitere Hilfe kontaktieren Sie bitte unseren Kundensupport: '}
                            <a href={"mailto:support@school.bitbot.eu"} style={{color: colors.grey[100]}}>support@school.bitbot.eu</a>
                        </Typography>
                    </Box>

                </>
                ) : (
                    <>
                        {flowID ?
                            (
                                <>
                                    <DropinButton billingRequestFlowID={flowID} environment={"live"} orderId={props.order._id} validate={props.validate}/>
                                </>
                            ) : (
                                <>
                                    <CircularProgress sx={{color: colors.greenAccent[400]}}/>
                                    <Typography variant={"body1"} sx={{ml: 3, color: colors.grey[100]}}>Lade Zahlungsformular...</Typography>
                                </>
                            )
                        }
                    </>
                )
            }
        </Box>
    );
}

function DropinButton(props: any) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const navigate = useNavigate();
    const { refreshOrder }: any = useOrderContext();

    // Use an existing Billing Request Flow ID likely generated in
    // your backend, then configure hooks for Dropin lifecycle
    // events.
    const config : GoCardlessDropinOptions = ({
        billingRequestFlowID: props.billingRequestFlowID,
        environment: props.environment, // either live or sandbox
        onSuccess: (billingRequest, billingRequestFlow) => {
            console.log(billingRequest, billingRequestFlow);

            rest.post('/payment/gocardless/confirmBillingRequest', {paymentId: billingRequest.id, orderId: props.orderId}).then((response) => {
                props.validate(true);
                refreshOrder();
                navigate('/finish' );
                console.log(response.data);
            });
        },
        onExit: (error, metadata) => {
            console.log(error, metadata);
        },
    });

    // Create state variables via the useGoCardlessDropin hook
    // function, to be used inside a React component render
    // You must call open() before the Dropin will activate.
    const {
        open, exit, ready, error,
    } = useGoCardlessDropin(config);

    return (
        <Box width={"400px"}>
            <Button
                color="primary"
                onClick={() => {
                    if (ready) {
                        open();
                    }
                }
                }
                // sx={{display: {xs: 'none', md: 'block'}}}
                sx={{
                    backgroundColor: theme.palette.mode === 'light' ? colors.primary[900] : colors.primary[300],
                    color: colors.grey[100],
                    [`:focus`]: {
                        backgroundColor: theme.palette.mode === 'light' ? colors.primary[900] : colors.primary[300],
                    },
                    [`:hover`]: {
                        backgroundColor: { xs: theme.palette.mode === 'light' ? colors.primary[900] : colors.primary[300], sm: colors.greenAccent[600]},
                    },
                    height: "50px",
                    width: "100%",
                    display: props.hidden ? "none" : undefined,
                }}
                disabled={!ready}
            >
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}>
                    <Typography sx={{mx: "5px"}}>Bezahlen mit GoCardless (Lastschrift)</Typography>
                    <CreditCardOutlined />
                </Box>
            </Button>
        </Box>
    );
}

export default GoCardless;