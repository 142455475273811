import React, {useEffect} from 'react';
import {Box, Typography, useTheme} from "@mui/material";
import {tokens} from "../../theme";
import PageHeader from "../../components/PageHeader";
import Category from "./components/Category";
import SummaryTable from "./components/SummaryTable";
import OrderStatusDisplay from "./components/OrderStatusDisplay";
import IOrder, {OrderStatus} from "../../types/OrderType";
import {CheckCircle} from "@mui/icons-material";
import {useLoadingContext} from "../../components/LoadingProvider";

function Finish(props: any) {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const {setShowLoading}: any = useLoadingContext();

    useEffect(() => {
        props.validate(true);
    });

    useEffect(() => {
        if(props.order) {
            setShowLoading(false);
        }
    }, [props.order]);

    const getOrderStatus = (order: IOrder): number => {
        if(order && order.status){
            switch (order.status) {
                case OrderStatus.open:
                    return 0;
                case OrderStatus.checkedOut:
                    return 1;
                case OrderStatus.paymentAuthorised:
                    return 2;
                case OrderStatus.paid:
                    return 3;
                case OrderStatus.shipped:
                    return 4;
                case OrderStatus.delivered:
                    return 5;
                default:
                    return 0;
            }
        }
        return 0;
    }

    return (
        <Box >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: 3,
                    p: {xs: 1, md: 3},
                    // width: `calc(100% - 24px)`,
                    boxSizing: "border-box"
                }}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <PageHeader title="ABSCHLUSS" subtitle="Bestellung erfolgreich abgeschlossen" />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        gap: 3,
                        alignItems: 'stretch',
                        justifyContent: 'stretch',
                        flexDirection: {xs: 'column', sm: 'row'},
                    }}
                >
                    <Box display="flex" justifyContent="center" alignItems="center"
                        sx={{
                            p: 3,
                            backgroundColor: colors.primary[400],
                            borderRadius: 3,
                        }}
                    >
                        <CheckCircle sx={{fontSize: 100, color: colors.greenAccent[400]}}/>
                    </Box>

                    <Box
                        sx={{
                            p: 3,
                            backgroundColor: colors.primary[400],
                            borderRadius: 3,
                        }}
                    >
                        <Typography variant="body1">Vielen Dank für Ihre Bestellung. Ihre Bestellung wurde erfolgreich abgeschlossen. <br/><br/>
                            Die Bestellung wird an {props.orgName} mit der Anschrift {props.orgAddress} geliefert. <br/>
                            Wenn Sie Fragen zu Ihrer Bestellung haben, dann wenden Sie sich an Ihren Ansprechpartner {props.orgContactPerson} oder schreiben Sie uns eine Mail unter <a href={"mailto:support@school.bitbot.eu"} style={{color: colors.grey[100]}}>support@school.bitbot.eu</a>.</Typography>
                    </Box>
                </Box>

                <Box display="flex" justifyContent="space-between" alignItems="center" mt={4}>
                    <PageHeader title="STATUS" subtitle="Dies ist der Status Ihrer Bestellung" />
                </Box>

                <Box
                    sx={{
                        p: 3,
                        backgroundColor: colors.primary[400],
                        borderRadius: 3,
                    }}
                >
                    <OrderStatusDisplay activeStep={getOrderStatus(props.order)} orderTimestamps={props.order?.timestamps}/>
                </Box>

                <Box display="flex" justifyContent="space-between" alignItems="center" mt={4}>
                    <PageHeader title="ZUSAMMENFASSUNG" subtitle="Übersicht über Ihre Bestellung" />
                </Box>

                <SummaryTable
                    order={props.order}
                    devices={props.devices}
                    accessories={props.accessories}
                    services={props.services}
                />
            </Box>
        </Box>
    );
}

export default Finish;