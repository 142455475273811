import React, {createContext, useContext, useEffect, useRef, useState} from 'react';
import rest from "../http-common";
import {useAuthContext} from "./AuthProvider";
import {AxiosResponse} from "axios";
import IOrganisation from "../../types/OrgaTypes";
import {Box} from "@mui/material";
import ErrorMessage from "../../components/ErrorMessage";
import {UserRole} from "../../types/UserTypes";

const OrganisationContext = createContext({});
export function OrganisationProvider({children}: any) {
    const [organisation, setOrganisation] = useState<IOrganisation>({} as IOrganisation);
    const [error, setError] = useState<string>("");
    const [showError, setShowError] = useState<boolean>(false);

    // ___ ADMIN ___
    const [organisations, setOrganisations] = useState<IOrganisation[]>([]);

    const { organisationId, accessToken, revokeAccessToken, isUserLoggedIn, userRole }: any = useAuthContext();

    useEffect(() => {
        if (showError) {
            setTimeout(() => {
                setShowError(false);
            }, 5000);
        }
    },[showError]);

    // Clear data on logout
    useEffect(() => {
        if(!isUserLoggedIn){
            setOrganisation({} as IOrganisation);
        }
    }, [isUserLoggedIn]);

    // API calls
    const getUserOrganisation = () => {
        return rest.get("/organisations/" + organisationId).then((res: AxiosResponse<IOrganisation>) => {
            setOrganisation(res.data);
        }).catch((err) => {
            if(err.response.status === 401) {
                revokeAccessToken();
            }
            setError(err.response.data);
            setShowError(true);
        });
    };

    const getAllOrganisations = () => {
        return rest.get("/organisations").then((res: AxiosResponse<IOrganisation[]>) => {
            // setOrganisations(res.data);
        }).catch((err) => {
            if(err.response.status === 401) {
                revokeAccessToken();
            }
            setError(err.response.data);
            setShowError(true);
        });
    }

    const getLocalOrganisationById = (id: string) => {
        return organisations.find((org: IOrganisation) => org._id === id);
    }

    useEffect(() => {
        console.log("OrganisationProvider useEffect" + organisationId);
        if(userRole !== UserRole.admin && organisationId && organisationId !== "" && organisationId !== undefined) {
            getUserOrganisation().then(() => {
                console.log("Organisation loaded");
            });
        } else if (userRole === UserRole.admin) {
            getAllOrganisations().then(() => {
                console.log("AllOrganisations loaded");
            });
        }
    }, [organisationId, userRole]);

    return (
        <OrganisationContext.Provider
            value={{
                organisation,

                organisations,
                getLocalOrganisationById,
            }}
        >
            {/* Alert */}
            {showError && (
                <Box
                    sx={{
                        position: "fixed",
                        bottom: 0,
                        right: 0,
                        margin: 4,
                        zIndex: 2147483001,
                        maxWidth: "100%",
                        // width: "00px"
                    }}
                >
                    <ErrorMessage error={error} severity={"error"} />
                </Box>
            )}
            {children}
        </OrganisationContext.Provider>
    );
}

export const useOrgContext = () => useContext(OrganisationContext);