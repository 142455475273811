import React, {useContext, useEffect, useState} from 'react';
import {
    Box, Button,
    Link,
    formLabelClasses,
    inputClasses,
    GlobalStyles,
    Typography,
    useTheme, IconButton, TextField, InputLabel, InputAdornment
} from "@mui/material";
import {ColorModeContext, tokens} from "../../theme";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import {IAuthOrgRequest, IAuthUserRequest} from "../../types/AuthTypes";
import {useAuthContext} from "../../api/provider/AuthProvider";
import {ArrowBackOutlined, Help, HelpOutlined, Visibility, VisibilityOff} from '@mui/icons-material';
import ErrorMessage from "../../components/ErrorMessage";
import {useLoadingContext} from "../../components/LoadingProvider";
import Footer from "../order/Footer";

interface FormElements extends HTMLFormControlsCollection {
    email: HTMLInputElement;
    password: HTMLInputElement;
    persistent: HTMLInputElement;
}
interface SignInFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}

function LoginSelection(props: any) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();

    const {setShowLoading}: any = useLoadingContext();

    useEffect(() => {
        setShowLoading(false);
    }, []);

    return (
        <Box
            component="main"
            sx={{
                my: 'auto',
                py: 2,
                pb: 5,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: 400,
                maxWidth: '100%',
                mx: 'auto',
                borderRadius: 'sm',
                '& form': {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                },
                [`& .${formLabelClasses.focused}`]: {
                    color: colors.grey[100],
                },
                [`& .${inputClasses.focused}`]: {
                    color: colors.grey[100],
                    '& fieldset': {
                        borderColor: colors.grey[100] + ' !important',
                    }
                },
            }}
        >
            <div>
                <Typography variant="h2" fontWeight="lg">
                    Willkommen im Bitbot Bestellportal!
                </Typography>
                <Typography variant="body1" sx={{my: 1, mb: 3}}>
                    Bitte wählen Sie eine Anmeldeoption aus.
                </Typography>
            </div>
            <Button
                fullWidth
                sx={{
                    backgroundColor: colors.primary[400],
                    color: colors.grey[100],
                    [`:focus`]: {
                        backgroundColor: colors.primary[400],
                    },
                    [`:hover`]: {
                        backgroundColor: { xs: colors.primary[400], sm: colors.greenAccent[600]},
                    },
                    height: "50px",
                }}
                onClick={() => navigate("/login/org")}
            >
                <Typography variant="body1" fontWeight="lg">
                    Anmeldung mit Organisations-Passwort
                </Typography>
            </Button>
            <Typography variant="body1" sx={{my: 1, mt: 1, mb: 1, textAlign: "center"}}>
                ODER
            </Typography>
            <Button
                fullWidth
                sx={{
                    backgroundColor: colors.primary[400],
                    color: colors.grey[100],
                    [`:focus`]: {
                        backgroundColor: colors.primary[400],
                    },
                    [`:hover`]: {
                        backgroundColor: { xs: colors.primary[400], sm: colors.greenAccent[600]},
                    },
                    height: "50px",
                }}
                onClick={() => navigate("/login/user")}
            >
                <Typography variant="body1" fontWeight="lg">
                    Anmeldung mit Benutzerdaten
                </Typography>
            </Button>
        </Box>
    )
}

function UserLogin(props: any) {
    const [error, setError] = useState<string>("");
    const [showError, setShowError] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {userAuth}: any = useAuthContext();

    useEffect(() => {
        if (showError) {
            setTimeout(() => {
                setShowError(false);
            }, 10000);
        }
    },[showError]);

    return (
        <Box
            component="main"
            sx={{
                my: 'auto',
                py: 2,
                pb: 5,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: 400,
                maxWidth: '100%',
                mx: 'auto',
                borderRadius: 'sm',
                '& form': {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                },
                [`& .${formLabelClasses.focused}`]: {
                    color: colors.grey[100],
                },
                [`& .${inputClasses.focused}`]: {
                    color: colors.grey[100] + ' !important',
                    '& fieldset': {
                        borderColor: colors.grey[100] + ' !important',
                    }
                },
            }}
        >
            <div>
                <Typography variant="h2" fontWeight="lg">
                    Willkommen im Bitbot Bestellportal!
                </Typography>
                <Typography variant="body1" sx={{ my: 1, mb: 3 }}>
                    Bitte melden Sie sich mit Ihren Benutzerdaten an.
                </Typography>
            </div>
            {showError && (
                <Box
                    sx={{
                        // position: "absolute",
                        // bottom: 0,
                        // right: 0,
                        // margin: 4,
                        zIndex: 1000,
                        width: "100%",
                        // width: "00px"
                    }}
                >
                    <ErrorMessage error={error} severity={"error"} />
                </Box>
            )}
            <form
                onSubmit={(event: React.FormEvent<SignInFormElement>) => {
                    event.preventDefault();
                    const formElements = event.currentTarget.elements;
                    const data = {
                        eMail: formElements.email.value,
                        password: formElements.password.value,
                    };
                    userAuth(data as IAuthUserRequest).then((response: any) => {
                        console.log(JSON.stringify(response, null, 2));
                    }).catch((err: any) => {
                        setError(err);
                        setShowError(true);
                        console.log(err);
                    });
                }}
            >
                <TextField required variant="outlined" placeholder="Geben Sie hier Ihre E-Mail ein" type="email" name="email" label="E-Mail" autoComplete="username"/>
                <TextField required variant="outlined" label="Passwort" placeholder="Geben Sie hier Ihr Passwort ein" type={showPassword ? "text" : "password"} name="password" autoComplete="password"
                           InputProps={{ // <-- This is where the toggle button is added.
                               endAdornment: (
                                   <InputAdornment position="end">
                                       <IconButton
                                           aria-label="toggle password visibility"
                                           onClick={handleClickShowPassword}
                                           onMouseDown={handleMouseDownPassword}
                                       >
                                           {showPassword ? <Visibility /> : <VisibilityOff />}
                                       </IconButton>
                                   </InputAdornment>
                               )
                           }}
                />
                {/*<Box*/}
                {/*    sx={{*/}
                {/*        display: 'flex',*/}
                {/*        justifyContent: 'space-between',*/}
                {/*        alignItems: 'center',*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <Link fontSize="sm" href="#replace-with-a-link" fontWeight="lg" color={colors.grey[200]}>*/}
                {/*        Passwort vergessen?*/}
                {/*    </Link>*/}
                {/*</Box>*/}
                <Button
                    type="submit"
                    fullWidth
                    sx={{
                        backgroundColor: colors.primary[400],
                        color: colors.grey[100],
                        [`:focus`]: {
                            backgroundColor: colors.primary[400],
                        },
                        [`:hover`]: {
                            backgroundColor: { xs: colors.primary[400], sm: colors.greenAccent[600]},
                        },
                        height: "50px",
                    }}
                >
                    Login
                </Button>
            </form>
            {/* Alert */}
        </Box>
    );
}

function OrgLogin(props: any) {
    const [error, setError] = useState<string>("");
    const [showError, setShowError] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { orgAuth }: any = useAuthContext();

    useEffect(() => {
        if (showError) {
            setTimeout(() => {
                setShowError(false);
            }, 10000);
        }
    },[showError]);

    return (
        <Box
            component="main"
            sx={{
                my: 'auto',
                py: 2,
                pb: 5,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: 400,
                maxWidth: '100%',
                mx: 'auto',
                borderRadius: 'sm',
                '& form': {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                },
                [`& .${formLabelClasses.focused}`]: {
                    color: colors.grey[100],
                },
                [`& .${inputClasses.focused}`]: {
                    color: colors.grey[100] + ' !important',
                    '& fieldset': {
                        borderColor: colors.grey[100] + ' !important',
                    }
                },
            }}
        >
            {/*<Box*/}
            {/*    sx={{*/}
            {/*        display: 'flex',*/}
            {/*        justifyContent: 'flex-start',*/}
            {/*        alignItems: 'center',*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <IconButton*/}
            {/*        sx={{*/}
            {/*            color: colors.greenAccent[400],*/}
            {/*        }}*/}
            {/*        onClick={() => navigate("/login")}*/}
            {/*    >*/}
            {/*        <ArrowBackOutlined />*/}
            {/*    </IconButton>*/}
            {/*</Box>*/}
            <Box>
                <Typography variant="h2" fontWeight="lg">
                    Willkommen im Bitbot Bestellportal!
                </Typography>
                <Typography variant="body1" sx={{ my: 1, mb: 3 }}>
                    Bitte melden Sie sich mit dem Passwort an, dass Sie von Ihrer Organisation erhalten haben.
                </Typography>
            </Box>
            {showError && (
                <Box
                    sx={{
                        // position: "absolute",
                        // bottom: 0,
                        // right: 0,
                        // margin: 4,
                        zIndex: 1000,
                        width: "100%",
                        // width: "00px"
                    }}
                >
                    <ErrorMessage error={error} severity={"error"} />
                </Box>
            )}
            <form
                onSubmit={(event: React.FormEvent<SignInFormElement>) => {
                    event.preventDefault();
                    const formElements = event.currentTarget.elements;
                    const data = {
                        groupPassword: formElements.password.value,
                    };
                    orgAuth(data as IAuthOrgRequest).then((response: any) => {
                        console.log(JSON.stringify(response, null, 2));
                    }).catch((error: any) => {
                        console.log(JSON.stringify(error, null, 2));
                        setError(error);
                        setShowError(true);
                    });
                }}
            >
                <TextField required variant="outlined" label="Organisations-Passwort" placeholder="Geben Sie hier Ihr Passwort von Ihrer Organisation ein" type={showPassword ? "text" : "password"} name="password" autoComplete="password"
                           InputProps={{ // <-- This is where the toggle button is added.
                               endAdornment: (
                                   <InputAdornment position="end">
                                       <IconButton
                                           aria-label="toggle password visibility"
                                           onClick={handleClickShowPassword}
                                           onMouseDown={handleMouseDownPassword}
                                           tabIndex={-1}
                                       >
                                           {showPassword ? <Visibility /> : <VisibilityOff />}
                                       </IconButton>
                                   </InputAdornment>
                               )
                           }}
                />
                <Button
                    type="submit"
                    fullWidth
                    sx={{
                        backgroundColor: colors.primary[400],
                        color: colors.grey[100],
                        [`:focus`]: {
                            backgroundColor: colors.primary[400],
                        },
                        [`:hover`]: {
                            backgroundColor: { xs: colors.primary[400], sm: colors.greenAccent[600]},
                        },
                        height: "50px",
                    }}
                >
                    Login
                </Button>
            </form>
        </Box>
    );
}


function Login(props: any) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const navigate = useNavigate();
    const {setShowLoading}: any = useLoadingContext();

    useEffect(() => {
        setShowLoading(false);
    }, []);

    return (
        <>
            <GlobalStyles
                styles={{
                    ':root': {
                        '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
                        '--Cover-width': '40vw', // must be `vw` only
                        '--Form-maxWidth': '700px',
                        '--Transition-duration': '0.4s', // set to `none` to disable transition
                    },
                }}
            />
            <Box
                sx={{
                    width:
                        'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
                    transition: 'width var(--Transition-duration)',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    position: 'relative',
                    zIndex: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    backdropFilter: 'blur(4px)',
                    backgroundColor: theme.palette.mode === "dark" ? (colors.primary[500] + 'D9') : ('#fcfcfcD9'),
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100dvh',
                        width:
                            'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
                        maxWidth: '100%',
                        px: 2,
                    }}
                >
                    <Box
                        component="header"
                        sx={{
                            py: 3,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <img src={(theme.palette.mode === "dark") ? "/pics/Bitbot Logo weiß.svg" : "/pics/Bitbot Logo dunkel.svg"} width="120px"/>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 1,
                                alignItems: 'center',
                            }}
                        >
                            {/*<IconButton onClick={() => navigate("/help")}>*/}
                            {/*    <HelpOutlined />*/}
                            {/*</IconButton>*/}
                            <IconButton onClick={colorMode.toggleColorMode}>
                                {theme.palette.mode === "dark" ? <DarkModeOutlinedIcon /> : <LightModeOutlinedIcon />}
                            </IconButton>
                        </Box>
                    </Box>
                    <Routes>
                        <Route path="/login" element={<LoginSelection />}/>
                        <Route path="/login/user" element={<UserLogin />}/>
                        <Route path="/login/org" element={<OrgLogin />}/>
                        {/*<Route path="*" element={<LoginSelection />}/>*/}
                        <Route path="*" element={<Navigate to="/login" replace />}/>
                    </Routes>
                    <Footer />
                </Box>
            </Box>
            <Box
                sx={{
                    height: '100%',
                    position: 'fixed',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
                    transition:
                        'background-image var(--Transition-duration), left var(--Transition-duration) !important',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    backgroundColor: 'background.level1',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: `url('/pics/Login Background.jpg')`,
                }}
            />
        </>
    );
}

export default Login;