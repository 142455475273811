import React, {useEffect, useState} from "react";
import PageHeader from "../../../../components/PageHeader";
import {
    Box,
    Button,
    Chip,
    Dialog, formLabelClasses,
    IconButton,
    Input, inputClasses, InputLabel,
    MenuItem,
    Select, SelectChangeEvent,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import {tokens} from "../../../../theme";
import {useProductContext} from "../../../../api/provider/ProductProvider";
import {IUserCustomer, UserRole} from "../../../../types/UserTypes";
import {DataGrid, GridToolbarContainer, GridToolbarExport} from "@mui/x-data-grid";
import {
    CancelOutlined,
    CloseOutlined,
    CreditCardOutlined,
    CreditScoreOutlined,
    DoneOutlined,
    EditNoteOutlined,
    ErrorOutlined,
    ErrorOutlineOutlined,
    HomeWorkOutlined,
    InventoryOutlined,
    LocalShippingOutlined,
    LockOpenOutlined,
    LockOutlined, MoneyOffOutlined,
    NavigateNextOutlined, ReceiptLongOutlined, TranscribeOutlined,
    VisibilityOffOutlined,
    VisibilityOutlined
} from "@mui/icons-material";
import {useOrderContext} from "../../../../api/provider/OrderProvider";
import IOrder, {OrderStatus} from "../../../../types/OrderType";
import {useUserContext} from "../../../../api/provider/UserProvider";
import {Currency} from "../../../../types/PriceType";
import OrderStatusDisplay from "../../../order/components/OrderStatusDisplay";
import SummaryTable from "../../../order/components/SummaryTable";
import {ProductType} from "../../../../types/ProductTypes";
import BackendOrderItems from "../productsOrder/BackendOrderItems";
import ConfirmationDialog from "../../../../components/ConfirmationDialog";
import {OrderItem} from "../../../../../../../src/core/models/order";

function BackendStudents(props: any) {
    const [organisationId, setOrganisationId] = React.useState<string>("");
    const [selectedProducts, setSelectedProducts] = React.useState<string[]>([]);
    // const [availableProducts, setAvailableProducts] = React.useState<any>([]);

    const [open, setOpen] = React.useState<boolean>(false);
    const [selectedOrder, setSelectedOrder] = React.useState<IOrder | null>(null);
    const [selectedChild, setSelectedChild] = React.useState<IUserCustomer | null>(null);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { availableProducts }: any = useProductContext();
    const { orders }: any = useOrderContext();
    const { users, filterUsersByRole }: any = useUserContext();

    const handleRowSelection = (params: any) => {
        setSelectedOrder(getCorrespondingOrder(params.row._id));
        setSelectedChild(params.row);
        setOpen(true);
    }

    // useEffect(() => {
    //     console.log("productVisibility", productVisibility);
    //     if (availableProducts) {
    //         setAvailableProducts(productVisibility);
    //     }
    // }, [availableProducts]);

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    const getCorrespondingOrder = (childId: string): IOrder | null => {
        const customers: IUserCustomer[] = filterUsersByRole(users, UserRole.customer);

        // Find parent user
        let parent: IUserCustomer | null = null;
        for (const user of customers) {
            if(user.children?.includes(childId)) {
                parent = user;
                break;
            }
        }

        // Find order
        if(parent) {
            for (const order of orders) {
                if(order.userId === parent._id) {
                    return order;
                }
            }
        }

        return null;
    }


    const columns = [
        {
            field: "_id",
            headerName: "ID",
            flex: 1,
            minWidth: 175,
        },
        {
            field: "firstName",
            headerName: "Vorname",
            flex: 0.5,
            minWidth: 150
        },
        {
            field: "lastName",
            headerName: "Nachname",
            flex: 0.5,
            minWidth: 150
        },
        {
            field: "class",
            headerName: "Klasse",
            flex: 0.5,
            minWidth: 150
        },
        {
            field: "number",
            headerName: "Bestellnummer",
            flex: 0.5,
            minWidth: 50,
            valueGetter: (params: any) => {
                return getCorrespondingOrder(params.row._id)?.number;
            },
        },
        {
            field: "orderId",
            headerName: "Bestell-ID",
            flex: 1,
            minWidth: 175,
            valueGetter: (params: any) => {
                return getCorrespondingOrder(params.row._id)?._id;
            },
        },
        {
            field: "billingAddressName",
            headerName: "Vater/Mutter",
            flex: 1,
            minWidth: 140,
            valueGetter: (params: any) => {
                return getCorrespondingOrder(params.row._id)?.billingAddress.name;
            }
        },
        {
            field: "organisationId",
            headerName: "Organisation",
            minWidth: 175,
            flex: 1,
            valueGetter: (params: any) => {
                return getCorrespondingOrder(params.row._id)?.organisationId;
            }
        },
        {
            field: "status",
            headerName: "Status",
            flex: 0.75,
            minWidth: 150,
            valueGetter: (params: any) => {
                return getCorrespondingOrder(params.row._id)?.status;
            },
            renderCell: (params: any) => {
                return <BackendOrderStatusDisplay status={getCorrespondingOrder(params.row._id)?.status} />
            }
        },
        {
            field: "orderItemsCount",
            headerName: "Anzahl Objekte",
            flex: 0.5,
            minWidth: 75,
            valueGetter: (params: any) => {
                return getCorrespondingOrder(params.row._id)?.orderItems.filter((orderItem: OrderItem) => { return orderItem.childId === params.row._id; }).length;
            }
        },
        {
            field: "paymentInformation",
            headerName: "Zahlungsmittel",
            flex: 0.5,
            minWidth: 75,
            valueGetter: (params: any) => {
                return getCorrespondingOrder(params.row._id)?.paymentInformation;
            },
        }
    ]

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <PageHeader title="SCHÜLER*INNEN" subtitle="Überprüfen Sie den Status Ihrer Bestellungen in Schüler*innen-Zuordnung." />
            </Box>

            <Box>
                Auswahl der Organisation für Admins
            </Box>

            <Box
                sx={{
                    mt: 6,
                    height: {xs: "75vh", md: "80vh"},
                    "& .MuiDataGrid-root": {
                        border: "none"
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none"
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.primary[400],
                        color: colors.greenAccent[100],
                        borderBottom: "none",
                        borderRadius: "10px 10px 0 0"
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: theme.palette.background.default,
                    },
                    "& .MuiDataGrid-footerContainer": {
                        backgroundColor: colors.primary[400],
                        color: colors.greenAccent[100],
                        borderTop: "none",
                        borderRadius: "0 0 10px 10px"
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.greenAccent[200]} !important`
                    }
                }}
            >
                <DataGrid
                    loading={!(orders.length > 0)}
                    getRowId={(row) => row._id}
                    columns={columns}
                    rows={filterUsersByRole(users, UserRole.child)}
                    onRowClick={handleRowSelection}
                    slots={{
                        toolbar: CustomToolbar
                    }}
                />
            </Box>

            <BackendOrderPopup open={open} onClose={() => setOpen(false)} order={selectedOrder} child={selectedChild}/>
        </Box>
    );
}

function BackendOrderStatusDisplay({status}: any) {
    let statusText: string = "";
    let color: "primary" | "success" | "error" | "default" | "secondary" | "info" | "warning" | undefined = undefined;
    let icon: JSX.Element = (<></>);
    let variant: "outlined" | "filled" | undefined = undefined;

    switch (status) {
        case OrderStatus.open:
            statusText = "Erstellt / Offen";
            color = "default";
            icon = (<EditNoteOutlined />);
            variant = "outlined";
            break;
        case OrderStatus.checkedOut:
            statusText = "Verifiziert";
            color = "info";
            icon = (<InventoryOutlined />);
            variant = "outlined";
            break;
        case OrderStatus.paymentAuthorised:
            statusText = "Zahlung autorisiert";
            color = "success";
            icon = (<CreditCardOutlined />);
            variant = "outlined";
            break;
        case OrderStatus.paid:
            statusText = "Bezahlt";
            color = "success";
            icon = (<CreditScoreOutlined />);
            variant = "filled";
            break;
        case OrderStatus.shipped:
            statusText = "Versendet";
            color = "secondary";
            icon = (<LocalShippingOutlined />);
            variant = "filled";
            break;
        case OrderStatus.delivered:
            statusText = "Zugestellt";
            color="warning";
            icon = (<HomeWorkOutlined />);
            variant = "filled";
            break;
        case OrderStatus.cancelled:
            statusText = "Storniert";
            color = "error";
            icon = (<CancelOutlined />);
            variant = "filled";
            break;
        default:
            statusText = "Fehler";
            color = "error";
            icon = (<ErrorOutlineOutlined />);
            variant = "filled";
            break;
    }
    return (
        <Chip
            label={statusText}
            color={color}
            icon={icon}
            variant={variant}
        />
    );
}

function BackendOrderPopup({order, open, onClose, child}: any) {
    const [selectedStatus, setSelectedStatus] = useState(order?.status);
    const [openConfimDialog, setOpenConfirmDialog] = useState(false);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { setOrderStatus }: any = useOrderContext();
    const { availableProducts, filterProductsByType }: any = useProductContext();

    const getOrderStatus = (order: IOrder): number => {
        if(order && order.status){
            switch (order.status) {
                case OrderStatus.open:
                    return 0;
                case OrderStatus.checkedOut:
                    return 1;
                case OrderStatus.paymentAuthorised:
                    return 2;
                case OrderStatus.paid:
                    return 3;
                case OrderStatus.shipped:
                    return 4;
                case OrderStatus.delivered:
                    return 5;
                default:
                    return 0;
            }
        }
        return 0;
    }

    useEffect(() => {
        console.log("OrderStatus - Before", selectedStatus);
        console.log("OrderStatus - After", order?.status);
        setSelectedStatus(order?.status);
    }, [order]);

    const handleChange = (event: SelectChangeEvent<HTMLInputElement>) => {
        setSelectedStatus(event.target.value);
        setOpenConfirmDialog(true);
    }

    const handleSubmit = () => {
        setOpenConfirmDialog(false);
        if(order && selectedStatus){
            setOrderStatus(order._id, selectedStatus, order.paymentInformation);
        }
        onClose();
    }

    const handleAbort = () => {
        setSelectedStatus(order?.status);
        setOpenConfirmDialog(false);
    }

    return (
        <Dialog
            open={open}
            onClose={() => {onClose()}}
            maxWidth="md"
            fullWidth
            sx={{
                "& .MuiDialog-paper": {
                    borderRadius: 3,
                },
            }}
        >
            <IconButton onClick={() => {onClose()}} sx={{position: "absolute", top: 1, right: 1, color: colors.primary[100]}}>
                <CloseOutlined />
            </IconButton>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    backgroundColor: theme.palette.background.default,
                    borderRadius: 3,
                    p: 3,
                    width: "100%",
                    gap: 3,
                    flexDirection: "column"
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: theme.palette.background.default,
                        width: "100%",
                        gap: 3,
                    }}
                >
                    <PageHeader title="DETAILS" subtitle="Details zur Bestellung" />

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <ReceiptLongOutlined sx={{width: 50, height: 50}} />
                    </Box>
                </Box>

                <Box
                    sx={{
                        backgroundColor: colors.primary[400],
                        borderRadius: 3,
                        p: 3,
                        width: "100%",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: {xs: "flex-start", md: "center"},
                            flexDirection: {xs: "column", md: "row"},
                            gap: {xs: 3, md: 0},
                        }}
                    >
                        <Box>
                            <Typography variant="h5" color={colors.greenAccent[400]} sx={{mb: 1}}>Allgemein</Typography>
                            <Typography variant="body1" >Bestell-ID: {order?._id}</Typography>
                            <Typography variant="body1" >Bestell-Nummer: {order?.number}</Typography>
                            <Typography variant="body1" >Zahlungsmittel: {order?.paymentInformation}</Typography>
                        </Box>

                        <Box
                            sx={{
                                // display: "flex",
                                // justifyContent: "center",
                                // alignItems: "center",

                                // width: {xs: "100%", md: "50%"},
                                boxSizing: "border-box",
                                '& form': {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 2,
                                },
                                [`& .${formLabelClasses.focused}`]: {
                                    color: colors.grey[100],
                                },
                                [`& .${inputClasses.focused}`]: {
                                    color: colors.grey[100] + ' !important',
                                    '& fieldset': {
                                        borderColor: colors.grey[100] + ' !important',
                                    }
                                },
                            }}
                        >
                            <Typography variant={"body1"}>Bestell-Status ändern</Typography>
                            <Select
                                value={selectedStatus ?? OrderStatus.open}
                                onChange={handleChange}
                                sx={{
                                    width: "200px"
                                }}
                            >
                                <MenuItem disabled value={OrderStatus.open}>Offen</MenuItem>
                                <MenuItem disabled value={OrderStatus.checkedOut}>Verifiziert</MenuItem>
                                <MenuItem disabled value={OrderStatus.paymentAuthorised}>Zahlung authorisiert</MenuItem>
                                <MenuItem disabled={order?.paymentInformation === "GOCARDLESS" || !order?.paymentInformation || !(order?.status === OrderStatus.paymentAuthorised)} value={OrderStatus.paid}>{order?.status === OrderStatus.paid ? 'Zahlung eingegangen' : 'Als bezahlt markieren'}</MenuItem>
                                <MenuItem disabled={!(order?.status === OrderStatus.paid)} value={OrderStatus.shipped}>{order?.status === OrderStatus.shipped ? 'Versendet' : 'Als versendet markieren'}</MenuItem>
                                <MenuItem disabled={!(order?.status === OrderStatus.shipped)} value={OrderStatus.delivered}>{order?.status === OrderStatus.delivered ? 'Zugestellt' : 'Als zugestellt markieren'}</MenuItem>
                                <MenuItem disabled={(order?.status === OrderStatus.cancelled)} value={OrderStatus.cancelled}>{order?.status === OrderStatus.cancelled ? 'Storniert' : 'Als storniert markieren'}</MenuItem>
                            </Select>
                        </Box>
                    </Box>
                </Box>

                <Box
                    sx={{
                        backgroundColor: colors.primary[400],
                        borderRadius: 3,
                        p: 3,
                        width: "100%",
                    }}
                >
                    <Typography variant="h5" color={colors.greenAccent[400]} sx={{mb: 1}}>Bestell-Status</Typography>
                    <OrderStatusDisplay activeStep={getOrderStatus(order)} orderTimestamps={order?.timestamps}/>
                </Box>

                <Box
                    sx={{
                        backgroundColor: colors.primary[400],
                        borderRadius: 3,
                        p: 3,
                        width: "100%",
                    }}
                >
                    <Typography variant="h5" color={colors.greenAccent[400]} sx={{mb: 1}}>Kundendaten</Typography>
                    <Typography variant="body1" >Vor- und Nachname: {order?.billingAddress?.name}</Typography>
                    <Typography variant="body1" >Straße und Hausnummer: {order?.billingAddress?.street + ' ' + order?.billingAddress?.houseNumber}</Typography>
                    <Typography variant="body1" >Zusatz-Information: {order?.billingAddress?.additionalInformation}</Typography>
                    <Typography variant="body1" >Postleitzahl: {order?.billingAddress?.postalCode}</Typography>
                    <Typography variant="body1" >Stadt: {order?.billingAddress?.city}</Typography>
                </Box>

                <Box
                    sx={{
                        backgroundColor: colors.primary[400],
                        borderRadius: 3,
                        p: 3,
                        width: "100%",
                    }}
                >
                    <Typography variant="h5" color={colors.greenAccent[400]} sx={{mb: 1}}>Schüler*in Daten</Typography>
                    <Typography variant="body1" >Vor- und Nachname: {child?.firstName + ' ' + child?.lastName}</Typography>
                    <Typography variant="body1" >Klasse: {child?.class}</Typography>
                </Box>

                <Box
                    sx={{
                        backgroundColor: colors.primary[400],
                        borderRadius: 3,
                        p: 3,
                        width: "100%",
                    }}
                >
                    <Typography variant="h5" color={colors.greenAccent[400]} sx={{mb: 1}}>Bestellte Artikel</Typography>

                    <BackendOrderItems
                        child={child}
                        order={order}
                        devices={filterProductsByType(availableProducts, ProductType.tablet)}
                        accessories={filterProductsByType(availableProducts, ProductType.case).concat(filterProductsByType(availableProducts, ProductType.pencil).concat(filterProductsByType(availableProducts, ProductType.otherAccessories)))}
                        services={filterProductsByType(availableProducts, ProductType.insurance).concat(filterProductsByType(availableProducts, ProductType.software)).concat(filterProductsByType(availableProducts, ProductType.deviceSecurity)).concat(filterProductsByType(availableProducts, ProductType.internetSecurity))}
                    />
                </Box>




                <Box width={"100%"}
                     sx={{
                         display: "flex",
                         justifyContent: "flex-end",
                         alignItems: "flex-end",
                     }}
                >
                    {/*<Button*/}
                    {/*    color="primary"*/}
                    {/*    onClick={() => onSelection()}*/}
                    {/*    // sx={{display: {xs: 'none', md: 'block'}}}*/}
                    {/*    sx={{*/}
                    {/*        backgroundColor: colors.primary[400],*/}
                    {/*        color: colors.grey[100],*/}
                    {/*        [`:focus`]: {*/}
                    {/*            backgroundColor: colors.primary[400],*/}
                    {/*        },*/}
                    {/*        [`:hover`]: {*/}
                    {/*            backgroundColor: { xs: colors.primary[400], sm: colors.greenAccent[600]},*/}
                    {/*        },*/}
                    {/*        height: "50px",*/}
                    {/*        width: "100%",*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <Box sx={{*/}
                    {/*        display: "flex",*/}
                    {/*        alignItems: "center",*/}
                    {/*        justifyContent: "space-between",*/}
                    {/*    }}>*/}
                    {/*        <Typography sx={{ml: "5px"}}>Bestellung finanzieren</Typography>*/}
                    {/*        <NavigateNextOutlined />*/}
                    {/*    </Box>*/}
                    {/*</Button>*/}
                </Box>
            </Box>

            <ConfirmationDialog title="Achtung" text={"Wollen Sie den Bestell-Status wirklich auf " + selectedStatus + ' ändern?'}  open={openConfimDialog} onAbort={handleAbort} onConfirm={handleSubmit} />
        </Dialog>
    )
}



export default BackendStudents;