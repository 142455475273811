import React from 'react';
import Topbar from "../global/Topbar";
import {Navigate, Route, Routes} from "react-router-dom";
import Dashboard from "./dashboard/Dashboard";
import {MyProSidebarProvider} from "../global/sidebar/sidebarContext";
import BackendProductVisibilityOrganisation from "./organisation/productVisibility/BackendProductVisibilityOrganisation";
import BackendOrganisation from "./organisation/organisation/BackendOrganisation";
import BackendComingSoon from "./BackendComingSoon";
import BackendCustomers from "./organisation/customers/BackendCustomers";
import BackendOrders from "./organisation/orders/BackendOrders";
import BackendProductsOrder from "./organisation/productsOrder/BackendProductsOrder";
import BackendStudents from "./organisation/students/BackendStudents";

function Backend(props: any) {
    return (
        <MyProSidebarProvider>
            <div style={{ height: "100%", width: "100%" }}>
                <main>
                    <Topbar />
                    <Routes>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/organisation" element={<BackendOrganisation />} />
                        <Route path="/products/visibility" element={<BackendProductVisibilityOrganisation />} />
                        <Route path="/orders" element={<BackendOrders />} />
                        <Route path="/customers" element={<BackendCustomers />} />
                        <Route path="/orders/products" element={<BackendProductsOrder />} />
                        <Route path="/students" element={<BackendStudents />} />
                        {/*<Route path="/line" element={<Line />} />*/}
                        {/*<Route path="/faq" element={<FAQ />} />*/}
                        {/*<Route path="/calendar" element={<Calendar />} />*/}
                        {/*<Route path="/geography" element={<Geography />} />*/}
                        {/*<Route path="*" element={<Navigate to={"/"} />} />*/}
                        <Route path="*" element={<BackendComingSoon />} />
                    </Routes>
                </main>
            </div>
        </MyProSidebarProvider>
    );
}

export default Backend;