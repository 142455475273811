import React, {createContext, useContext, useEffect, useState} from 'react';
import {Box, CircularProgress, CssBaseline, ThemeProvider, useTheme} from "@mui/material";
import {ColorModeContext, tokens, useMode} from "../theme";

const LoadingContext = createContext({});
export function LoadingProvider({children}: any) {
    const [theme, colorMode]: any = useMode();
    const colors = tokens(theme.palette.mode);

    const [showLoading, setShowLoading] = useState<boolean>(true);

    useEffect(() => {
        console.log("LoadingProvider: useEffect: showLoading: " + showLoading);
    }, [showLoading]);

    return (
        <LoadingContext.Provider
            value={{
                showLoading,
                setShowLoading,
            }}
        >
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Box
                        sx={{
                            display: showLoading ? 'flex' : 'none',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 100,
                            backgroundColor: theme.palette.background.default,
                        }}
                    >
                        <CircularProgress sx={{
                            color: colors.greenAccent[400],
                        }}/>
                    </Box>
                </ThemeProvider>
            </ColorModeContext.Provider>

            {children}
        </LoadingContext.Provider>
    );
}

export const useLoadingContext = () => useContext(LoadingContext);