import {Box, Button, Dialog, IconButton, Typography, useTheme} from "@mui/material";
import {tokens} from "../../../theme";
import React, {useEffect, useState} from "react";
import PageHeader from "../../../components/PageHeader";
import {
    BlockOutlined,
    CloseOutlined,
    DoneOutlined,
    NavigateBeforeOutlined,
    NavigateNextOutlined
} from "@mui/icons-material";
import {IUserChild} from "../../../types/UserTypes";
import {IProductVariant} from "../../../types/ProductTypes";
import Price, {Currency} from "../../../types/PriceType";

function ProductSelectionPopup(props: any){
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [selection, setSelection] = useState<Map<string, string>>(new Map());
    const [selectedStudents, setSelectedStudents] = useState<IUserChild[]>([]);

    const [page, setPage] = useState<number>(0);

    useEffect(() => {
        console.log("selections: ", props.selections);
        console.log("students: ", props.students);
        console.log("selection: ", selection);
        // console.log(props.students.length === 1 && (!props.selections || props.selections.size === 0) && (!selection || selection.size === 0));
        if(props.students.length === 1 && (!props.selections || props.selections.size === 0) && (!selection || selection.size === 0)){
            setSelectedStudents(props.students);
            setPage(1);
        }
    }, []);

    // If only one student is available, select it automatically
    useEffect(() => {
        if(props.students.length === 1 && (!props.selections || props.selections.size === 0) && (!selection || selection.size === 0)){
            setSelectedStudents(props.students);
            setPage(1);
        } else {
            setSelectedStudents(getSelectedStudents(selection));
            setPage(0);
        }
    }, [props.students]);

    useEffect(() => {
        if(props.selections && props.selections.size > 0){
            setSelection(props.selections);
            setSelectedStudents(getSelectedStudents(props.selections));
            if(props.students.length === 1){
                setPage(1);
            }
        }
    }, [props.selections]);

    // Get selected students
    const getSelectedStudents = (selection: Map<string, string>): IUserChild[] => {
        const result: IUserChild[] = [];
        const students: IUserChild[] = props.students;
        for (const student of students) {
            if(selection.get(student._id as string)){
                result.push(student);
            }
        }

        return result;
    }

    const abort = () => {
        props.onClose();
        if(props.students.length !== 1){
            setSelectedStudents(getSelectedStudents(props.selection ?? selection));
            setPage(0);
        } else {
            setSelectedStudents(props.students);
            setPage(1);
        }
    }

    const submit = (selection: Map<string, string>) => {
        console.log("submit: ", selection);
        props.onSubmit(selection);
        setSelection(selection);
        setSelectedStudents(getSelectedStudents(selection));
        if(props.students.length === 1){
            setPage(1);
        } else {
            setPage(0);
        }
    }

    return (
        <Dialog
            open={props.open}
            onClose={() => {abort()}}
            maxWidth="sm"
            fullWidth
            sx={{
                "& .MuiDialog-paper": {
                    borderRadius: 3,

                },
            }}
        >
            <IconButton onClick={() => {abort()}} sx={{position: "absolute", top: 1, right: 1, color: colors.primary[100]}}>
                <CloseOutlined />
            </IconButton>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: theme.palette.background.default,
                    borderRadius: 3,
                    p: 3,
                    width: "100%",
                    gap: 3,
                }}
            >
                {page === 0 ?
                    (
                        <StudentSelection
                            students={props.students}
                            selectedStudents={selectedStudents}
                            onClick={(students: IUserChild[]) => {
                                console.log("selectedStudents - Submit: ", students);
                                if(students && students.length > 0){
                                    // remove all selections where students aren't included anymore
                                    const newSelection = new Map(selection);
                                    selection.forEach((value, key) => {
                                        if(!students.find(student => student._id === key)){
                                            newSelection.delete(key);
                                        }
                                    });
                                    setSelection(newSelection);
                                    setSelectedStudents(students.sort((a, b) => { return a.firstName < b.firstName ? -1 : a.firstName > b.firstName ? 1 : 0}));
                                    setPage(1);
                                }
                            }}
                        />
                    ) : (
                        <ProductSelection
                            students={selectedStudents}
                            onClick={(selection: Map<string, string>) => {
                                submit(selection);
                            }}
                            onBack={() => {
                                // setTempSelection(selection);
                                setPage(0);
                            }}
                            variants={props.variants}
                            selections={selection}
                            backVisible={props.students.length !== 1}
                            product={props.product}
                        />
                    )
                }
            </Box>
        </Dialog>
    );
}

function StudentSelection(props: any){
    const [selectedStudents, setSelectedStudents] = useState<IUserChild[]>([]);
    const [disabledStudents, setDisabledStudents] = useState<IUserChild[]>([]);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    useEffect(() => {
        setSelectedStudents(props.selectedStudents);
    },[props.selectedStudents]);

    useEffect(() => {
        setDisabledStudents(props.disabledStudents);
    }, [props.disabledStudents]);

    // Remove students on destruction
    useEffect(() => {
        return () => {
            setSelectedStudents([]);
            setDisabledStudents([]);
        }
    }, []);

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "100%",
                gap: 3,
                flexDirection: "column"
            }}
        >
            <PageHeader title="SCHÜLER*INNEN AUSWÄHLEN" subtitle="Wählen Sie aus, für welche Schüler*innen Sie das Produkt bestellen möchten." />

            {!props.students || props.students.length === 0 ? <Typography>Keine Schüler*innen gefunden.</Typography> :
            props.students.map((child: IUserChild, index: number) =>
                (
                    <StudentDisplay key={index} name={child.firstName + " " + child.lastName} onClick={
                        () => {
                            if(!selectedStudents.includes(child)) {
                                setSelectedStudents([...selectedStudents, child]);
                            } else {
                                setSelectedStudents(selectedStudents.filter((student) => student._id !== child._id));
                            }
                        }}
                        isSelected={selectedStudents.includes(child)}
                        isDisabled={disabledStudents && disabledStudents.includes(child)}
                    />
                )
            )}


            <Box width={"100%"}
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                }}
            >
                <Button
                    color="primary"
                    onClick={() => props.onClick(selectedStudents)}
                    // sx={{display: {xs: 'none', md: 'block'}}}
                    sx={{
                        backgroundColor: colors.primary[400],
                        color: colors.grey[100],
                        [`:focus`]: {
                            backgroundColor: colors.primary[400],
                        },
                        [`:hover`]: {
                            backgroundColor: { xs: colors.primary[400], sm: colors.greenAccent[600]},
                        },
                        height: "50px",
                        width: "100px",
                        // display: props.hidden ? "none" : undefined,
                    }}
                    disabled={!props.students || selectedStudents.length === 0}
                >
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}>
                        <Typography sx={{ml: "5px"}}>Weiter</Typography>
                        <NavigateNextOutlined />
                    </Box>
                </Button>
            </Box>
        </Box>
    );
}

function StudentDisplay(props: any) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Button
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: colors.primary[400],
                borderRadius: 3,
                p: 3,
                width: "100%",
                border: "3px solid",
                borderColor: (props.isSelected) ? colors.blueAccent[400] + ' !important': colors.primary[400],
                color: colors.grey[100],
                [`:focus`]: {
                    backgroundColor: colors.primary[400],
                },
                [`:hover`]: {
                    backgroundColor: { xs: colors.primary[400], sm: colors.primary[400]},
                    borderColor: { xs: undefined, md: colors.blueAccent[700]},
                },
            }}
            onClick={() => {props.onClick()}}
            disabled={props.isDisabled}
        >
            <Typography variant="h3" sx={{color: colors.grey[100]}}>{props.name}</Typography>
        </Button>
    );
}

function ProductSelection(props: any){
    const [studentIndex, setStudentIndex] = useState<number>(0);
    const [selection, setSelection] = useState<Map<string, string>>(new Map());
    const [initialSelection, setInitialSelection] = useState<Map<string, string>>(new Map());

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    useEffect(() => {
        if(props.selections) {
            setInitialSelection(new Map(props.selections));
        }
    }, [props.selections]);

    // useEffect(() => {
    //     console.log("props.variants", props.variants);
    //     console.log("selection", selection);
    //     console.log("props.variants.size", props.variants.size);
    //     if(props.variants.length === 1){
    //         if(selection.size === studentIndex){
    //             setSelection(selection.set(props.students[studentIndex]._id, props.variants[0].id));
    //         }
    //     }
    // }, [props.variants]);

    const handleNext = () => {
        if(studentIndex < props.students.length - 1){
            // console.log("selection - handleNext", selection);
            // console.log(props.variants.size);
            // if(props.variants.length === 1){
            //     console.log("selection - handleNext", selection);
            //     console.log("studentIndex", studentIndex)
            //     if(selection.size === studentIndex + 1){
            //         console.log("inside")
            //         setSelection(selection.set(props.students[studentIndex + 1]._id, props.variants[0].id));
            //     }
            // }
            setStudentIndex(studentIndex + 1);
        } else {
            console.log("FINISH", selection);
            props.onClick(selection);
        }
    };

    useEffect(() => {
        console.log("selection - ProductSelection", selection);
    }, [selection]);

    const handleBack = () => {
        if(studentIndex > 0){
            setStudentIndex(studentIndex - 1);
        } else {
            props.onBack();
        }
    }

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "100%",
                gap: 5,
                flexDirection: "column"
            }}
        >
            {props.students.length > 0 && (
                <>
                    <PageHeader title={"VARIANTE FÜR " + props.students[studentIndex].firstName.toUpperCase() + " AUSWÄHLEN"} subtitle="Wählen Sie aus, in welcher Variante Sie Ihr Produkt bestellen möchten." />

                    {/*{props.students.map((student: IUserChild, index: number) =>*/}
                    {/*    (*/}
                    {/*        <Accordion*/}
                    {/*            expanded={expanded === index}*/}
                    {/*            onChange={handleChange(index)}*/}
                    {/*            key={index}*/}
                    {/*            sx={{*/}
                    {/*                backgroundColor: colors.primary[400],*/}
                    {/*                borderRadius: 3,*/}
                    {/*                width: "100%",*/}
                    {/*            }}*/}
                    {/*        >*/}
                    {/*            <AccordionSummary*/}
                    {/*                expandIcon={<ExpandMoreOutlined />}*/}
                    {/*            >*/}
                    {/*                <Typography variant="h3">{student.firstName + " " + student.lastName}</Typography>*/}
                    {/*            </AccordionSummary>*/}
                    {/*            <AccordionDetails*/}
                    {/*                sx={{*/}
                    {/*                    backgroundColor: colors.primary[500],*/}
                    {/*                }}*/}
                    {/*            >*/}
                    {/*                Produktauswahl*/}
                    {/*            </AccordionDetails>*/}
                    {/*        </Accordion>*/}
                    {/*    )*/}
                    {/*)}*/}

                    <SingleProductSelection
                        index={studentIndex}
                        product={props.product}
                        variants={props.variants}
                        selected={selection.get(props.students[studentIndex]._id) ?? initialSelection.get(props.students[studentIndex]._id)}
                        onSelection={(variant: IProductVariant) => {
                            console.log("variant - onSelection", variant);
                            if(variant){
                                const map: Map<string, string> = new Map(initialSelection);
                                selection.forEach((value, key) => {
                                    map.set(key, value);
                                });
                                map.set(props.students[studentIndex]._id, variant._id);
                                setSelection(map);
                            } else {
                                const temp = new Map(selection);
                                temp.delete(props.students[studentIndex]._id);
                                setSelection(temp);
                            }
                        }}
                    />

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: !props.backVisible ? "flex-end" : "space-between",
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <Button
                            color="primary"
                            onClick={() => handleBack()}
                            // sx={{display: {xs: 'none', md: 'block'}}}
                            sx={{
                                backgroundColor: colors.primary[400],
                                color: colors.grey[100],
                                [`:focus`]: {
                                    backgroundColor: colors.primary[400],
                                },
                                [`:hover`]: {
                                    backgroundColor: { xs: colors.primary[400], sm: colors.greenAccent[600]},
                                },
                                height: "50px",
                                width: "100px",
                                display: !props.backVisible ? "none" : undefined,
                            }}
                        >
                            <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}>
                                <NavigateBeforeOutlined />
                                <Typography sx={{mr: "5px"}}>Zurück</Typography>
                            </Box>
                        </Button>
                        <Button
                            color="primary"
                            onClick={() => {handleNext()}}
                            disabled={selection.size < studentIndex + 1}
                            sx={{
                                backgroundColor: colors.primary[400],
                                color: colors.grey[100],
                                [`:focus`]: {
                                    backgroundColor: colors.primary[400],
                                },
                                [`:hover`]: {
                                    backgroundColor: { xs: colors.primary[400], sm: colors.greenAccent[600]},
                                },
                                height: "50px",
                                width: "100px",
                                // display: props.hidden ? "none" : undefined,
                            }}
                        >
                            {studentIndex === props.students.length - 1 ?
                                (
                                    <Box sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}>
                                        <Typography sx={{mr: "5px"}}>Fertig</Typography>
                                        <DoneOutlined />
                                    </Box>
                                ) : (
                                    <Box sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}>
                                        <Typography sx={{ml: "5px"}}>Weiter</Typography>
                                        <NavigateNextOutlined />
                                    </Box>
                                )
                            }
                        </Button>
                    </Box>
                </>
            )}
        </Box>
    );
}

function SingleProductSelection(props: any) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [availableColors, setAvailableColors] = useState<string[]>([]);
    const [availableStorages, setAvailableStorages] = useState<string[]>([]);
    const [activeStorages, setActiveStorages] = useState<string[]>([]);
    const [currentPrice, setCurrentPrice] = useState<Price>({total: 0, tax: 0, currency: Currency.EUR});

    const [selectedColor, setSelectedColor] = useState<string>("");
    const [selectedStorage, setSelectedStorage] = useState<string>("");
    const [selectedVariant, setSelectedVariant] = useState<IProductVariant | null>(null);

    const [currentIndex, setCurrentIndex] = useState<number>(-1);

    useEffect(() => {
        if(props.selected){
            const variant = props.variants.find((variant: any) => variant._id === props.selected);
            if(variant){
                handleColorChange(variant.color);
                handleStorageChange(variant.storage);
            }
        }
    }, [props.selected]);

    useEffect(() => {
        const colors: string[] = [];
        const storages: string[] = [];
        if(props.variants) {
            props.variants.map((variant: any) => {
                if(variant.color && !colors.includes(variant.color)){
                    colors.push(variant.color);
                }
                if(variant.storage && !storages.includes(variant.storage)){
                    storages.push(variant.storage);
                }
            });
            setAvailableColors(colors);
            setAvailableStorages(storages);
        }
    }, [props.variants]);

    useEffect(() => {
        if(selectedColor){
            if(selectedStorage){
                setCurrentPrice(props.variants.find((variant: any) => variant.color === selectedColor && variant.storage === selectedStorage)?.price);
            } else {
                setCurrentPrice(calcLowestPriceFromColor(selectedColor));
            }
        } else {
            setCurrentPrice(calcLowestPrice());
        }

        if(availableStorages.length > 0) {
            props.onSelection(props.variants.find((variant: any) => variant.color === selectedColor && variant.storage === selectedStorage));
        } else if (availableColors.length > 0) {
            props.onSelection(props.variants.find((variant: any) => variant.color === selectedColor));
        } else if (selectedVariant){
            props.onSelection(selectedVariant);
        }
    }, [selectedColor, selectedStorage, selectedVariant]);

    useEffect(() => {
        if(props.index !== currentIndex) {
            // props.onSelection(props.variants.find((variant: any) => variant.color === selectedColor && variant.storage === selectedStorage));
            if(props.selected){
                const variant = props.variants.find((variant: any) => variant._id === props.selected);
                if(variant){
                    handleColorChange(variant.color);
                    handleStorageChange(variant.storage);
                }
            } else {
                setSelectedColor("");
                setSelectedStorage("");
                setSelectedVariant(null);
                setCurrentPrice(calcLowestPrice());
            }
            setCurrentIndex(props.index);
        }
    }, [props.index]);

    const calcLowestPrice = (): Price => {
        let lowestPrice: Price = {total: 0, tax: 0, currency: Currency.EUR};
        props.variants.map((variant: any) => {
            if(lowestPrice.total === 0 || variant.price.total < lowestPrice.total){
                lowestPrice = variant.price;
            }
        });
        return lowestPrice;
    }

    const calcLowestPriceFromColor = (color: string): Price => {
        let lowestPrice: Price = {total: 0, tax: 0, currency: Currency.EUR};
        props.variants.map((variant: any) => {
            if(variant.color === color){
                if(lowestPrice.total === 0 || variant.price.total < lowestPrice.total){
                    lowestPrice = variant.price;
                }
            }
        });
        return lowestPrice;
    }

    const handleColorChange = (color: string) => {
        setSelectedColor(color);
        const storages: string[] = [];
        props.variants.map((variant: any) => {
            if(variant.color === color && !storages.includes(variant.storage)){
                storages.push(variant.storage);
            }
        });
        if(!storages.includes(selectedStorage)){
            setSelectedStorage("");
        }
        setActiveStorages(storages);
    }

    const handleStorageChange = (storage: string) => {
        setSelectedStorage(storage);
    }

    const handleSingleVariantChange = () => {
        setSelectedVariant(props.variants[0]);
    }

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "100%",
                gap: 3,
                flexDirection: "column"
            }}
        >
            {/* Direkte Auswahl */}
            {availableColors.length === 0 && availableStorages.length === 0 && (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        gap: 2,
                        flexDirection: "column",
                        width: "100%",
                    }}
                >
                    <Typography variant="h3">Produkt auswählen</Typography>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "space-between",
                            width: "100%",
                            gap: 3,
                        }}
                    >
                        <VariantDisplay
                            btnWidth={'100%'}
                            name={props.product?.name}
                            onClick={handleSingleVariantChange}
                            isSelected={selectedVariant !== null}
                        />
                    </Box>
                </Box>
            )}

            {/* Farbe */}
            {availableColors.length > 0 && (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        gap: 2,
                        flexDirection: "column",
                        width: "100%",
                    }}
                >
                    <Typography variant="h3">Farbe auswählen</Typography>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "space-between",
                            width: "100%",
                            gap: 3,
                        }}
                    >
                        {
                            availableColors.map((color: string, index: number) => {
                                const btnWidth: string = 100 / availableColors.length - 1 + "%";
                                return (
                                    <VariantDisplay
                                        key={index}
                                        btnWidth={btnWidth}
                                        name={color}
                                        onClick={() => handleColorChange(color)}
                                        isSelected={selectedColor === color}
                                    />
                                );
                            })
                        }
                    </Box>
                </Box>
            )}

            {/* Speicher */}
            {availableStorages.length > 0 &&
                (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            gap: 2,
                            flexDirection: "column",
                            width: "100%",
                        }}
                    >
                        <Typography variant="h3">Speicher auswählen</Typography>

                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "space-between",
                                width: "100%",
                                gap: 3,
                            }}
                        >
                            {
                                availableStorages.map((storage: string, index: number) => {
                                    const btnWidth: string = 100 / availableColors.length - 1 + "%";
                                    return (
                                        <VariantDisplay
                                            key={index}
                                            btnWidth={btnWidth}
                                            name={storage}
                                            onClick={() => {handleStorageChange(storage)}}
                                            isDisabled={!activeStorages.includes(storage)}
                                            isSelected={selectedStorage === storage}
                                        />
                                    );
                                })
                            }
                        </Box>
                    </Box>
                )
            }

            {/* Preis */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    flexDirection: "column",
                    gap: 1,
                    width: "100%",
                    mt: 2,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "baseline",
                        gap: 1
                    }}
                >
                    <Typography variant="h2" sx={{color: colors.greenAccent[400]}}>
                        {currentPrice.total.toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                    </Typography>
                    <Typography variant="h2" sx={{color: colors.greenAccent[400]}}>
                        {props.variants[0]?.price.currency === Currency.USD ? '$' : '€'}
                    </Typography>
                </Box>
                <Typography variant="h5" sx={{color: colors.primary[100]}}>Endpreis</Typography>
            </Box>
        </Box>
    );
}

function VariantDisplay (props: any){
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Button
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: colors.primary[400],
                color: colors.grey[100],
                borderRadius: 3,
                p: 3,
                width: props.btnWidth,
                border: "3px solid",
                borderColor: (props.isSelected) ? colors.blueAccent[400] + ' !important': colors.primary[400],
                [`:focus`]: {
                    backgroundColor: colors.primary[400],
                },
                [`:hover`]: {
                    backgroundColor: { xs: colors.primary[400], sm: colors.primary[400]},
                    borderColor: { xs: undefined, md: colors.blueAccent[700]},
                },
            }}
            onClick={() => {props.onClick()}}
            disabled={props.isDisabled}
        >
            <Typography variant="h5">{props.name}</Typography>
        </Button>
    );

}

export default ProductSelectionPopup;