export interface IUser {
    _id?: string;
    firstName: string;
    lastName: string;
    eMail: string;
    role: string;
    password: string
}

export interface IUserAdmin {
    _id?: string;
    firstName: string;
    lastName: string;
    eMail: string;
    role: string;
    password: string
}

export interface IUserManager {
    _id?: string;
    firstName: string;
    lastName: string;
    eMail: string;
    role: string;
    password: string;
    organisation: string
}

export interface IUserCustomer {
    _id?: string;
    firstName: string;
    lastName: string;
    eMail: string;
    role: string;
    password: string;
    organisation: string;
    children?: string[]
}

export interface IUserChild {
    _id?: string;
    firstName: string;
    lastName: string;
    eMail?: string;
    role: string;
    password?: string;
    organisation: string;
    parentCustomer: string;
    class: string
}

export enum UserRole {
    admin = 'admin',
    manager = 'manager',
    customer = 'customer',
    child = 'child'
}