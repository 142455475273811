import React from "react";
import PageHeader from "../../../components/PageHeader";
import {Box} from "@mui/material";

function Dashboard(props: any) {

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <PageHeader title="DASHBOARD" subtitle="Willkommen auf Ihrem Dashboard" />
            </Box>
        </Box>
    );
}

export default Dashboard;