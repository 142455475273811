import rest, {setAuthToken} from "../http-common";
import {IAuthUserRequest, IAuthUserResponse, IAuthOrgRequest, IAuthOrgResponse} from "../../types/AuthTypes";
import {AxiosResponse} from "axios";
import {createContext, useContext, useEffect, useState} from "react";
import {Alert, AlertTitle, Box} from "@mui/material";
import ErrorMessage from "../../components/ErrorMessage";
import {useNavigate} from "react-router-dom";
import {useLoadingContext} from "../../components/LoadingProvider";

const AuthContext = createContext({});

export const AuthProvider = ({children}: any) => {
    const [accessToken, setAccessToken] = useState<string>("");
    const [userId, setUserId] = useState<string>("");
    const [organisationId, setOrganisationId] = useState<string>("");
    const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false);
    const [isOrgLoggedIn, setIsOrgLoggedIn] = useState<boolean>(false);
    const [userRole, setUserRole] = useState<string>("");
    const [initialised, setInitialised] = useState<boolean>(false);

    const [error, setError] = useState<string>("");
    const [showError, setShowError] = useState<boolean>(false);
    const {setShowLoading}: any = useLoadingContext();

    const navigate = useNavigate();

    const loggedIn = (): boolean => {
        console.log("isUserLoggedIn: ", isUserLoggedIn);
        console.log("isOrgLoggedIn: ", isOrgLoggedIn);
        return isUserLoggedIn || isOrgLoggedIn;
    }

    // Load persistent data
    useEffect(() => {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
            setAuthToken(accessToken);
            setAccessToken(accessToken);

            validateToken();

            const userId = localStorage.getItem("userId");
            if (userId) {
                setUserId(userId);
            }
            const organisationId = localStorage.getItem("organisationId");
            if (organisationId) {
                setOrganisationId(organisationId);
            }
            const isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
            if (isUserLoggedIn === "true") {
                setIsUserLoggedIn(true);
            }
            const isOrgLoggedIn = localStorage.getItem("isOrgLoggedIn");
            if (isOrgLoggedIn === "true") {
                setIsOrgLoggedIn(true);
            }
            const userRole = localStorage.getItem("userRole");
            if (userRole) {
                setUserRole(userRole);
            }

            setInitialised(true);
        } else {
            removeLoginData();
            setInitialised(true);
        }
    },[]);

    useEffect(() => {
        if (showError) {
            setTimeout(() => {
                setShowError(false);
            }, 10000);
        }
    },[showError]);

    // useEffect(() => {
    //     setShowLoading(false);
    // });

    // Verify token is valid
    const validateToken = () => {
        console.log("Validating token...");
        console.log(rest.defaults.headers);
        rest.get("/validation/validateToken").then((res: AxiosResponse) => {
            if (res.status === 200) {
                console.log(res)
                return true;
            }
        }).catch((err) => {
            console.log(err);
            if (err.response.status === 401 || err.response.status === 403) {
                revokeAccessToken();
                return false;
            }
        });
    }

    const revokeAccessToken = () => {
        setShowLoading(true);
        setError("Your session has expired. Please log in again.");
        setShowError(true);
        removeLoginData();
    }

    // API calls
    const userAuth = (data: IAuthUserRequest, noRedirect?: boolean) => {
        return rest.post<IAuthUserResponse>("/login", data).then((res: AxiosResponse<IAuthUserResponse>) => {
            setShowLoading(true);
            if(res.data.accessToken) {
                localStorage.setItem("accessToken", res.data.accessToken);
                setAccessToken(res.data.accessToken);
                setAuthToken(res.data.accessToken);
            }
            if(res.data.userId) {
                localStorage.setItem("userId", res.data.userId);
                setUserId(res.data.userId);
            }
            if(res.data.organisationId) {
                localStorage.setItem("organisationId", res.data.organisationId);
                setOrganisationId(res.data.organisationId);
            }
            localStorage.setItem("isUserLoggedIn", "true");
            setIsUserLoggedIn(true);
            localStorage.setItem("isOrgLoggedIn", "false");
            setIsOrgLoggedIn(false);
            if(res.data.role) {
                localStorage.setItem("userRole", res.data.role);
                setUserRole(res.data.role);
            }
            console.log(res);
            setShowError(false);
            if(!noRedirect) {
                navigate("/");
            }
            return res.data;
        }).catch((err) => {
            // setError(err.response.data);
            // setShowError(true);
            throw err.response.data;
        });
    }

    const orgAuth = (data: IAuthOrgRequest) => {
        return rest.post<IAuthOrgResponse>("/login/organisation", data).then((res: AxiosResponse<IAuthOrgResponse>) => {
            setShowLoading(true);
            if(res.data.accessToken) {
                localStorage.setItem("accessToken", res.data.accessToken);
                setAccessToken(res.data.accessToken);
                setAuthToken(res.data.accessToken);
            }
            localStorage.removeItem("userId");
            setUserId("");
            localStorage.setItem("organisationId", res.data.organisationId);
            setOrganisationId(res.data.organisationId);
            localStorage.setItem("isOrgLoggedIn", "true");
            setIsOrgLoggedIn(true);
            localStorage.setItem("isUserLoggedIn", "false");
            setIsUserLoggedIn(false);
            localStorage.removeItem("userRole");
            setUserRole("");
            setShowError(false);
            navigate("/");
            return res.data;
        }).catch((err) => {
            // console.log(err);
            // setError(err.response.data);
            // setShowError(true);
            throw err.response.data;
        });
    }

    const logout = () => {
        setShowLoading(true);
        return rest.post("/logout").then(() => {
            removeLoginData();
            navigate("/");
            setShowLoading(false);
        }).catch((err) => {
            removeLoginData();
            setError(err.response.data);
            setShowError(true);
        });
    }

    const switchToUserLogin = ({eMail, password}: any): Promise<void | AxiosResponse> => {
        if(isOrgLoggedIn) {
            return rest.post("/logout").then(() => {
                userAuth({eMail, password} as IAuthUserRequest, true).then((res) => {
                    console.log(res);
                    setShowLoading(false);
                }).catch((err) => {
                    console.log(err);
                    setShowLoading(false);
                    removeLoginData();
                    setError(err.response.data);
                    setShowError(true);
                })
            }).catch((err) => {
                setError(err.response.data);
                setShowError(true);
            })
        } else {
            return new Promise<void>((resolve, reject) => {
                reject();
            });
        }
    }

    const removeLoginData = () => {
        localStorage.clear();
        setAccessToken("");
        setUserId("");
        setOrganisationId("");
        setIsUserLoggedIn(false);
        setIsOrgLoggedIn(false);
        setUserRole("");
        setAuthToken("");
        setInitialised(true);
    }

    return (
        <AuthContext.Provider value={{
            accessToken,
            userId,
            organisationId,
            isUserLoggedIn,
            isOrgLoggedIn,
            userRole,
            error,
            initialised,
            loggedIn,
            userAuth,
            orgAuth,
            logout,
            switchToUserLogin,
            revokeAccessToken,
        }} >
            {/* Alert */}
            {showError && (
                <Box
                    sx={{
                        zIndex: 21474830000
                    }}
                >
                    <Box
                        sx={{
                            position: "fixed",
                            bottom: 0,
                            right: 0,
                            margin: 4,
                            zIndex: 21474830000,
                            maxWidth: "100%",
                            // width: "00px"
                        }}
                    >
                        <Alert variant="filled" severity="error">
                            <AlertTitle>Authentifizierungsfehler</AlertTitle>
                            {error}
                        </Alert>
                    </Box>
                </Box>
            )}
            {children}
        </AuthContext.Provider>
    )
}
export const useAuthContext = () => useContext(AuthContext);