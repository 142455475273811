import React, {useEffect} from 'react';
import {useLoadingContext} from "../../components/LoadingProvider";
import {Box, Typography, useTheme} from "@mui/material";
import PageHeader from "../../components/PageHeader";
import {tokens} from "../../theme";
import {CheckCircle, LockClockOutlined} from "@mui/icons-material";

function Closed(props: any) {
    const {setShowLoading}: any = useLoadingContext();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    useEffect(() => {
        if(props.organisation) {
            setShowLoading(false);
        }
    }, [props.organisation]);

    return (
        <Box >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: 3,
                    p: {xs: 1, md: 3},
                    // width: `calc(100% - 24px)`,
                    boxSizing: "border-box"
                }}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <PageHeader title="GESCHLOSSEN" subtitle="Der Webshop Ihrer Organisation ist aktuell geschlossen." />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "stretch",
                        alignItems: "stretch",
                        gap: 3,
                        [theme.breakpoints.down('sm')]: {
                            flexWrap: "wrap"
                        },
                        boxSizing: "border-box"
                    }}
                >
                    {(props.organisation?.logoImage !== "" && props.organisation?.logoImage !== undefined) && (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: colors.primary[400],
                                borderRadius: 3,
                                p: 3,
                                width: "100%",
                                boxSizing: "border-box"
                            }}
                        >
                            <img width="100%"  style={{objectFit: "contain", maxHeight: "100%"}} src={props.organisation?.logoImage} alt="Organisations-Logo" />
                        </Box>)
                    }
                    {props.organisation?.name !== "" && (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: colors.primary[400],
                                borderRadius: 3,
                                p: 3,
                                width: "100%",
                                boxSizing: "border-box"
                            }}
                        >
                            <Typography variant="h2" color={colors.greenAccent[500]}>
                                {props.organisation?.name}
                            </Typography>
                        </Box>
                    )}
                </Box>
                {/*<Divider color={colors.greenAccent[500]} sx={{my: 4}} flexItem={true}/>*/}
                <Box
                    sx={{
                        display: 'flex',
                        gap: 3,
                        alignItems: 'stretch',
                        justifyContent: 'stretch',
                        flexDirection: {xs: 'column', sm: 'row'},
                    }}
                >
                    <Box display="flex" justifyContent="center" alignItems="center"
                         sx={{
                             p: 3,
                             backgroundColor: colors.primary[400],
                             borderRadius: 3,
                         }}
                    >
                        <LockClockOutlined sx={{fontSize: 100, color: colors.greenAccent[400]}}/>
                    </Box>

                    <Box
                        sx={{
                            p: 3,
                            backgroundColor: colors.primary[400],
                            borderRadius: 3,
                        }}
                    >
                        <Typography variant="body1">Der Webshop für Ihre Organisation ist aktuell geschlossen. <br/><br/>
                            Sie können sich mit Ihren eigenen Zugangsdaten einloggen, um den aktuellen Status Ihrer Bestellung zu prüfen. Wenn Sie Fragen zu Ihrer Bestellung haben, dann wenden Sie sich an Ihren Ansprechpartner {props.orgContactPerson} oder schreiben Sie uns eine Mail unter <a href={"mailto:support@school.bitbot.eu"} style={{color: colors.grey[100]}}>support@school.bitbot.eu</a>.<br/><br/>
                            Der Webshop öffnet zwischen dem {new Date(props.organisation?.webShopOpensAt).toLocaleDateString('de-DE')} und dem {new Date(props.organisation?.webShopClosesAt).toLocaleDateString('de-DE')}.
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default Closed;