import React from "react";
import {Box, Typography, useTheme} from "@mui/material";
import { tokens } from "../theme";

function PageHeader({ title, subtitle }: any) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box>
            <Typography
                variant="h2"
                color={colors.grey[100]}
                fontWeight="bold"
                sx={{ mb: "5px" }}
            >
                {title}
            </Typography>
            <Typography variant="h5" color={colors.greenAccent[400]}>
                {subtitle}
            </Typography>
        </Box>
    );
}

export default PageHeader;