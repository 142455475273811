import React, {useEffect, useRef, useState} from 'react';
import {Box, Typography, useTheme} from "@mui/material";
import {tokens} from "../../theme";
import PageHeader from "../../components/PageHeader";
import Category from "./components/Category";
import Product from "./components/Product";
import {IAccessory, IProductVariant, IProductWithVariants, ProductType} from "../../types/ProductTypes";
import {useLoadingContext} from "../../components/LoadingProvider";

function Accessories(props: any) {
    const [selectionCases, setSelectionCases] = useState<Map<string, string>>(new Map<string, string>());
    const [selectionPencils, setSelectionPencils] = useState<Map<string, string>>(new Map<string, string>());
    const [selectionOtherAccessories, setSelectionOtherAccessories] = useState<Map<string, string>>(new Map<string, string>());

    const [givenCaseSelection, setGivenCaseSelection] = useState<Map<string, string>>(new Map<string, string>());
    const [givenPencilSelection, setGivenPencilSelection] = useState<Map<string, string>>(new Map<string, string>());
    const [givenOtherAccessoriesSelection, setGivenOtherAccessoriesSelection] = useState<Map<string, string>>(new Map<string, string>());

    const [formValid, setFormValid] = useState<boolean>(false);

    const [cases, setCases] = useState<IProductWithVariants[]>([]);
    const [pencils, setPencils] = useState<IProductWithVariants[]>([]);
    const [otherAccessories, setOtherAccessories] = useState<IProductWithVariants[]>([]);

    const selectionRef = useRef();
    const formValidRef = useRef<boolean>(false);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {setShowLoading}: any = useLoadingContext();

    useEffect(() => {
        if (props.cases) {
            aggregateDevices(props.cases, ProductType.case);
            setShowLoading(false);
        }
    }, [props.cases]);

    useEffect(() => {
        if(props.pencils) {
            aggregateDevices(props.pencils, ProductType.pencil);
            setShowLoading(false);
        }
    }, [props.pencils]);

    useEffect(() => {
        if(props.otherAccessories) {
            aggregateDevices(props.otherAccessories, ProductType.otherAccessories);
            setShowLoading(false);
        }
    }, [props.otherAccessories]);

    useEffect(() => {
        formValidRef.current = formValid;
    }, [formValid]);

    useEffect(() => {
        (selectionRef as any).current = {
            cases: selectionCases,
            pencils: selectionPencils,
            otherAccessories: selectionOtherAccessories,
        };
    }, [selectionCases, selectionPencils, selectionOtherAccessories]);

    useEffect(() => {
        let casesValid: boolean = false;
        let pencilsValid: boolean = false;
        let otherAccessoriesValid: boolean = false;

        // Validate cases
        if(selectionCases && selectionCases.size > 0) {
            if (selectionCases.size === props.students.length) {
                casesValid = true;
            } else {
                if (props.requiredProductCategories) {
                    if (props.requiredProductCategories.includes(ProductType.case)) {
                        casesValid = false;
                    } else {
                        casesValid = true;
                    }
                }
            }
        } else if (givenCaseSelection && givenCaseSelection.size > 0) {
            if (givenCaseSelection.size === props.students.length) {
                casesValid = true;
            } else {
                if (props.requiredProductCategories) {
                    if (props.requiredProductCategories.includes(ProductType.case)) {
                        casesValid = false;
                    } else {
                        casesValid = true;
                    }
                }
            }
        } else {
            casesValid = props.requiredProductCategories && !props.requiredProductCategories.includes(ProductType.case);
        }

        // Validate pencils
        if(selectionPencils && selectionPencils.size > 0) {
            if (selectionPencils.size === props.students.length) {
                pencilsValid = true;
            } else {
                if (props.requiredProductCategories) {
                    if (props.requiredProductCategories.includes(ProductType.pencil)) {
                        pencilsValid = false;
                    } else {
                        pencilsValid = true;
                    }
                }
            }
        } else if (givenPencilSelection && givenPencilSelection.size > 0){
            if (givenPencilSelection.size === props.students.length) {
                pencilsValid = true;
            } else {
                if (props.requiredProductCategories) {
                    if (props.requiredProductCategories.includes(ProductType.pencil)) {
                        pencilsValid = false;
                    } else {
                        pencilsValid = true;
                    }
                }
            }
        } else {
            pencilsValid = props.requiredProductCategories && !props.requiredProductCategories.includes(ProductType.pencil);
        }

        // Validate otherAccessories
        console.log("props.requiredProductCategories: ", props.requiredProductCategories);
        if(selectionOtherAccessories && selectionOtherAccessories.size > 0) {
            console.log("selectionOtherAccessories.size: ", selectionOtherAccessories.size);
            if (selectionOtherAccessories.size === props.students.length) {
                otherAccessoriesValid = true;
            } else {
                if (props.requiredProductCategories) {
                    if (props.requiredProductCategories.includes(ProductType.otherAccessories)) {
                        otherAccessoriesValid = false;
                    } else {
                        otherAccessoriesValid = true;
                    }
                }
            }
        } else if (givenOtherAccessoriesSelection && givenOtherAccessoriesSelection.size > 0) {
            if (givenOtherAccessoriesSelection.size === props.students.length) {
                otherAccessoriesValid = true;
            } else {
                if (props.requiredProductCategories) {
                    if (props.requiredProductCategories.includes(ProductType.otherAccessories)) {
                        otherAccessoriesValid = false;
                    } else {
                        otherAccessoriesValid = true;
                    }
                }
            }
        } else {
            otherAccessoriesValid = props.requiredProductCategories && !props.requiredProductCategories.includes(ProductType.otherAccessories);
        }

        if (casesValid && pencilsValid && otherAccessoriesValid) {
            props.validate(true);
            setFormValid(true);
        } else {
            props.validate(false);
            setFormValid(false);
        }

        if(props.readOnly) {
            props.validate(true);
            setFormValid(true);
        }
    }, [selectionCases, selectionPencils, selectionOtherAccessories, givenCaseSelection, givenPencilSelection, givenOtherAccessoriesSelection]);

    // Submission on page leave
    useEffect(() => {
        return () => {
            console.log("selectionRef.current: ", selectionRef.current);
            if (formValidRef.current && !props.readOnly) {
                // @ts-ignore
                props.onSelection([selectionRef.current.cases ?? new Map(), selectionRef.current.pencils ?? new Map(), selectionRef.current.otherAccessories ?? new Map()]);
            }
        }
    }, []);

    useEffect(() => {
        if (props.selectionCase) {
            setGivenCaseSelection(props.selectionCase);
        }
        if (props.selectionPencil) {
            setGivenPencilSelection(props.selectionPencil);
        }
        if (props.selectionOtherAccessories) {
            setGivenOtherAccessoriesSelection(props.selectionOtherAccessories);
        }
    }, [props.selectionCase, props.selectionPencil, props.selectionOtherAccessories]);

    // ----- HELPER METHODS ----- //
    const aggregateDevices = (accessories: IAccessory[], type: ProductType) => {
        const temp: IProductWithVariants[] = [];

        for (const accessory of accessories) {
            if(!temp.find((item: IProductWithVariants) => item.product.brand === accessory.brand && item.product.name === accessory.name && (item.product as IAccessory).fitsFor === accessory.fitsFor)) {
                const variants: IProductVariant[] = [];
                const variant: IProductVariant = {
                    _id: accessory._id ?? accessory.name,
                    color: accessory.color,
                    price: accessory.price,
                    modelIdentifier: accessory.name,
                }
                variants.push(variant);
                temp.push({product: accessory, variants: variants});
            } else {
                const index = temp.findIndex((item: IProductWithVariants) => item.product.brand === accessory.brand && item.product.name === accessory.name && (item.product as IAccessory).fitsFor === accessory.fitsFor);
                const variant: IProductVariant = {
                    _id: accessory._id ?? accessory.name,
                    color: accessory.color,
                    price: accessory.price,
                    modelIdentifier: accessory.name,
                }
                temp[index].variants.push(variant);
            }
        }

        if(type === ProductType.case) {
            setCases(temp);
        } else if (type === ProductType.pencil) {
            setPencils(temp);
        } else if (type === ProductType.otherAccessories) {
            setOtherAccessories(temp);
        }
    }

    const deviceSelectionHandler = (deviceSelection: Map<string, string>, variants: IProductVariant[], type: ProductType) => {
        if(deviceSelection) {
            const cases = new Map<string, string>();
            const pencils = new Map<string, string>();
            const otherAccessories = new Map<string, string>();

            if(givenCaseSelection && selectionCases.size === 0){
                givenCaseSelection.forEach((value, key) => {
                    cases.set(key, value);
                });
            }
            selectionCases.forEach((value, key) => {
                cases.set(key, value);
            });

            if(givenPencilSelection && selectionPencils.size === 0){
                givenPencilSelection.forEach((value, key) => {
                    pencils.set(key, value);
                });
            }
            selectionPencils.forEach((value, key) => {
                pencils.set(key, value);
            });

            if(givenOtherAccessoriesSelection && selectionOtherAccessories.size === 0){
                givenOtherAccessoriesSelection.forEach((value, key) => {
                    otherAccessories.set(key, value);
                });
            }
            selectionOtherAccessories.forEach((value, key) => {
                otherAccessories.set(key, value);
            });

            if(type === ProductType.case) {
                if(deviceSelection.size > 0) {
                    console.log("Variants: ", variants);
                    variants.forEach((variant: IProductVariant) => {
                        cases.forEach((value: string, key: string) => {
                            if (value === variant._id) {
                                cases.delete(key);
                            }
                        });
                    });
                }
                deviceSelection.forEach((value: string, key: string) => {
                    cases.set(key, value);
                });

            } else if (type === ProductType.pencil) {

                if(deviceSelection.size > 0) {
                    variants.forEach((variant: IProductVariant) => {
                        pencils.forEach((value: string, key: string) => {
                            if (value === variant._id) {
                                pencils.delete(key);
                            }
                        });
                    });
                }
                deviceSelection.forEach((value: string, key: string) => {
                    pencils.set(key, value);
                });


            } else if (type === ProductType.otherAccessories) {

                if(deviceSelection.size > 0) {
                    variants.forEach((variant: IProductVariant) => {
                        otherAccessories.forEach((value: string, key: string) => {
                            if (value === variant._id) {
                                otherAccessories.delete(key);
                            }
                        });
                    });
                }
                deviceSelection.forEach((value: string, key: string) => {
                    otherAccessories.set(key, value);
                });

            }

            setSelectionCases(cases);
            setSelectionPencils(pencils);
            setSelectionOtherAccessories(otherAccessories);
        }
    }

    return (
        <Box >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: 3,
                    p: {xs: 1, md: 3},
                    // width: `calc(100% - 24px)`,
                    boxSizing: "border-box"
                }}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <PageHeader title="ZUBEHÖR AUSWÄHLEN" subtitle="Bitte wählen Sie das gewünschte Zubehör für Ihr Gerät aus." />
                </Box>

                {(cases.length === 0 && pencils.length === 0 && otherAccessories.length === 0) ?
                    (
                        <Box
                            sx={{
                            backgroundColor: colors.primary[400],
                            borderRadius: 3,
                            p: 3,
                        }}
                            >
                            <Typography variant="h5" sx={{color: colors.grey[100]}}>Es sind leider keine Geräte verfügbar.</Typography>
                        </Box>
                    ) : (
                        <>
                            {cases.length > 0 && (
                                <>
                                    <Category title="Schutzhüllen" isRequired={props.requiredProductCategories && props.requiredProductCategories.includes(ProductType.case) ? true : false}/>
                                    {cases.map((device: any, index: number) => {
                                        return (
                                            <Product key={index} readOnly={props.readOnly} product={device.product} variants={device.variants} students={props.students} onSelection={(selection: Map<string, string>, variants: IProductVariant[]) => deviceSelectionHandler(selection, variants, ProductType.case)} selections={selectionCases.size > 0 ? selectionCases : givenCaseSelection}/>
                                        )
                                    })}
                                </>
                            )}

                            {pencils.length > 0 && (
                                <>
                                    <Category title="Stifte" isRequired={props.requiredProductCategories && props.requiredProductCategories.includes(ProductType.pencil) ? true : false}/>
                                    {pencils.map((device: any, index: number) => {
                                        return (
                                            <Product key={index} readOnly={props.readOnly} product={device.product} variants={device.variants} students={props.students} onSelection={(selection: Map<string, string>, variants: IProductVariant[]) => deviceSelectionHandler(selection, variants, ProductType.pencil)} selections={selectionPencils.size > 0 ? selectionPencils : givenPencilSelection}/>
                                        )
                                    })}
                                </>
                            )}

                            {otherAccessories.length > 0 && (
                                <>
                                    <Category title="Sonstiges Zubehör" isRequired={props.requiredProductCategories && props.requiredProductCategories.includes(ProductType.otherAccessories) ? true : false}/>
                                    {otherAccessories.map((device: any, index: number) => {
                                        return (
                                        <Product key={index} readOnly={props.readOnly} product={device.product} variants={device.variants} students={props.students} onSelection={(selection: Map<string, string>, variants: IProductVariant[]) => deviceSelectionHandler(selection, variants, ProductType.otherAccessories)} selections={selectionOtherAccessories.size > 0 ? selectionOtherAccessories : givenOtherAccessoriesSelection}/>
                                        )
                                    })}
                                </>
                            )}
                        </>
                    )}
            </Box>
        </Box>
    );
}

export default Accessories;