import React, {useEffect} from "react";
import PageHeader from "../../../../components/PageHeader";
import {Box, Button, Typography, useTheme} from "@mui/material";
import {tokens} from "../../../../theme";
import {useProductContext} from "../../../../api/provider/ProductProvider";
import {UserRole} from "../../../../types/UserTypes";
import {DataGrid} from "@mui/x-data-grid";
import {
    LockOpenOutlined,
    LockOutlined,
    NavigateNextOutlined,
    VisibilityOffOutlined,
    VisibilityOutlined
} from "@mui/icons-material";

function BackendProductVisibilityOrganisation(props: any) {
    const [organisationId, setOrganisationId] = React.useState<string>("");
    const [selectedProducts, setSelectedProducts] = React.useState<string[]>([]);
    // const [availableProducts, setAvailableProducts] = React.useState<any>([]);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { availableProducts }: any = useProductContext();

    // useEffect(() => {
    //     console.log("productVisibility", productVisibility);
    //     if (availableProducts) {
    //         setAvailableProducts(productVisibility);
    //     }
    // }, [availableProducts]);


    const columns = [
        {
            field: "pictures",
            headerName: "",
            flex: 0.3,
            sortable: false,
            renderCell: (params: any) => {
                return (
                    <img
                        src={params.row.pictures[0]}
                        alt={params.row.name}
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                        }}
                    />
                );
            }
        },
        // {
        //     field: "_id",
        //     headerName: "ID",
        //     flex: 1,
        // },
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            minWidth: 200,
        },
        {
            field: "description",
            headerName: "Beschreibung",
            flex: 2,
            minWidth: 200,
        },
        {
            field: "type",
            headerName: "Kategorie",
            flex: 0.4,
            minWidth: 70,
        },
        {
            field: "storage",
            headerName: "Speicher",
            flex: 0.3,
            minWidth: 60,
        },
        {
            field: "color",
            headerName: "Farbe",
            flex: 0.5,
            minWidth: 70,
        },
        {
            field: "duration",
            headerName: "Laufzeit",
            flex: 0.3,
            minWidth: 70,
        },
        {
            field: "price",
            headerName: "Preis",
            flex: 0.3,
            renderCell: (params: any) => {
                return params.row.price.total + " " + ((params.row.price.currency === "EUR") ? "€" : "$");
            },
            minWidth: 60
        },
        {
            field: "id",
            headerName: "Sichtbarkeit",
            flex: 0.6,
            sortable: false,
            renderCell: (params: any) => {
                return (<BackendProductsOrganisationButton enabled={selectedProducts.includes(params.row._id)} onClick={() => {
                    if (selectedProducts.includes(params.row._id)) {
                        setSelectedProducts(selectedProducts.filter((id) => id !== params.row._id));
                    } else {
                        setSelectedProducts([...selectedProducts, params.row._id]);
                    }
                }} />);
            },
            minWidth: 75
        }
    ]

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <PageHeader title="PRODUKT-SICHTBARKEIT" subtitle="Verwalten Sie die Produkte, die im Webshop angezeigt werden sollen." />
            </Box>

            <Box>
                Auswahl der Organisation für Admins
            </Box>

            <Box
                sx={{
                    mt: 6,
                    height: {xs: "75vh", md: "80vh"},
                    "& .MuiDataGrid-root": {
                        border: "none"
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none"
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.primary[400],
                        color: colors.greenAccent[100],
                        borderBottom: "none",
                        borderRadius: "10px 10px 0 0"
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: theme.palette.background.default,
                    },
                    "& .MuiDataGrid-footerContainer": {
                        backgroundColor: colors.primary[400],
                        color: colors.greenAccent[100],
                        borderTop: "none",
                        borderRadius: "0 0 10px 10px"
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.greenAccent[200]} !important`
                    }
                }}
            >
                <DataGrid
                    loading={!(availableProducts.length > 0)}
                    getRowId={(row) => row._id}
                    columns={columns}
                    rows={availableProducts}
                />
            </Box>
        </Box>
    );
}

function BackendProductsOrganisationButton(props: any) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box width={"100%"}>
            <Button
                color="primary"
                onClick={props.onClick}
                sx={{
                    backgroundColor: colors.primary[400],
                    color: colors.grey[100],
                    [`:focus`]: {
                        backgroundColor: colors.primary[400],
                    },
                    [`:hover`]: {
                        backgroundColor: { xs: colors.primary[400], sm: colors.greenAccent[600]},
                    },
                    height: "35px",
                    width: "95%",
                    display: props.hidden ? "none" : undefined,
                }}
                disabled={props.disabled}
                // sx={{display: {xs: 'none', md: 'block'}}}
            >
                <Box sx={{
                    display: {xs: "none", md: "flex"},
                    alignItems: "center",
                    justifyContent: "space-between",
                }}>
                    {props.enabled ?
                        (
                            <>
                                <Typography sx={{mx: "5px"}}>Verstecken</Typography>
                                <VisibilityOffOutlined />
                            </>
                        ) : (
                            <>
                                <Typography sx={{mx: "5px"}}>Anzeigen</Typography>
                                <VisibilityOutlined />
                            </>
                        )
                    }
                </Box>
                <Box sx={{
                    display: {xs: "flex", md: "none"},
                    alignItems: "center",
                    justifyContent: "space-between",
                }}>
                    {!props.enabled ?
                        (
                            <>
                                <VisibilityOffOutlined />
                            </>
                        ) : (
                            <>
                                <VisibilityOutlined />
                            </>
                        )
                    }
                </Box>
            </Button>
        </Box>
    );
}

export default BackendProductVisibilityOrganisation;