import React, {useEffect, useState} from "react";
import PageHeader from "../../../../components/PageHeader";
import {
    Box,
    formLabelClasses,
    IconButton,
    InputAdornment,
    inputClasses,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import {tokens} from "../../../../theme";
import * as yup from "yup";
import {useFormik} from "formik";
import Textarea
    from "@react-buddy/ide-toolbox/dist/previews/tools-panel/props-edit-table/table-items/table-item/table-item-control/textarea";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useOrgContext} from "../../../../api/provider/OrganisationProvider";
import {OrganisationSelectionProvider} from "../../../../components/OrganisationSelection";

function BackendOrganisation(props: any) {

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <PageHeader title="ÜBER DIE ORGANISATION" subtitle="Passen Sie die Informationen Ihrer Organisation an." />
            </Box>

            {/*<OrganisationSelectionProvider>*/}
                <ManageOrganisation />
            {/*</OrganisationSelectionProvider>*/}

        </Box>
    );
}

function ManageOrganisation(props: any) {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { organisation }: any = useOrgContext();

    const validationSchema = yup.object({
        name: yup.string().required('Name der Organisation ist erforderlich'),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            groupPassword: '',
            contactPerson: '',
            logoImage: '',
            welcomeText: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            // if(formik.isValid){
            //     submitData(values);
            // }
        },
    });

    useEffect(() => {
        if(organisation){
            formik.setValues({
                name: organisation.name,
                groupPassword: organisation.groupPassword,
                contactPerson: organisation.contactPerson,
                logoImage: organisation.logoImage,
                welcomeText: organisation.welcomeText,
            });
        }
    });

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                mt: 6,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    boxSizing: "border-box",
                    '& form': {
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                    },
                    [`& .${formLabelClasses.focused}`]: {
                        color: colors.grey[100],
                    },
                    [`& .${inputClasses.focused}`]: {
                        color: colors.grey[100] + ' !important',
                        '& fieldset': {
                            borderColor: colors.grey[100] + ' !important',
                        }
                    },
                }}
            >
                <form
                    onSubmit={(event: React.FormEvent) => {
                        event.preventDefault();
                    }}
                    style={{width: '100%'}}
                >
                    <Typography variant="h5" sx={{color: colors.grey[100]}}>Allgemeine Informationen</Typography>
                    <TextField
                        required
                        variant="outlined"
                        label="Name der Organisation"
                        name="name"
                        sx={{width: {xs: '100%', md: '50%'}}}
                        type={"text"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name ?? ''}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />
                    <TextField
                        required
                        disabled
                        variant="outlined"
                        label="Organisations-Passwort"
                        name="groupPassword"
                        sx={{width: {xs: '100%', md: '50%'}}}
                        type={showPassword ? "text" : "password"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.groupPassword ?? ''}
                        error={formik.touched.groupPassword && Boolean(formik.errors.groupPassword)}
                        helperText={formik.touched.groupPassword && formik.errors.groupPassword}
                        InputProps={{ // <-- This is where the toggle button is added.
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <TextField
                        variant="outlined"
                        label="URL des Logos"
                        placeholder="URL zum Speicherort des Logo der Organisation."
                        name="logoImage"
                        sx={{width: {xs: '100%', md: '50%'}}}
                        type={"text"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.logoImage ?? ''}
                        error={formik.touched.logoImage && Boolean(formik.errors.logoImage)}
                        helperText={formik.touched.logoImage && formik.errors.logoImage}
                    />
                    <Typography variant="h5" sx={{color: colors.grey[100], mt: 3}}>Informationen für Ihre Kunden</Typography>
                    <TextField
                        variant="outlined"
                        label="Ansprechpartner"
                        name="contactPerson"
                        sx={{width: '100%'}}
                        type={"text"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.contactPerson ?? ''}
                        error={formik.touched.contactPerson && Boolean(formik.errors.contactPerson)}
                        helperText={formik.touched.contactPerson && formik.errors.contactPerson}
                    />
                    <TextField
                        multiline
                        rows={4}
                        variant="outlined"
                        label="Informationen für Ihre Kunden"
                        placeholder="Wird auf der Startseite der Bestellung angezeigt."
                        name="welcomeText"
                        sx={{width: '100%'}}
                        type={"text"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.welcomeText ?? ''}
                        error={formik.touched.welcomeText && Boolean(formik.errors.welcomeText)}
                        helperText={formik.touched.welcomeText && formik.errors.welcomeText}
                    />
                </form>
            </Box>
        </Box>
    );
}

export default BackendOrganisation;