import React, {useEffect, useState} from 'react';
import Header from "./Header";
import {Box, useTheme} from "@mui/material";
import {Navigate, redirect, Route, Routes, useNavigate} from "react-router-dom";
import Welcome from "./Welcome";
import Footer from "./Footer";
import Stepper from "./components/OrderStepper";
import OrderStepper from "./components/OrderStepper";
import ButtonNavigation from "./components/ButtonNavigation";
import Data from "./Data";
import Student from "./Student";
import {IUserAdmin, IUserChild, IUserCustomer, IUserManager,IUser} from "../../types/UserTypes";
import {IProduct, ITablet, IAccessory, IService, ProductType} from "../../types/ProductTypes";
import IOrder, {OrderStatus} from "../../types/OrderType";
import IOrganisation from "../../types/OrgaTypes";
import {useAuthContext} from "../../api/provider/AuthProvider";
import {useOrgContext} from "../../api/provider/OrganisationProvider";
import {useUserContext} from "../../api/provider/UserProvider";
import {useOrderContext} from "../../api/provider/OrderProvider";
import {useProductContext} from "../../api/provider/ProductProvider";
import Devices from "./Devices";
import Accessories from "./Accessories";
import Services from "./Services";
import Payment from "./Payment";
import Finish from "./Finish";
import IAddress from "../../types/AddressType";
import IOrderItem from "../../types/OrderItemType";
import {useLoadingContext} from "../../components/LoadingProvider";
import Closed from "./Closed";

function Order(props: any) {
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [childDataValid, setChildDataValid] = useState<boolean>(false);
    const [readOnly, setReadOnly] = useState<boolean>(false);
    const [interacted, setInteracted] = useState<boolean>(false);
    const [webshopClosed, setWebshopClosed] = useState<boolean>(false);

    // Context
    const theme = useTheme();
    const navigate = useNavigate();
    const {switchToUserLogin, isOrgLoggedIn, isUserLoggedIn}: any = useAuthContext();
    const {organisation}: any = useOrgContext();
    const {createCustomer, user, students, setSubmittedStudents}: any = useUserContext();
    const {order, setAddress, tempOrderItems, setTempOrderItems, validateOrder}: any = useOrderContext();
    const {tablets, accessories, services, filterProductsByType, requiredProductCategories}: any = useProductContext();
    const {setShowLoading}: any = useLoadingContext();

    // Constants
    const steps = [
        'Start',
        'Persönliche Daten',
        'Schüler*innen anlegen',
        'Geräte auswählen',
        'Zubehör auswählen',
        'Dienste auswählen',
        'Zahlung',
        'Abschluss'
    ];

    const paths = [
        '/',
        '/data',
        '/students',
        '/devices',
        '/accessories',
        '/services',
        '/payment',
        '/finish'
    ]

    const isStepFailed = (step: number) => {
        return false;
    };

    const handleNext = () => {
        setInteracted(true);

        if(childDataValid){
            const prevCurrentPage: number = currentPage;
            let newPage: number = prevCurrentPage + 1;
            if(newPage > steps.length - 1){
                newPage = steps.length - 1;
            }
            setCurrentPage(newPage);
            navigate(paths[newPage]);
            if(!readOnly) {
                setChildDataValid(false);
            }
        }
    }

    const handleBack = () => {
        setInteracted(true);

        const prevCurrentPage: number = currentPage;
        let newPage: number = prevCurrentPage - 1;
        if(newPage < 0){
            newPage = 0;
        }
        setCurrentPage(newPage);
        navigate(paths[newPage]);
        if(!readOnly) {
            setChildDataValid(false);
        }
    }

    // ----- DATA SUBMISSION ---- //

    // Submit data from Persönliche Daten Form
    const submitData = (data: any) => {
        setInteracted(true);
        // Create User object in database
        if(isOrgLoggedIn) {
            createCustomer(data).then((res: any) => {
                // Set new Login Context
                switchToUserLogin(data).then((res: any) => {
                    // Save address to update order as soon as available
                    const address = {
                        street: data.street,
                        houseNumber: data.houseNumber,
                        postalCode: data.postalCode,
                        additionalInformation: data.additionalInformation,
                        city: data.city,
                    }
                    setAddress(address);

                    setShowLoading(false);
                });
            });
        } else if (isUserLoggedIn) {
            const address = {
                street: data.street,
                houseNumber: data.houseNumber,
                additionalInformation: data.additionalInformation,
                postalCode: data.postalCode,
                city: data.city,
            }
            setAddress(address);
        }
    }

    // Submit data from Schüler*innen anlegen Form
    const submitStudents = (data: IUserChild[]) => {
        setInteracted(true);
        setSubmittedStudents(data);
    }

    // Submit data from Geräte/Zubehör/Dienste auswählen Form
    const onDeviceSelection = (deviceSelections: Map<string, string>[]) => {
        setInteracted(true);

        console.log("Device Selections", deviceSelections);
        if(deviceSelections && deviceSelections.length > 0){
            const orderItems: IOrderItem[] = [];

            for (const selection of deviceSelections) {
                selection.forEach((value: string, key: string) => {
                    const orderItem: IOrderItem = {
                        productId: value,
                        childId: key,
                    };
                    orderItems.push(orderItem);
                });
            }

            setTempOrderItems(orderItems);
        }
    }

    // set current page on page load
    useEffect(() => {
        const path = window.location.pathname;
        const index = paths.indexOf(path);

        if(index > -1){
            setCurrentPage(index);
        }
    });

    // actions based on current page
    useEffect(() => {
        console.log("Current Page", currentPage);
        // Make readonly after last step
        if(currentPage === steps.length - 1){
            setReadOnly(true);
        }
    }, [currentPage]);

    useEffect(() => {
        // Validate order if payment step
        if(order.status === OrderStatus.open) {
            if (currentPage === paths.length - 2) {
                console.log("Validate Order", order._id);
                if (order._id) {
                    validateOrder(order._id);
                }
            }
        }
    }, [currentPage, order]);

    // set page based on current order status
    useEffect(() => {
        console.log("Set page based on order status", order);
        if(!interacted) {
            if(organisation && ((organisation.webShopClosesAt && new Date(organisation.webShopClosesAt) < new Date()) || organisation.webShopOpensAt && new Date(organisation.webShopOpensAt) > new Date())) {
                console.log("Webshop is closed", organisation.webShopClosesAt, organisation.webShopOpensAt, new Date(), new Date(organisation.webShopClosesAt) < new Date(), new Date(organisation.webShopOpensAt) > new Date());
                if(isUserLoggedIn && order) {
                    if(order.status !== OrderStatus.open && order.status !== OrderStatus.checkedOut) {
                        setCurrentPage(steps.length - 1);
                        navigate(paths[steps.length - 1]);
                    } else {
                        setWebshopClosed(true);
                        navigate('/closed');
                    }
                } else {
                    setWebshopClosed(true);
                    navigate('/closed');
                }
            } else if(window.location.pathname === '/' || (order.status !== OrderStatus.checkedOut && order.status !== OrderStatus.open)) {
                console.log("From path / or order status not checked out or open");
                setWebshopClosed(false);
                if (isUserLoggedIn) {
                    if (order) {
                        if (order.status === OrderStatus.checkedOut) {
                            setCurrentPage(steps.length - 2);
                            navigate(paths[steps.length - 2])
                        } else if (order.status === OrderStatus.paymentAuthorised || order.status === OrderStatus.paid || order.status === OrderStatus.shipped || order.status === OrderStatus.delivered) {
                            setCurrentPage(steps.length - 1);
                            navigate(paths[steps.length - 1])
                        } else if (order.status === OrderStatus.open) {
                            if(order.billingAddress?.city) {
                                if (user?.children.length > 0) {
                                    setCurrentPage(3);
                                    navigate(paths[3]);
                                } else {
                                    setCurrentPage(2);
                                    navigate(paths[2]);
                                }
                            } else {
                                if((Date.now() - new Date(order.timestamps?.openAt).getTime()) > (2 * 60 * 1000)) {
                                    setCurrentPage(1);
                                    navigate(paths[1]);
                                } else {
                                    setShowLoading(false);
                                }
                            }
                        }
                    }
                } else {
                    setCurrentPage(0);
                    navigate(paths[0]);
                }
            } else {
                console.log("From other path", currentPage);
                setWebshopClosed(false);
                navigate(paths[currentPage]);
            }
        }
    }, [interacted, isUserLoggedIn, user?.children, order?.status, order, organisation]);

    useEffect(() => {
        console.log("interacted:" +interacted)
    }, [interacted]);

    // ------ HELPER FUNCTIONS ------ //
    const convertOrderItemsToMap = (orderItems: IOrderItem[]): Map<string, string> | undefined => {
        if(orderItems && orderItems.length > 0) {
            const map: Map<string, string> = new Map();

            for (const orderItem of orderItems) {
                map.set(orderItem.childId, orderItem.productId);
            }

            return map;
        }

        return undefined;
    }

    const getOrderItemsByType = (orderItems: IOrderItem[], type: string): IOrderItem[] => {
        const filteredOrderItems: IOrderItem[] = [];
        if(orderItems) {
            let productsForFiltering;
            if(type === ProductType.tablet){
                productsForFiltering = tablets;
            } else if (type === ProductType.case || type === ProductType.otherAccessories || type === ProductType.pencil) {
                productsForFiltering = accessories;
            } else if (type === ProductType.insurance || type === ProductType.internetSecurity || type === ProductType.deviceSecurity || type === ProductType.software){
                productsForFiltering = services;
            } else {
                return filteredOrderItems;
            }

            for (const orderItem of orderItems) {
                const product = filterProductsByType(productsForFiltering, type).find((product: IProduct) => product._id === orderItem.productId);
                if (product) {
                    filteredOrderItems.push(orderItem);
                }
            }
        }
        return filteredOrderItems;
    }

    // Load data on page load
    // useEffect(() => {
    //
    // },[]);

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
        >
            <Box
                className="orderWraper"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "100dvh",
                    width: "70rem",
                    maxWidth: "100%",
                    px: 2,
                    // alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "40px",
                    }}
                >
                    { /* Header */ }
                    <Box className="orderHeaderWrapper">
                        <Header />
                    </Box>

                    { /* Stepper */ }
                    { !webshopClosed && (
                        <Box className="orderStepperWrapper"
                            sx={{
                                [theme.breakpoints.down('sm')]: {
                                    display: "none"
                                }
                            }}
                        >
                            <OrderStepper steps={steps} isStepFailed={isStepFailed} activeStep={currentPage}/>
                        </Box>
                    )}

                    { /* Content */ }
                    <Box className="orderContentWrapper"
                        sx={{
                            [theme.breakpoints.up('sm')]: {
                                ml: 3,
                                mr: 3,
                            }
                        }}
                    >
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    <Welcome
                                        validate={setChildDataValid}
                                        logoUrl={organisation?.logoImage}
                                        welcomeText={organisation?.welcomeText}
                                        organisationName={organisation?.name}
                                    />
                                }
                            />
                            <Route
                                path="/data"
                                element={
                                    <Data
                                        validate={setChildDataValid}
                                        submitData={(data: any) => submitData(data)}
                                        user={user}
                                        invoiceAddress={order?.billingAddress}
                                        readOnly={readOnly}
                                    />
                                }
                            />
                            <Route
                                path="/students"
                                element={
                                    <Student
                                        validate={setChildDataValid}
                                        submitData={(students: IUserChild[]) => submitStudents(students)}
                                        students={students}
                                        readOnly={readOnly || order?.orderItems?.length > 0}
                                    />
                                }
                            />
                            <Route
                                path="/devices"
                                element={
                                    <Devices
                                        validate={setChildDataValid}
                                        students={students}
                                        tablets={tablets}
                                        requiredProductCategories={requiredProductCategories}
                                        selections={convertOrderItemsToMap(getOrderItemsByType(order.orderItems, ProductType.tablet))}
                                        onSelection={(selection: Map<string, string>[]) => {
                                            onDeviceSelection(selection);
                                        }}
                                        readOnly={readOnly}
                                    />
                                }
                            />
                            <Route
                                path="/accessories"
                                element={
                                    <Accessories
                                        validate={setChildDataValid}
                                        cases={filterProductsByType(accessories, ProductType.case)}
                                        pencils={filterProductsByType(accessories, ProductType.pencil)}
                                        otherAccessories={filterProductsByType(accessories, ProductType.otherAccessories)}
                                        selectionCase={convertOrderItemsToMap(getOrderItemsByType(order.orderItems, ProductType.case))}
                                        selectionPencil={convertOrderItemsToMap(getOrderItemsByType(order.orderItems, ProductType.pencil))}
                                        selectionOtherAccessories={convertOrderItemsToMap(getOrderItemsByType(order.orderItems, ProductType.otherAccessories))}
                                        students={students}
                                        requiredProductCategories={requiredProductCategories}
                                        onSelection={(selection: Map<string, string>[]) => {
                                            onDeviceSelection(selection);
                                        }}
                                        readOnly={readOnly}
                                    />
                                }
                            />
                            <Route
                                path="/services"
                                element={
                                    <Services
                                        validate={setChildDataValid}
                                        software={filterProductsByType(services, ProductType.software)}
                                        insurance={filterProductsByType(services, ProductType.insurance)}
                                        deviceSecurity={filterProductsByType(services, ProductType.deviceSecurity)}
                                        internetSecurity={filterProductsByType(services, ProductType.internetSecurity)}
                                        students={students}
                                        selectionSoftware={convertOrderItemsToMap(getOrderItemsByType(order.orderItems, ProductType.software))}
                                        selectionInsurance={convertOrderItemsToMap(getOrderItemsByType(order.orderItems, ProductType.insurance))}
                                        selectionDeviceSecurity={convertOrderItemsToMap(getOrderItemsByType(order.orderItems, ProductType.deviceSecurity))}
                                        selectionInternetSecurity={convertOrderItemsToMap(getOrderItemsByType(order.orderItems, ProductType.internetSecurity))}
                                        requiredProductCategories={requiredProductCategories}
                                        onSelection={(selection: Map<string, string>[]) => {
                                            onDeviceSelection(selection);
                                        }}
                                        readOnly={readOnly}
                                    />
                                }
                            />
                            <Route
                                path="/payment"
                                element={
                                    <Payment
                                        validate={setChildDataValid}
                                        order={order}
                                        devices={tablets}
                                        accessories={accessories}
                                        services={services}
                                        user={user}
                                        readOnly={readOnly}
                                    />
                                }
                            />
                            <Route
                                path="/finish"
                                element={
                                    <Finish
                                        validate={setChildDataValid}
                                        order={order}
                                        devices={tablets}
                                        accessories={accessories}
                                        services={services}
                                        orgName={organisation?.name}
                                        orgAddress={organisation?.deliveryAddress?.street + " " + organisation?.deliveryAddress?.houseNumber + ", " + organisation?.deliveryAddress?.postalCode + " " + organisation?.deliveryAddress?.city}
                                        orgContactPerson={organisation?.contactPerson}
                                    />
                                }
                            />
                            <Route
                                path="/closed"
                                element={<Closed organisation={organisation}/>}
                            />
                            <Route path="*" element={<Navigate to="/" replace />}/>
                        </Routes>
                    </Box>

                    {!webshopClosed && (
                        <>
                            { /* Button Navigation */}
                            <Box className="orderButtonNavigationWrapper"
                                 sx={{
                                     [theme.breakpoints.up('sm')]: {
                                         ml: 3,
                                         mr: 3,
                                     }
                                 }}
                            >
                                <ButtonNavigation steps={steps.length} activeStep={currentPage} nextOnClick={handleNext} backOnClick={handleBack} nextDisabled={!childDataValid}/>
                            </Box>
                        </>
                    )}
                </Box>

                { /* Footer */ }
                <Box className="orderFooterWrapper">
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}

export default Order;