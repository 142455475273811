import React, {useState} from 'react';
import {Box, Button, CircularProgress, Dialog, IconButton, Typography, useTheme} from "@mui/material";
import {tokens} from "../../../theme";
import {
    CloseOutlined,
    CreditCardOutlined,
    ErrorOutlined,
    NavigateNextOutlined,
    ReceiptLongOutlined
} from "@mui/icons-material";
import {PayPalButtons, PayPalScriptProvider} from "@paypal/react-paypal-js";
import rest from "../../../api/http-common";
import PageHeader from "../../../components/PageHeader";
import {Currency} from "../../../types/PriceType";
import {useNavigate} from "react-router-dom";
import {useOrderContext} from "../../../api/provider/OrderProvider";

function Invoice(props: any) {
    const [open, setOpen] = React.useState(false);
    const [error, setError] = useState<string | null>(null);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const navigate = useNavigate();
    const { refreshOrder }: any = useOrderContext();

    const selectBuyOnInvoice = () => {
        let serverResponse: string;
        rest.post('/payment/invoice/create-order', {orderId: props.order._id}).then((response) => {
            serverResponse = response.data;
            refreshOrder();
            navigate("/finish");
            setOpen(false);
        }).catch((error) => {
            console.log(error)
            setError('Server-Fehler: ' + (serverResponse !== undefined ? serverResponse : error.response.data) + ' - ' + 'Client-Fehler: ' + error.message);
            if(!props.readOnly){
                props.validate(false);
            }
        });
    }

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: colors.primary[400],
                p: 3,
                borderRadius: 3,
                gap: 3
            }}
        >
            {error ? (
                <>
                    <ErrorOutlined color={"error"} sx={{width: 50, height: 50}}/>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1
                        }}
                    >
                        <Typography variant={"body1"} sx={{color: colors.grey[100]}}>Fehler im Zahlungsformular: <br /> {error}</Typography>
                        <Typography variant={"body1"} sx={{color: colors.grey[100]}}>{'Für weitere Hilfe kontaktieren Sie bitte unseren Kundensupport: '}
                            <a href={"mailto:support@school.bitbot.eu"} style={{color: colors.grey[100]}}>support@school.bitbot.eu</a>
                        </Typography>
                    </Box>

                </>
            ) : (
                <>
                    <Box width={"400px"}>
                        <Button
                            color="primary"
                            onClick={() => {
                                setOpen(true);
                            }
                            }
                            sx={{
                                backgroundColor: theme.palette.mode === 'light' ? colors.primary[900] : colors.primary[300],
                                color: colors.grey[100],
                                [`:focus`]: {
                                    backgroundColor: theme.palette.mode === 'light' ? colors.primary[900] : colors.primary[300],
                                },
                                [`:hover`]: {
                                    backgroundColor: { xs: theme.palette.mode === 'light' ? colors.primary[900] : colors.primary[300], sm: colors.greenAccent[600]},
                                },
                                height: "50px",
                                width: "100%",
                                display: props.hidden ? "none" : undefined,
                            }}
                        >
                            <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}>
                                <Typography sx={{mx: "5px"}}>Bezahlen per Rechnung</Typography>
                                <ReceiptLongOutlined />
                            </Box>
                        </Button>
                    </Box>
                    <InvoicePopup open={open} onClose={() => {setOpen(false)}} onSelection={selectBuyOnInvoice} order={props.order}/>
                </>
            )
            }
        </Box>

    );
}

function InvoicePopup(props: any) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Dialog
            open={props.open}
            onClose={() => {props.onClose()}}
            maxWidth="sm"
            fullWidth
            sx={{
                "& .MuiDialog-paper": {
                    borderRadius: 3,

                },
            }}
        >
            <IconButton onClick={() => {props.onClose()}} sx={{position: "absolute", top: 1, right: 1, color: colors.primary[100]}}>
                <CloseOutlined />
            </IconButton>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    backgroundColor: theme.palette.background.default,
                    borderRadius: 3,
                    p: 3,
                    width: "100%",
                    gap: 3,
                    flexDirection: "column"
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: theme.palette.background.default,
                        width: "100%",
                        gap: 3,
                    }}
                >
                    <PageHeader title="KAUF AUF RECHNUNG" subtitle="Sie können Ihre Bestellung ganz bequem per Rechnung bezahlen." />

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <ReceiptLongOutlined sx={{width: 50, height: 50}} />
                    </Box>
                </Box>

                <Typography variant={"body1"} sx={{color: colors.grey[100]}}>
                    Wenn Sie Rechnung als Ihre Zahlungsmittel auswählen erhalten Sie von uns eine E-Mail mit Ihrer Rechnung. <br/><br/>

                    Bitte überweisen Sie den Betrag innerhalb von 14 Tagen auf das folgende Konto: <br/>
                    Kontoinhaber: Bitbot UG (haftungsbeschränkt) <br/>
                    IBAN: DE13 1001 0123 3454 0317 94 <br />
                    BIC/SWIFT: QNTODEB2XXX <br />
                    Adresse des Kontoinhabers: Hansastraße 60, 42109 Wuppertal, DE <br/><br/>

                    Betrag: {props.order?.totalPrice?.total.toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2})}{props.order?.totalPrice?.currency === Currency.USD ? '$' : '€'} <br/>
                    Verwendungszweck: BBSCH-ID: {props.order?.number ?? props.order?._id} <br/><br/>

                    Vielen Dank für Ihre Bestellung!
                </Typography>

                <Box width={"100%"}
                     sx={{
                         display: "flex",
                         justifyContent: "flex-end",
                         alignItems: "flex-end",
                     }}
                >
                    <Button
                        color="primary"
                        onClick={() => props.onSelection()}
                        // sx={{display: {xs: 'none', md: 'block'}}}
                        sx={{
                            backgroundColor: colors.primary[400],
                            color: colors.grey[100],
                            [`:focus`]: {
                                backgroundColor: colors.primary[400],
                            },
                            [`:hover`]: {
                                backgroundColor: { xs: colors.primary[400], sm: colors.greenAccent[600]},
                            },
                            height: "50px",
                            width: "100%",
                        }}
                    >
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}>
                            <Typography sx={{ml: "5px"}}>Rechnung als Zahlungsmittel auswählen</Typography>
                            <NavigateNextOutlined />
                        </Box>
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
}

export default Invoice;