import {Box, Typography} from "@mui/material";
import {
    AspectRatioOutlined,
    Battery50Outlined,
    CandlestickChartOutlined, ChecklistOutlined, DevicesOutlined, DomainOutlined,
    InstallMobileOutlined,
    MemoryOutlined, PaletteOutlined, PhotoCameraOutlined, ScheduleOutlined,
    StorageOutlined
} from "@mui/icons-material";
import React from "react";

function Specifications(props: any) {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "stretch",
                flexDirection: "column",
                gap: 1
            }}
        >
            {/*<SpecRow icon={<FactoryOutlined />} category="Marke" value="Wert"/>*/}
            {/*<SpecRow icon={<DevicesOutlined />} category="Modell" value="Wert"/>*/}
            <SpecRow icon={<DomainOutlined />} category="Anbieter" value={props.brand}/>
            <SpecRow icon={<InstallMobileOutlined />} category="Betriebssystem" value={props.os}/>
            <SpecRow icon={<MemoryOutlined />} category="Prozessor" value={props.cpu}/>
            <SpecRow icon={<CandlestickChartOutlined />} category="RAM" value={props.ram}/>
            <SpecRow icon={<StorageOutlined />} category="Speicher" value={props.storage}/>
            <SpecRow icon={<Battery50Outlined />} category="Akku" value={props.battery}/>
            <SpecRow icon={<PhotoCameraOutlined />} category="Kamera" value={props.camera}/>
            <SpecRow icon={<AspectRatioOutlined />} category="Display" value={props.display}/>
            <SpecRow icon={<PaletteOutlined />} category="Farben" value={props.colors}/>
            <SpecRow icon={<ScheduleOutlined />} category="Laufzeit" value={props.runtime}/>
            <SpecRow icon={<DevicesOutlined />} category="Kompatibilität" value={props.fitsFor}/>
            <SpecRow icon={<ChecklistOutlined />} category="Varianten" value={props.variant}/>
        </Box>
    );
}

function SpecRow(props: any) {
    return (
        <>
            {(props.category && props.value) ? (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        width: "100%",
                        gap: 2,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: 1,
                            width: "30%",
                            minWidth: "130px"
                        }}
                    >
                        {props.icon}
                        <Typography variant="h6" sx={{fontWeight: 700}}>{props.category}</Typography>
                    </Box>
                    <Typography variant="body1">{props.value}</Typography>
                </Box>
            ) : <></>}
        </>
    );
}

export default Specifications;