import React, {createContext, useContext, useEffect, useRef, useState} from 'react';
import rest from "../http-common";
import {useAuthContext} from "./AuthProvider";
import {AxiosResponse} from "axios";
import IOrganisation from "../../types/OrgaTypes";
import {Box} from "@mui/material";
import ErrorMessage from "../../components/ErrorMessage";
import {ITablet, IAccessory, IService, ProductType, IProduct} from "../../types/ProductTypes";
import {useOrgContext} from "./OrganisationProvider";
import {UserRole} from "../../types/UserTypes";

const ProductContext = createContext({});
export function ProductProvider({children}: any) {
    const [tablets, setTablets] = useState<ITablet[]>([]);
    const [accessories, setAccessories] = useState<IAccessory[]>([]);
    const [services, setServices] = useState<IService[]>([]);
    const [requiredProductCategories, setRequiredProductCategories] = useState<ProductType[]>([]);

    // Backend
    const [availableProducts, setAvailableProducts] = useState<IProduct[]>([]);

    const [error, setError] = useState<string>("");
    const [showError, setShowError] = useState<boolean>(false);
    const { organisationId, revokeAccessToken, isUserLoggedIn, userRole }: any = useAuthContext();
    const { organisation }: any = useOrgContext();

    useEffect(() => {
        if (showError) {
            setTimeout(() => {
                setShowError(false);
            }, 5000);
        }
    },[showError]);

    // Clear data on logout
    useEffect(() => {
        if(!isUserLoggedIn){
            setTablets([]);
            setAccessories([]);
            setServices([]);
            setRequiredProductCategories([]);
            setAvailableProducts([]);
        }
    }, [isUserLoggedIn]);

    // ---- API calls ---- //

    // Get available productVisibility
    useEffect(() => {
        if(isUserLoggedIn){
            if(userRole !== UserRole.admin) {
                // Get allowed productVisibility
                rest.get(`/products/?organisationId=${organisationId}`)
                    .then((response: AxiosResponse) => {
                        console.log("Products", response.data);
                        const products: IProduct[] = response.data.sort((a: IProduct, b: IProduct) => a.name.localeCompare(b.name));
                        setTablets(filterProductsByType(products, ProductType.tablet));
                        setAccessories(filterProductsByType(products, ProductType.case).concat(filterProductsByType(products, ProductType.pencil).concat(filterProductsByType(products, ProductType.otherAccessories))));
                        setServices(filterProductsByType(products, ProductType.insurance).concat(filterProductsByType(products, ProductType.software)).concat(filterProductsByType(products, ProductType.deviceSecurity)).concat(filterProductsByType(products, ProductType.internetSecurity)));
                    })
                    .catch((error) => {
                        console.log(error);
                        if (error.response.status === 401) {
                            revokeAccessToken();
                        }
                        setError(error.message);
                        setShowError(true);
                    });
            }

            if(userRole === UserRole.admin || userRole === UserRole.manager) {
                // Get all productVisibility
                rest.get(`/products/`)
                    .then((response: AxiosResponse) => {
                        console.log("ProductsAvailable", response.data);
                        setAvailableProducts(response.data.sort((a: IProduct, b: IProduct) => a.name.localeCompare(b.name)));
                    })
                    .catch((error) => {
                        console.log(error);
                        if(error.response.status === 401) {
                            revokeAccessToken();
                        }
                        setError(error.message);
                        setShowError(true);
                    });
            }
        }
    },[isUserLoggedIn, userRole]);

    // Get required productVisibility
    useEffect(() => {
        // Get required product-categories
        if(organisation){
            setRequiredProductCategories(organisation.requiredProducts);
            console.log(organisation.requiredProducts);
        }
    }, [organisation]);

    // ---- HELPER METHODS ---- //
    const filterProductsByType = (products: any[], type: ProductType) => {
        if(products && products.length > 0) {
            return products.filter((product) => product.type === type);
        } else {
            return [];
        }
    }

    return (
        <ProductContext.Provider
            value={{
                tablets,
                accessories,
                services,
                filterProductsByType,
                requiredProductCategories,
                availableProducts
            }}
        >
            {/* Alert */}
            {showError && (
                <Box
                    sx={{
                        position: "fixed",
                        bottom: 0,
                        right: 0,
                        margin: 4,
                        zIndex: 2147483001,
                        maxWidth: "100%",
                        // width: "00px"
                    }}
                >
                    <ErrorMessage error={error} severity={"error"} />
                </Box>
            )}
            {children}
        </ProductContext.Provider>
    );
}

export const useProductContext = () => useContext(ProductContext);