import React, {createContext, useContext, useEffect, useState} from 'react';
import rest from "../http-common";
import {useAuthContext} from "./AuthProvider";
import {AxiosResponse} from "axios";
import IOrganisation from "../../types/OrgaTypes";
import {Box} from "@mui/material";
import ErrorMessage from "../../components/ErrorMessage";
import IOrder, {OrderStatus} from "../../types/OrderType";
import IOrderItem from "../../types/OrderItemType";
import IAddress from "../../types/AddressType";
import {IAccessory, IProduct, IService, ITablet} from "../../types/ProductTypes";
import {useProductContext} from "./ProductProvider";
import {UserRole} from "../../types/UserTypes";

const OrderContext = createContext({});
export function OrderProvider({children}: any) {
    // Order data
    const [order, setOrder] = useState<IOrder>({} as IOrder);
    const [tempOrderItems, setTempOrderItems] = useState<IOrderItem[]>([]);
    const [address, setAddress] = useState<IAddress>();

    const [error, setError] = useState<string>("");
    const [showError, setShowError] = useState<boolean>(false);
    const { revokeAccessToken, isUserLoggedIn, userRole, organisationId }: any = useAuthContext();
    const { tablets, accessories, services }: any = useProductContext();

    // Admin Data
    const [orders, setOrders] = useState<IOrder[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (showError) {
            setTimeout(() => {
                setShowError(false);
            }, 5000);
        }
    },[showError]);

    // Clear data on logout
    useEffect(() => {
        if(!isUserLoggedIn){
            setOrder({} as IOrder);
            setTempOrderItems([]);
            setAddress(undefined);

            setOrders([]);
            setIsLoading(false);
        }
    }, [isUserLoggedIn]);

    // API calls
    useEffect(() => {
        if(isUserLoggedIn && userRole === UserRole.customer) {
            createOrder().then(() => {
                console.log("Order loaded");
            });
        } else if (isUserLoggedIn && (userRole === UserRole.admin || userRole === UserRole.manager)) {
            let query: string | undefined = undefined;
            if(userRole === UserRole.manager) {
                query = organisationId;
            }
            getAllOrders(query).then(() => {
                console.log("Orders loaded");
            });
        }
    },[isUserLoggedIn, organisationId, userRole]);

    useEffect(() => {
        if(address && order._id) {
            addAddress(address);
        }
    }, [address]);

    useEffect(() => {
        console.log("TempOrderItems: ", tempOrderItems);
        if(tempOrderItems.length > 0) {
            updateOrderItems(tempOrderItems, order, tablets, accessories, services);
            setTempOrderItems([]);
        }
    }, [tempOrderItems]);

    const createOrder = () => {
        return rest.post("/orders").then((res: AxiosResponse<IOrder>) => {
            setOrder(res.data);
            console.log("Order: ", res.data);

            if(address){
                addAddress(address, res.data._id);
            }
            return res.data;
        }).catch((err) => {
            if(err.response.status === 401) {
                revokeAccessToken();
            }
            setError(err.response.data);
            setShowError(true);
        });
    }

    const getOrder = (orderId: string) => {
        return rest.get("/orders/" + orderId).then((res: AxiosResponse<IOrder>) => {
            setOrder(res.data);
            console.log("Order: ", res.data);
            return res.data;
        }).catch((err) => {
            if(err.response.status === 401) {
                revokeAccessToken();
            }
            setError(err.response.data);
            setShowError(true);
        });
    }

    const updateOrderItems = (orderItems: IOrderItem[], order: IOrder, tablets: ITablet[], accessories: IAccessory[], services: IService[]) => {
        let orderItemsToAdd: IOrderItem[] = [];
        let orderItemsToRemove: IOrderItem[] = [];
        const promises: Promise<void>[] = [];

        let changeTablets: boolean = false;
        let changeAccessories: boolean = false;
        let changeServices: boolean = false;

        // Identify the order categories that have changed
        for (const orderItem of orderItems) {
            if(tablets.find((tablet: ITablet) => tablet._id === orderItem.productId)) {
                changeTablets = true;
            }
            if(accessories.find((accessory: IAccessory) => accessory._id === orderItem.productId)) {
                changeAccessories = true;
            }
            if(services.find((service: IService) => service._id === orderItem.productId)) {
                changeServices = true;
            }
        }

        // Identify order items that share the same category
        let orderItemsToCompare: IOrderItem[] = [];
        for (const orderItem of order.orderItems) {
            if(changeTablets) {
                if (tablets.find((tablet: ITablet) => tablet._id === orderItem.productId)) {
                    orderItemsToCompare.push(orderItem);
                }
            }
            if(changeAccessories) {
                if (accessories.find((accessory: IAccessory) => accessory._id === orderItem.productId)) {
                    orderItemsToCompare.push(orderItem);
                }
            }
            if(changeServices) {
                if (services.find((service: IService) => service._id === orderItem.productId)) {
                    orderItemsToCompare.push(orderItem);
                }
            }
        }

        orderItems.forEach((orderItem) => {
            if(!orderItemsToCompare.find((item) => item.productId === orderItem.productId && item.childId === orderItem.childId)) {
                orderItemsToAdd.push(orderItem);
            }
        });

        orderItemsToCompare.forEach((orderItem) => {
            if(!orderItems.find((item) => item.productId === orderItem.productId && item.childId === orderItem.childId)) {
                orderItemsToRemove.push(orderItem);
            }
        });

        // Add all items that are not in the order yet
        orderItemsToAdd.forEach((orderItem) => {
            promises.push(
                rest.post("/orders/" + order._id + "/products", orderItem).then((res: AxiosResponse<IOrder>) => {
                    console.log("OrderItem added: ", orderItem);
                }).catch((err) => {
                    if(err.response.status === 401) {
                        revokeAccessToken();
                    }
                    setError(err.response.data);
                    setShowError(true);
            }));
        });

        // Remove all items that are not in the order anymore
        orderItemsToRemove.forEach((orderItem) => {
            promises.push(
                rest.delete("/orders/" + order._id + "/products", {data: orderItem}).then((res: AxiosResponse<IOrder>) => {
                    console.log("OrderItem removed: ", orderItem);
                }).catch((err) => {
                    if(err.response.status === 401) {
                        revokeAccessToken();
                    }
                    setError(err.response.data);
                    setShowError(true);
                })
            );
        });

        Promise.all(promises).then(() => {
            console.log("All promises resolved");
            getOrder(order._id as string).then(() => {});
        });
    }

    const addAddress = (address: IAddress, id?: string) => {
        let orderId = id ?? order._id;

        rest.post("/orders/" + orderId + "/address", address).then((res: AxiosResponse<IOrder>) => {
            setOrder(res.data);
        }).catch((err) => {
            if(err.response.status === 401) {
                revokeAccessToken();
            }
            setError(err.response.data);
            setShowError(true);
        });
    }

    const validateOrder = (orderId: string) => {
        rest.post("/orders/" + orderId + "/checkout").then((res: AxiosResponse<IOrder>) => {
            setOrder(res.data);
            console.log("Order validated: ", res.data);
        }).catch((err) => {
            if(err.response.status === 401) {
                revokeAccessToken();
            }
            setError(err.response.data);
            setShowError(true);
        });
    };

    const refreshOrder = () => {
        if(!order._id) return;
        getOrder(order._id).then(r => {
            console.log("orderData refreshed");
        });
    }

    const getAllOrders = (organisationId?: string) => {
        setIsLoading(true);
        let url = "/orders";
        if(organisationId) {
            url = "/orders/?organisationId=" + organisationId;
        }
        return rest.get(url).then((res: AxiosResponse<IOrder[]>) => {
            setOrders(res.data);
            setIsLoading(false);
        }).catch((err) => {
            if(err.response.status === 401) {
                revokeAccessToken();
            }
            setError(err.response.data);
            setShowError(true);
            setIsLoading(false);
        });
    }

    const setOrderStatus = (orderId: string, status: OrderStatus, paymentInformation: any) => {
        let url: string = "";
        let body: any;

        console.log("Order status: ", status);
        switch(status) {
            case OrderStatus.cancelled:
                url = "/orders/" + orderId;
                return rest.delete(url).then((res: AxiosResponse<IOrder[]>) => {
                    getAllOrders(organisationId);
                    setIsLoading(false);
                }).catch((err) => {
                    if (err.response.status === 401) {
                        revokeAccessToken();
                    }
                    setError(err.response.data);
                    setShowError(true);
                    setIsLoading(false);
                });
                break;
            case OrderStatus.paid:
                if (paymentInformation === "INVOICE") {
                    url = "/payment/invoice/verify-payment";
                    body = {
                        orderId: orderId,
                    }
                } else if (paymentInformation === "GOCARDLESS") {
                    return;
                }
                break;
            case OrderStatus.shipped:
                url = "/orders/shipping";
                body = [orderId];
                break;
            case OrderStatus.delivered:
                url = "/orders/delivered";
                body = [orderId];
                break;
            default:
                return;
        }

        console.log("submit", url, body)
        return rest.post(url, body).then((res: AxiosResponse<IOrder[]>) => {
            getAllOrders(organisationId);
            setIsLoading(false);
            console.log("Order status changed: ", res.data);
        }).catch((err) => {
            if(err.response.status === 401) {
                revokeAccessToken();
            }
            setError(err.response.data);
            setShowError(true);
            setIsLoading(false);
        });
    }

    return (
        <OrderContext.Provider
            value={{
                order,
                setAddress,
                tempOrderItems,
                setTempOrderItems,
                validateOrder,
                refreshOrder,

                orders,
                getAllOrders,
                isLoading,
                setOrderStatus,
            }}
        >
            {/* Alert */}
            {showError && (
                <Box
                    sx={{
                        position: "fixed",
                        bottom: 0,
                        right: 0,
                        margin: 4,
                        zIndex: 2147483001,
                        maxWidth: "100%",
                        // width: "00px"
                    }}
                >
                    <ErrorMessage error={error} severity={"error"} />
                </Box>
            )}
            {children}
        </OrderContext.Provider>
    );
}

export const useOrderContext = () => useContext(OrderContext);