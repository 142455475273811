import React from 'react';
import {
    Box, Button,
    Dialog,
    formLabelClasses,
    IconButton,
    inputClasses,
    MenuItem,
    Select,
    Typography,
    useTheme
} from "@mui/material";
import {CloseOutlined, ErrorOutlined, ReceiptLongOutlined} from "@mui/icons-material";
import PageHeader from "./PageHeader";
import {OrderStatus} from "../types/OrderType";
import OrderStatusDisplay from "../scenes/order/components/OrderStatusDisplay";
import BackendOrderItems from "../scenes/backend/organisation/productsOrder/BackendOrderItems";
import {ProductType} from "../types/ProductTypes";
import {tokens} from "../theme";

function ConfirmationDialog({title, text, open, onAbort, onConfirm }: any) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Dialog
            open={open}
            onClose={() => {onAbort()}}
            maxWidth="xs"
            fullWidth
            sx={{
                "& .MuiDialog-paper": {
                    borderRadius: 3,
                },
            }}
        >
            {/*<IconButton onClick={() => {onAbort()}} sx={{position: "absolute", top: 1, right: 1, color: colors.primary[100]}}>*/}
            {/*    <CloseOutlined />*/}
            {/*</IconButton>*/}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    backgroundColor: theme.palette.background.default,
                    borderRadius: 3,
                    p: 3,
                    width: "100%",
                    gap: 3,
                    flexDirection: "column"
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: theme.palette.background.default,
                        width: "100%",
                        gap: 3,
                    }}
                >
                    <PageHeader title={title.toUpperCase()}/>

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <ErrorOutlined sx={{width: 50, height: 50}} />
                    </Box>
                </Box>

                <Typography variant="body1">{text}</Typography>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    <Button color="secondary" onClick={() => {onAbort()}}>Nein</Button>
                    <Button color="secondary" onClick={() => {onConfirm()}}>Ja</Button>
                </Box>


                {/*<Box width={"100%"}*/}
                {/*     sx={{*/}
                {/*         display: "flex",*/}
                {/*         justifyContent: "flex-end",*/}
                {/*         alignItems: "flex-end",*/}
                {/*     }}*/}
                {/*>*/}
                {/*    /!*<Button*!/*/}
                {/*    /!*    color="primary"*!/*/}
                {/*    /!*    onClick={() => onSelection()}*!/*/}
                {/*    /!*    // sx={{display: {xs: 'none', md: 'block'}}}*!/*/}
                {/*    /!*    sx={{*!/*/}
                {/*    /!*        backgroundColor: colors.primary[400],*!/*/}
                {/*    /!*        color: colors.grey[100],*!/*/}
                {/*    /!*        [`:focus`]: {*!/*/}
                {/*    /!*            backgroundColor: colors.primary[400],*!/*/}
                {/*    /!*        },*!/*/}
                {/*    /!*        [`:hover`]: {*!/*/}
                {/*    /!*            backgroundColor: { xs: colors.primary[400], sm: colors.greenAccent[600]},*!/*/}
                {/*    /!*        },*!/*/}
                {/*    /!*        height: "50px",*!/*/}
                {/*    /!*        width: "100%",*!/*/}
                {/*    /!*    }}*!/*/}
                {/*    /!*>*!/*/}
                {/*    /!*    <Box sx={{*!/*/}
                {/*    /!*        display: "flex",*!/*/}
                {/*    /!*        alignItems: "center",*!/*/}
                {/*    /!*        justifyContent: "space-between",*!/*/}
                {/*    /!*    }}>*!/*/}
                {/*    /!*        <Typography sx={{ml: "5px"}}>Bestellung finanzieren</Typography>*!/*/}
                {/*    /!*        <NavigateNextOutlined />*!/*/}
                {/*    /!*    </Box>*!/*/}
                {/*    /!*</Button>*!/*/}
                {/*</Box>*/}
            </Box>
        </Dialog>
    );
}

export default ConfirmationDialog;