import React, {useContext, useEffect, useState} from 'react';
import {useOrgContext} from "../../api/provider/OrganisationProvider";
import IOrganisation from "../../types/OrgaTypes";
import {Box, Divider, IconButton, Typography, useTheme} from "@mui/material";
import {ColorModeContext, tokens} from "../../theme";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import {useAuthContext} from "../../api/provider/AuthProvider";

function Header(props: any) {
    // Context
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const { logout }: any = useAuthContext();

    return (
        <Box
            component="header"
            className="orderHeader"
            sx={{
                py: 3,
                display: "flex",
                alignItems: 'center',
                justifyContent: 'space-between',
                width: "100%",
            }}
        >
            <Box display="flex" alignItems="center" gap="20px">
                <img height="75px"  style={{objectFit: "contain", textAlign: "right"}} src={(theme.palette.mode === "dark") ? "/pics/Bitbot Logo weiß.svg" : "/pics/Bitbot Logo dunkel.svg"} alt="Logo" />
                <Divider orientation="vertical" color={colors.grey[100]} flexItem={true} sx={{display: {xs: "none", sm: "block"}}}/>
                <Typography variant="h2" color={colors.grey[100]} sx={{display: {xs: "none", sm: "block"}}}>
                    Bestellportal
                </Typography>
            </Box>
            <Box className="themeToggleIcon">
                <IconButton onClick={colorMode.toggleColorMode}>
                    {theme.palette.mode === "dark" ? <DarkModeOutlinedIcon /> : <LightModeOutlinedIcon />}
                </IconButton>
                <IconButton onClick={ logout }>
                    <LogoutOutlinedIcon />
                </IconButton>
            </Box>
        </Box>
    );
}

export default Header;