import Address from "./AddressType";
import Price from "./PriceType";
import OrderItem from "./OrderItemType";
import OrderTimestamp from "./OrderTimestampType";

export default interface IOrder {
    _id?: string;
    orderItems: OrderItem[];
    billingAddress: Address;
    paymentInformation: any;
    totalPrice: Price;
    status: OrderStatus;
    userId: string;
    organisationId: string;
    timestamps: OrderTimestamp;
    number: number;
}

export enum OrderStatus {
    open = "open",
    paymentAuthorised = "paymentAuthorised",
    paid = "paid",
    cancelled = "cancelled",
    checkedOut = "checkedOut",
    shipped = "shipped",
    delivered = "delivered"
}