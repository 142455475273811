import React from 'react';
import {Box, Divider, Link, Typography, useTheme} from "@mui/material";
import {tokens} from "../../theme";

function Footer(props: any) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box component="footer"
            sx={{
                display: "flex",
                flexDirection: {
                    xs: "column",
                    md: "row"
                },
                justifyContent: {
                    xs: "center",
                    md: "space-between"
                },
                alignItems: "center",
                py: 3,
                mt: 3,
                gap: 1
            }}
        >
            <Typography variant="body2" textAlign="center">
                © Simon Frank im Auftrag der Bitbot UG (haftungsbeschränkt) {new Date().getFullYear()}
            </Typography>
            <Box sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "5px",
            }}
            >
                <Typography variant="body2" textAlign="center">
                    <Link href="https://school.bitbot.eu/impressum" target="_blank" rel="noreferrer" color={colors.grey[100]}>Impressum</Link>
                </Typography>
                <Divider flexItem={true} orientation="vertical" color={colors.grey[200]}/>
                <Typography variant="body2" textAlign="center">
                    <Link href="https://school.bitbot.eu/datenschutz" target="_blank" rel="noreferrer" color={colors.grey[100]}>Datenschutz</Link>
                </Typography>
                <Divider flexItem={true} orientation="vertical" color={colors.grey[200]}/>
                <Typography variant="body2" textAlign="center">
                    <Link href="https://school.bitbot.eu/agb" target="_blank" rel="noreferrer" color={colors.grey[100]}>AGB</Link>
                </Typography>
                <Divider flexItem={true} orientation="vertical" color={colors.grey[200]}/>
                <Typography variant="body2" textAlign="center">
                    <Link href="https://school.bitbot.eu/widerruf" target="_blank" rel="noreferrer" color={colors.grey[100]}>Widerruf</Link>
                </Typography>
            </Box>
        </Box>
    );
}

export default Footer;