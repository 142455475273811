import React from "react";
import './index.css';
import App from './App';
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";
import {BrowserRouter} from "react-router-dom";
import * as ReactDOM from 'react-dom/client';
import {AuthProvider} from "./api/provider/AuthProvider";
import {OrganisationProvider} from "./api/provider/OrganisationProvider";
import {UserProvider} from "./api/provider/UserProvider";
import {LoadingProvider} from "./components/LoadingProvider";
import {OrderProvider} from "./api/provider/OrderProvider";
import {ProductProvider} from "./api/provider/ProductProvider";
import ScrollToTop from "./components/ScrollToTop";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <DevSupport ComponentPreviews={ComponentPreviews}
                    useInitialHook={useInitial}
        >
            <BrowserRouter>
                <ScrollToTop />
                <LoadingProvider>
                    <AuthProvider>
                        <OrganisationProvider>
                            <UserProvider>
                                <ProductProvider>
                                    <OrderProvider>
                                        <App />
                                    </OrderProvider>
                                </ProductProvider>
                            </UserProvider>
                        </OrganisationProvider>
                    </AuthProvider>
                </LoadingProvider>
            </BrowserRouter>
        </DevSupport>
    </React.StrictMode>
);