import axios, {AxiosInstance} from "axios";

let instance: AxiosInstance;

if(process.env.NODE_ENV !== "production"){
    instance =  axios.create({
        baseURL: "http://localhost:8080/api",
        headers: {
            "Content-type": "application/json",
        }
    });
} else {
    instance =  axios.create({
        baseURL: "/api",
        headers: {
            "Content-type": "application/json",
        }
    });
}

export const setAuthToken = (token: string) => {
    if (token) {
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    else
        delete instance.defaults.headers.common["Authorization"];
}

export default instance;