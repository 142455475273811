import React, {useEffect} from 'react';
import {Box, Typography, useTheme} from "@mui/material";
import {tokens} from "../../theme";
import PageHeader from "../../components/PageHeader";
import Category from "./components/Category";
import SummaryTable from "./components/SummaryTable";
import {useLoadingContext} from "../../components/LoadingProvider";
import {OrderStatus} from "../../types/OrderType";
import GoCardless from "./components/GoCardless";
import Paypal from "./components/Paypal";
import Cashpresso from './components/Cashpresso';
import Invoice from './components/Invoice';
import {CheckCircle, DoneOutlined} from "@mui/icons-material";
import Financing from "./components/Financing";

function Payment(props: any) {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const {setShowLoading}: any = useLoadingContext();

    useEffect(() => {
        if(props.order && props.order.status !== OrderStatus.open){
            setShowLoading(false);
        }
    }, [props.order]);

    useEffect(() => {
        if (props.readOnly) {
            props.validate(true);
        }
    }, [props.readOnly]);

    // @ts-ignore
    // @ts-ignore
    return (
        <Box >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: 3,
                    p: {xs: 1, md: 3},
                    // width: `calc(100% - 24px)`,
                    boxSizing: "border-box"
                }}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <PageHeader title="ZUSAMMENFASSUNG" subtitle="Übersicht über Ihre Bestellung" />
                </Box>

                <SummaryTable
                    order={props.order}
                    devices={props.devices}
                    accessories={props.accessories}
                    services={props.services}
                />

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        gap: 2,
                        mt: 3
                    }}
                >
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <PageHeader title="ZAHLUNG" subtitle="Bitte wählen Sie Ihre Zahlungsmethode" />
                    </Box>

                    {props.readOnly || (props.order.status !== OrderStatus.checkedOut && props.order.status !== OrderStatus.open && props.order?.status !== undefined) ? (
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 3,
                                alignItems: 'stretch',
                                justifyContent: 'strecth',
                                flexDirection: {xs: 'column', sm: 'row'},
                            }}
                        >
                            <Box display="flex" justifyContent="center" alignItems="center"
                                 sx={{
                                     p: 3,
                                     backgroundColor: colors.primary[400],
                                     borderRadius: 3,
                                 }}
                            >
                                <CheckCircle sx={{fontSize: 100, color: colors.greenAccent[400]}}/>
                            </Box>

                            <Box
                                sx={{
                                    p: 3,
                                    backgroundColor: colors.primary[400],
                                    borderRadius: 3,
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                            >
                                <Typography variant="body1">Die Zahlungsabwicklung dieser Bestellung ist bereits abgeschlossen.</Typography>
                            </Box>
                        </Box>

                    ) : (
                        <>
                            <GoCardless order={props.order} validate={props.validate} readOnly={props.readOnly}/>

                            <Invoice order={props.order} />

                            {/*<Paypal order={props.order}/>*/}

                            {/*<Cashpresso order={props.order} user={props.user}/>*/}

                            <Financing order={props.order} />
                        </>
                    )}
                </Box>
            </Box>
        </Box>

    );
}


export default Payment;