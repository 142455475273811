import React, {useEffect, useState} from 'react';
import Markdown from 'markdown-to-jsx';
import {Box, Divider, Typography, useTheme} from "@mui/material";
import {tokens} from "../../theme";
import {useOrgContext} from "../../api/provider/OrganisationProvider";
import IOrganisation from "../../types/OrgaTypes";
import PageHeader from "../../components/PageHeader";
import {useLoadingContext} from "../../components/LoadingProvider";
import MultilineText from "../../components/MultilineText";

function Welcome(props: any) {
    // const [logo, setLogo] = useState<string>("");
    // const [welcomeText, setWelcomeText] = useState<string>("Willkommen im Bestellportal der BitBot UG.");
    // const [orgName, setOrgName] = useState<string>("");

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const {setShowLoading}: any = useLoadingContext();

    // useEffect(() => {
    //     let org: IOrganisation = organisation as IOrganisation;
    //     if(org.logoImage){
    //         setLogo(org.logoImage);
    //     }
    //     if(org.welcomeText){
    //         setWelcomeText(org.welcomeText);
    //     }
    //     if(org.name){
    //         setOrgName(org.name);
    //     }
    // }, [organisation])

    useEffect(() => {
        props.validate(true);
    });

    useEffect(() => {
        if(props.logoUrl !== "" && props.logoUrl !== undefined && props.organisationName !== "" && props.organisationName !== undefined && props.welcomeText !== "" && props.welcomeText !== undefined){
            setShowLoading(false);
        }
    }, [props.logoUrl, props.organisationName, props.welcomeText]);

    return (
        <Box >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: 3,
                    p: {xs: 1, md: 3},
                    // width: `calc(100% - 24px)`,
                    boxSizing: "border-box"
                }}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <PageHeader title="START" subtitle="Informationen von Ihrer Organisation" />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "stretch",
                        alignItems: "stretch",
                        gap: 3,
                        [theme.breakpoints.down('sm')]: {
                            flexWrap: "wrap"
                        },
                        boxSizing: "border-box"
                    }}
                >
                    {(props.logoUrl !== "" && props.logoUrl !== undefined) && (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: colors.primary[400],
                                borderRadius: 3,
                                p: 3,
                                width: "100%",
                                boxSizing: "border-box"
                            }}
                        >
                            <img width="100%"  style={{objectFit: "contain", maxHeight: "100%"}} src={props.logoUrl} alt="Organisations-Logo" />
                        </Box>)
                    }
                    {props.organisationName !== "" && (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: colors.primary[400],
                                borderRadius: 3,
                                p: 3,
                                width: "100%",
                                boxSizing: "border-box"
                            }}
                        >
                            <Typography variant="h2" color={colors.greenAccent[500]}>
                                {props.organisationName}
                            </Typography>
                        </Box>
                    )}
                </Box>
                {/*<Divider color={colors.greenAccent[500]} sx={{my: 4}} flexItem={true}/>*/}
                <Box
                    sx={{
                        backgroundColor: colors.primary[400],
                        borderRadius: 3,
                        p: 3,
                    }}
                >
                    <MultilineText variant="body1" text={props.welcomeText ?? "Willkommen im Bestellportal der BitBot UG."} />
                </Box>
            </Box>
        </Box>
    );
}

export default Welcome;