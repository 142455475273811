import React from 'react';
import {Box, Button, MobileStepper, mobileStepperClasses, Typography, useTheme} from "@mui/material";
import {tokens} from "../../../theme";
import {NavigateNextOutlined, NavigateBeforeOutlined} from "@mui/icons-material";

function ButtonNavigation(props: any) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            sx={{
                width: '100%',
                pl: {xs: 0, sm: 1, md: 3},
                pr: {xs: 0, sm: 1, md: 3},
            }}
        >
            { /* Show stepper on mobile */ }
            <Box sx={{
                display: {xs: 'block', sm: 'none'},
                [`& .${mobileStepperClasses.dotActive}`]: {
                    backgroundColor: colors.greenAccent[400] + "!important",
                },
            }}>
                <MobileStepper position={'static'} activeStep={props.activeStep} backButton={<BackButton onClick={props.backOnClick}  hidden={props.activeStep <= 0}/>} nextButton={<NextButton onClick={props.nextOnClick} disabled={props.nextDisabled} hidden={props.activeStep >= props.steps - 1}/>} steps={props.steps}></MobileStepper>
            </Box>
            <Box sx={{display: {xs: 'none', sm: 'flex'},
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    }}>
                <BackButton onClick={props.backOnClick} hidden={props.activeStep <= 0} />
                <NextButton onClick={props.nextOnClick} disabled={props.nextDisabled} hidden={props.activeStep >= props.steps - 1}/>
            </Box>
        </Box>
    );
}

function BackButton(props: any) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box width={"100px"}>
            <Button
                color="primary"
                onClick={props.onClick}
                // sx={{display: {xs: 'none', md: 'block'}}}
                sx={{
                    backgroundColor: colors.primary[400],
                    color: colors.grey[100],
                    [`:focus`]: {
                        backgroundColor: colors.primary[400],
                    },
                    [`:hover`]: {
                        backgroundColor: { xs: colors.primary[400], sm: colors.greenAccent[600]},
                    },
                    height: "50px",
                    width: "100px",
                    display: props.hidden ? "none" : undefined,
                }}
                disabled={props.disabled}
                tabIndex={-1}
            >
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}>
                    <NavigateBeforeOutlined />
                    <Typography sx={{mr: "5px"}}>Zurück</Typography>
                </Box>
            </Button>
        </Box>
    );
}

function NextButton(props: any) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    return (
        <Box width={"100px"}>
            <Button
                color="primary"
                onClick={props.onClick}
                sx={{
                    backgroundColor: colors.primary[400],
                    color: colors.grey[100],
                    [`:focus`]: {
                        backgroundColor: colors.primary[400],
                    },
                    [`:hover`]: {
                        backgroundColor: { xs: colors.primary[400], sm: colors.greenAccent[600]},
                    },
                    height: "50px",
                    width: "100px",
                    display: props.hidden ? "none" : undefined,
                }}
                disabled={props.disabled}
                // sx={{display: {xs: 'none', md: 'block'}}}
            >
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}>
                    <Typography sx={{ml: "5px"}}>Weiter</Typography>
                    <NavigateNextOutlined />
                </Box>
            </Button>
        </Box>
    );
}

export default ButtonNavigation;