import React, {useEffect, useRef, useState} from 'react';
import {Box, Button, Typography, useTheme} from "@mui/material";
import {tokens} from "../../theme";
import PageHeader from "../../components/PageHeader";
import Category from "./components/Category";
import Product from "./components/Product";
import {IProductVariant, IProductWithVariants, IService, ProductType} from "../../types/ProductTypes";
import {useLoadingContext} from "../../components/LoadingProvider";
import {LinkOffOutlined, LinkOutlined, ReceiptLongOutlined} from "@mui/icons-material";

function Services(props: any) {
    const [selectionSoftware, setSelectionSoftware] = useState<Map<string, string>>(new Map<string, string>());
    const [selectionInternetSecurity, setSelectionInternetSecurity] = useState<Map<string, string>>(new Map<string, string>());
    const [selectionDeviceSecurity, setSelectionDeviceSecurity] = useState<Map<string, string>>(new Map<string, string>());
    const [selectionInsurance, setSelectionInsurance] = useState<Map<string, string>>(new Map<string, string>());

    const [givenSoftwareSelection, setGivenSoftwareSelection] = useState<Map<string, string>>(new Map<string, string>());
    const [givenInternetSecuritySelection, setGivenInternetSecuritySelection] = useState<Map<string, string>>(new Map<string, string>());
    const [givenDeviceSecuritySelection, setGivenDeviceSecuritySelection] = useState<Map<string, string>>(new Map<string, string>());
    const [givenInsuranceSelection, setGivenInsuranceSelection] = useState<Map<string, string>>(new Map<string, string>());

    const [formValid, setFormValid] = useState<boolean>(false);

    const [software, setSoftware] = useState<IProductWithVariants[]>([]);
    const [internetSecurity, setInternetSecurity] = useState<IProductWithVariants[]>([]);
    const [deviceSecurity, setDeviceSecurity] = useState<IProductWithVariants[]>([]);
    const [insurance, setInsurance] = useState<IProductWithVariants[]>([]);

    const selectionRef = useRef();
    const formValidRef = useRef<boolean>(false);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {setShowLoading}: any = useLoadingContext();

    useEffect(() => {
        if (props.software) {
            aggregateDevices(props.software, ProductType.software);
            setShowLoading(false);
        }
    }, [props.software]);

    useEffect(() => {
        if(props.internetSecurity) {
            aggregateDevices(props.internetSecurity, ProductType.internetSecurity);
            setShowLoading(false);
        }
    }, [props.internetSecurity]);

    useEffect(() => {
        if(props.deviceSecurity) {
            aggregateDevices(props.deviceSecurity, ProductType.deviceSecurity);
            setShowLoading(false);
        }
    }, [props.deviceSecurity]);

    useEffect(() => {
        if (props.insurance) {
            aggregateDevices(props.insurance, ProductType.insurance);
            setShowLoading(false);
        }
    }, [props.insurance]);

    useEffect(() => {
        formValidRef.current = formValid;
    }, [formValid]);

    useEffect(() => {
        (selectionRef as any).current = {
            software: selectionSoftware,
            deviceSecurity: selectionDeviceSecurity,
            internetSecurity: selectionInternetSecurity,
            insurance: selectionInsurance
        };
    }, [selectionSoftware, selectionDeviceSecurity, selectionInternetSecurity, selectionInsurance]);

    useEffect(() => {
        let softwareValid: boolean = false;
        let internetSecurityValid: boolean = false;
        let deviceSecurityValid: boolean = false;
        let insuranceValid: boolean = false;

        // Validate software
        if(selectionSoftware && selectionSoftware.size > 0) {
            if (selectionSoftware.size === props.students.length) {
                softwareValid = true;
            } else {
                if (props.requiredProductCategories) {
                    if (props.requiredProductCategories.includes(ProductType.case)) {
                        softwareValid = false;
                    } else {
                        softwareValid = true;
                    }
                }
            }
        } else if (givenSoftwareSelection && givenSoftwareSelection.size > 0) {
            if (givenSoftwareSelection.size === props.students.length) {
                softwareValid = true;
            } else {
                if (props.requiredProductCategories) {
                    if (props.requiredProductCategories.includes(ProductType.case)) {
                        softwareValid = false;
                    } else {
                        softwareValid = true;
                    }
                }
            }
        } else {
            if(props.requiredProductCategories){
                softwareValid = !props.requiredProductCategories.includes(ProductType.software);
            } else {
                softwareValid = true;
            }
        }

        console.log("softwareValid: " + softwareValid)

        // Validate DeviceSecurity
        if(selectionDeviceSecurity && selectionDeviceSecurity.size > 0) {
            if (selectionDeviceSecurity.size === props.students.length) {
                deviceSecurityValid = true;
            } else {
                if (props.requiredProductCategories) {
                    if (props.requiredProductCategories.includes(ProductType.deviceSecurity)) {
                        deviceSecurityValid = false;
                    } else {
                        deviceSecurityValid = true;
                    }
                }
            }
        } else if (givenDeviceSecuritySelection && givenDeviceSecuritySelection.size > 0){
            if (givenDeviceSecuritySelection.size === props.students.length) {
                deviceSecurityValid = true;
            } else {
                if (props.requiredProductCategories) {
                    if (props.requiredProductCategories.includes(ProductType.deviceSecurity)) {
                        deviceSecurityValid = false;
                    } else {
                        deviceSecurityValid = true;
                    }
                }
            }
        } else {
            if(props.requiredProductCategories){
                deviceSecurityValid = !props.requiredProductCategories.includes(ProductType.deviceSecurity);
            } else {
                deviceSecurityValid = true;
            }
        }

        // Validate Internet Security
        if(selectionInternetSecurity && selectionInternetSecurity.size > 0) {
            if (selectionInternetSecurity.size === props.students.length) {
                internetSecurityValid = true;
            } else {
                if (props.requiredProductCategories) {
                    if (props.requiredProductCategories.includes(ProductType.internetSecurity)) {
                        internetSecurityValid = false;
                    } else {
                        internetSecurityValid = true;
                    }
                }
            }
        } else if (givenInternetSecuritySelection && givenInternetSecuritySelection.size > 0) {
            if (givenInternetSecuritySelection.size === props.students.length) {
                internetSecurityValid = true;
            } else {
                if (props.requiredProductCategories) {
                    if (props.requiredProductCategories.includes(ProductType.internetSecurity)) {
                        internetSecurityValid = false;
                    } else {
                        internetSecurityValid = true;
                    }
                }
            }
        } else {
            if(props.requiredProductCategories){
                internetSecurityValid = !props.requiredProductCategories.includes(ProductType.internetSecurity);
            } else {
                internetSecurityValid = true;
            }
        }

        console.log("Test", props.requiredProductCategories)

        // Validate Insurance
        if(selectionInsurance && selectionInsurance.size > 0) {
            if (selectionInsurance.size === props.students.length) {
                insuranceValid = true;
            } else {
                if (props.requiredProductCategories) {
                    if (props.requiredProductCategories.includes(ProductType.insurance)) {
                        insuranceValid = false;
                    } else {
                        insuranceValid = true;
                    }
                }
            }
        } else if (givenInsuranceSelection && givenInsuranceSelection.size > 0) {
            if (givenInsuranceSelection.size === props.students.length) {
                insuranceValid = true;
            } else {
                if (props.requiredProductCategories) {
                    if (props.requiredProductCategories.includes(ProductType.insurance)) {
                        insuranceValid = false;
                    } else {
                        insuranceValid = true;
                    }
                }
            }
        } else {
            if(props.requiredProductCategories){
                insuranceValid = props.requiredProductCategories && !props.requiredProductCategories.includes(ProductType.insurance);
            } else {
                insuranceValid = true;
            }
        }

        console.log("insuranceValid: " + insuranceValid)

        if (softwareValid && deviceSecurityValid && insuranceValid && internetSecurityValid) {
            props.validate(true);
            setFormValid(true);
        } else {
            props.validate(false);
            setFormValid(false);
        }

        if(props.readOnly) {
            props.validate(true);
            setFormValid(true);
        }
    }, [selectionSoftware, selectionDeviceSecurity, selectionInternetSecurity, selectionInsurance, givenSoftwareSelection, givenDeviceSecuritySelection, givenInternetSecuritySelection, givenInsuranceSelection]);

    // Submission on page leave
    useEffect(() => {
        return () => {
            console.log("selectionRef.current: ", selectionRef.current);
            if (formValidRef.current && !props.readOnly) {
                // @ts-ignore
                props.onSelection([selectionRef.current.software ?? new Map(), selectionRef.current.insurance ?? new Map(), selectionRef.current.deviceSecurity ?? new Map(), selectionRef.current.internetSecurity ?? new Map()]);
            }
        }
    }, []);

    useEffect(() => {
        if (props.selectionSoftware) {
            setGivenSoftwareSelection(props.selectionSoftware);
        }
        if (props.selectionDeviceSecurity) {
            setGivenDeviceSecuritySelection(props.selectionDeviceSecurity);
        }
        if (props.selectionInternetSecurity) {
            setGivenInternetSecuritySelection(props.selectionInternetSecurity);
        }
        if (props.selectionInsurance) {
            setGivenInsuranceSelection(props.selectionInsurance);
        }
    }, [props.selectionSoftware, props.selectionDeviceSecurity, props.selectionInternetSecurity, props.selectionInsurance]);

    // ----- HELPER METHODS ----- //
    const aggregateDevices = (services: IService[], type: ProductType) => {
        const temp: IProductWithVariants[] = [];

        for (const service of services) {
            if(!temp.find((item: IProductWithVariants) => item.product.brand === service.brand && item.product.name === service.name && (item.product as IService).fitsFor === service.fitsFor)) {
                const variants: IProductVariant[] = [];
                const variant: IProductVariant = {
                    _id: service._id ?? service.name,
                    color: service.color,
                    price: service.price,
                    modelIdentifier: service.name,
                }
                variants.push(variant);
                temp.push({product: service, variants: variants});
            } else {
                const index = temp.findIndex((item: IProductWithVariants) => item.product.brand === service.brand && item.product.name === service.name && (item.product as IService).fitsFor === service.fitsFor);
                const variant: IProductVariant = {
                    _id: service._id ?? service.name,
                    color: service.color,
                    price: service.price,
                    modelIdentifier: service.name,
                }
                temp[index].variants.push(variant);
            }
        }

        if(type === ProductType.software) {
            setSoftware(temp);
        } else if(type === ProductType.deviceSecurity) {
            setDeviceSecurity(temp);
        } else if (type === ProductType.internetSecurity) {
            setInternetSecurity(temp);
        } else if (type === ProductType.insurance) {
            setInsurance(temp);
        }
    }

    const deviceSelectionHandler = (deviceSelection: Map<string, string>, variants: IProductVariant[], type: ProductType) => {
        if(deviceSelection) {
            const software = new Map<string, string>();
            const deviceSecurity = new Map<string, string>();
            const internetSecurity = new Map<string, string>();
            const insurance = new Map<string, string>();

            if(givenSoftwareSelection && selectionSoftware.size === 0){
                givenSoftwareSelection.forEach((value, key) => {
                    software.set(key, value);
                });
            }
            selectionSoftware.forEach((value, key) => {
                software.set(key, value);
            });


            if(givenDeviceSecuritySelection && selectionDeviceSecurity.size === 0){
                givenDeviceSecuritySelection.forEach((value, key) => {
                    deviceSecurity.set(key, value);
                });
            }
            selectionDeviceSecurity.forEach((value, key) => {
                deviceSecurity.set(key, value);
            });


            if(givenInternetSecuritySelection && selectionInternetSecurity.size === 0){
                givenInternetSecuritySelection.forEach((value, key) => {
                    internetSecurity.set(key, value);
                });
            }
            selectionInternetSecurity.forEach((value, key) => {
                internetSecurity.set(key, value);
            });


            if(givenInsuranceSelection && selectionInsurance.size === 0){
                givenInsuranceSelection.forEach((value, key) => {
                    insurance.set(key, value);
                });
            }
            selectionInsurance.forEach((value, key) => {
                insurance.set(key, value);
            });

            if(type === ProductType.software) {
                if(deviceSelection.size > 0) {
                    console.log("Variants: ", variants);
                    variants.forEach((variant: IProductVariant) => {
                        software.forEach((value: string, key: string) => {
                            if (value === variant._id) {
                                software.delete(key);
                            }
                        });
                    });
                }
                deviceSelection.forEach((value: string, key: string) => {
                    software.set(key, value);
                });

            } else if (type === ProductType.deviceSecurity) {

                if(deviceSelection.size > 0) {
                    variants.forEach((variant: IProductVariant) => {
                        deviceSecurity.forEach((value: string, key: string) => {
                            if (value === variant._id) {
                                deviceSecurity.delete(key);
                            }
                        });
                    });
                }
                deviceSelection.forEach((value: string, key: string) => {
                    deviceSecurity.set(key, value);
                });

            } else if (type === ProductType.internetSecurity) {

                if(deviceSelection.size > 0) {
                    variants.forEach((variant: IProductVariant) => {
                        internetSecurity.forEach((value: string, key: string) => {
                            if (value === variant._id) {
                                internetSecurity.delete(key);
                            }
                        });
                    });
                }
                deviceSelection.forEach((value: string, key: string) => {
                    internetSecurity.set(key, value);
                });

            } else if (type === ProductType.insurance) {

                if(deviceSelection.size > 0) {
                    variants.forEach((variant: IProductVariant) => {
                        insurance.forEach((value: string, key: string) => {
                            if (value === variant._id) {
                                insurance.delete(key);
                            }
                        });
                    });
                }
                deviceSelection.forEach((value: string, key: string) => {
                    insurance.set(key, value);
                });

            }

            setSelectionSoftware(software);
            setSelectionDeviceSecurity(deviceSecurity);
            setSelectionInternetSecurity(internetSecurity);
            setSelectionInsurance(insurance);
        }
    }

    return (
        <Box >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: 3,
                    p: {xs: 1, md: 3},
                    // width: `calc(100% - 24px)`,
                    boxSizing: "border-box"
                }}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <PageHeader title="DIENSTE AUSWÄHLEN" subtitle="Bitte wählen Sie die gewünschten Dienste für Ihr Gerät aus." />
                </Box>

                {(software.length === 0 && deviceSecurity.length === 0 && internetSecurity.length === 0 && insurance.length === 0) ?
                    (
                        <Box
                            sx={{
                                backgroundColor: colors.primary[400],
                                borderRadius: 3,
                                p: 3,
                            }}
                        >
                            <Typography variant="h5" sx={{color: colors.grey[100]}}>Es sind leider keine Geräte verfügbar.</Typography>
                        </Box>
                    ) : (
                        <>
                            {insurance.length > 0 && (
                                <>
                                    <Category title="Versicherung" isRequired={props.requiredProductCategories && props.requiredProductCategories.includes(ProductType.insurance) ? true : false}/>
                                    {insurance.map((device: any, index: number) => {
                                        return (
                                            <Product key={index} readOnly={props.readOnly} product={device.product} variants={device.variants} students={props.students} onSelection={(selection: Map<string, string>, variants: IProductVariant[]) => deviceSelectionHandler(selection, variants, ProductType.insurance)} selections={selectionInsurance.size > 0 ? selectionInsurance : givenInsuranceSelection}/>
                                        )
                                    })}
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            mt: 1,
                                        }}
                                    >
                                        <Button
                                            color="primary"
                                            onClick={() => {
                                                window?.open('https://school.bitbot.eu/absicherung', '_blank')?.focus();
                                            }
                                            }
                                            sx={{
                                                backgroundColor: colors.primary[400],
                                                color: colors.grey[100],
                                                [`:focus`]: {
                                                    backgroundColor: colors.primary[400],
                                                },
                                                [`:hover`]: {
                                                    backgroundColor: { xs: colors.primary[400], sm: colors.greenAccent[600]},
                                                },
                                                height: "50px",
                                                width: "400px",
                                                display: props.hidden ? "none" : undefined,
                                            }}
                                        >
                                            <Box sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                            }}>
                                                <Typography sx={{mx: "5px"}}>Versicherungen vergleichen</Typography>
                                                <LinkOutlined />
                                            </Box>
                                        </Button>
                                    </Box>
                                </>
                            )}

                            {software.length > 0 && (
                                <>
                                    <Category title="Software & Lizenzen" isRequired={props.requiredProductCategories && props.requiredProductCategories.includes(ProductType.software) ? true : false}/>
                                    {software.map((device: any, index: number) => {
                                        return (
                                            <Product key={index} readOnly={props.readOnly} product={device.product} variants={device.variants} students={props.students} onSelection={(selection: Map<string, string>, variants: IProductVariant[]) => deviceSelectionHandler(selection, variants, ProductType.software)} selections={selectionSoftware.size > 0 ? selectionSoftware : givenSoftwareSelection}/>
                                        )
                                    })}
                                </>
                            )}

                            {internetSecurity.length > 0 && (
                                <>
                                    <Category title="Internet-Schutz" isRequired={props.requiredProductCategories && props.requiredProductCategories.includes(ProductType.internetSecurity) ? true : false}/>
                                    {internetSecurity.map((device: any, index: number) => {
                                        return (
                                            <Product key={index} readOnly={props.readOnly} product={device.product} variants={device.variants} students={props.students} onSelection={(selection: Map<string, string>, variants: IProductVariant[]) => deviceSelectionHandler(selection, variants, ProductType.internetSecurity)} selections={selectionInternetSecurity.size > 0 ? selectionInternetSecurity : givenInternetSecuritySelection}/>
                                        )
                                    })}
                                </>
                            )}

                            {deviceSecurity.length > 0 && (
                                <>
                                    <Category title="Geräte-Schutz" isRequired={props.requiredProductCategories && props.requiredProductCategories.includes(ProductType.deviceSecurity) ? true : false}/>
                                    {deviceSecurity.map((device: any, index: number) => {
                                        return (
                                            <Product key={index} readOnly={props.readOnly} product={device.product} variants={device.variants} students={props.students} onSelection={(selection: Map<string, string>, variants: IProductVariant[]) => deviceSelectionHandler(selection, variants, ProductType.deviceSecurity)} selections={selectionDeviceSecurity.size > 0 ? selectionDeviceSecurity : givenDeviceSecuritySelection}/>
                                        )
                                    })}
                                </>
                            )}
                        </>
                    )}
            </Box>
        </Box>
    );
}


export default Services;