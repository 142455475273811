/**
 * A price type
 * @description A price type for a product or a cart item
 * @typedef {object} Price
 * @property {number} total.required - The total price of the object
 * @property {number} tax.required - The included taxes in the total price of the object
 * @property {string} currency.required - The currency of the price
 */
export default interface Price {
    total: number;
    tax: number;
    currency: Currency;
}

export enum Currency {
    EUR = 'EUR',
    USD = 'USD'
}