import React, {useEffect, useRef, useState} from 'react';
import {Box, Button, formLabelClasses, IconButton, inputClasses, TextField, Typography, useTheme} from "@mui/material";
import PageHeader from "../../components/PageHeader";
import {tokens} from "../../theme";
import {IAuthOrgRequest} from "../../types/AuthTypes";
import {useUserContext} from "../../api/provider/UserProvider";
import {IUserCustomer, IUserChild} from "../../types/UserTypes";
import {DeleteOutline, AddOutlined} from "@mui/icons-material";
import {useLoadingContext} from "../../components/LoadingProvider";

function Student({submitData, students, ...props}: any) {
    const [formData, setFormData] = useState<IUserChild[]>([{} as IUserChild]);
    const [formValid, setFormValid] = useState<boolean>(false);

    const formRef = useRef();
    const formValidRef = useRef<boolean>();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {setShowLoading}: any = useLoadingContext();

    useEffect(() => {
        (formRef as any).current = formData;
    }, [formData]);

    useEffect(() => {
        formValidRef.current = formValid;
    }, [formValid]);

    useEffect(() => {
        setShowLoading(false);

        // Submit data on unmount
        return () => {
            if(formValidRef.current && !props.readOnly) {
                console.log("childform submission: ", formRef.current);
                submitData(formRef.current);
            }
        }
    }, []);

    useEffect(() => {
        // Prefill form with user data if available
        if(students && students.length > 0){
            setFormData(students);
        }
    }, [students]);

    useEffect(() => {
        console.log(formData);
        validate();
    }, [formData]);

    // Validate form fields on change
    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const fieldName = event.currentTarget.name;
        const value = event.currentTarget.value;
        if(value || value === "") {
            const student = formData[index];
            if(fieldName === "firstName") {
                student.firstName = value;
                // Insert new student into array
                formData[index] = student;
                setFormData([...formData]);
            } else if (fieldName === "lastName") {
                student.lastName = value;
                // Insert new student into array
                formData[index] = student;
                setFormData([...formData]);
            } else if (fieldName === "class") {
                student.class = value;
                // Insert new student into array
                formData[index] = student;
                setFormData([...formData]);
            }
        }
    }

    // Validate form
    const validate = () => {
        for (const student of formData) {
            if(!student.firstName || !student.lastName || !student.class) {
                props.validate(false);
                setFormValid(false);
                return;
            }
        }

        setFormValid(true);
        props.validate(true);
    }

    return (
        <Box >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: 3,
                    p: {xs: 1, md: 3},
                    // width: `calc(100% - 24px)`,
                    boxSizing: "border-box"
                }}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <PageHeader title="SCHÜLER*INNEN ANLEGEN" subtitle="Auf dieser Seite können Sie Ihre Kinder anlegen, für die ein Gerät über dieses Bestellportal gekauft werden soll." />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "stretch",
                        flexWrap: "wrap",
                        // backgroundColor: colors.primary[400],
                        // borderRadius: 3,
                        // p: 3,
                        columnGap: 3,
                        width: "100%",
                        boxSizing: "border-box",
                        '& form': {
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                        },
                        [`& .${formLabelClasses.focused}`]: {
                            color: colors.grey[100],
                        },
                        [`& .${inputClasses.focused}`]: {
                            color: colors.grey[100] + ' !important',
                            '& fieldset': {
                                borderColor: colors.grey[100] + ' !important',
                            }
                        },
                    }}
                >
                    {formData.map((student: IUserChild, index: number) => (
                        <Box key={index}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-around",
                                width: {xs: '100%', md: 'calc(50% - 12px)'},
                                backgroundColor: colors.primary[400],
                                borderRadius: 3,
                                p: 3,
                                mt: 3,
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    mb: 2,
                                }}
                            >
                                <Typography variant="h5" sx={{color: colors.grey[100]}}>{'Kind ' + (index + 1) }</Typography>
                                {formData.length > 1 && (
                                    <IconButton
                                        onClick={() => {
                                            const newStudent = [...formData];
                                            newStudent.splice(index, 1);
                                            setFormData(newStudent);
                                        }}
                                        disabled={props.readOnly}
                                    >
                                        <DeleteOutline />
                                    </IconButton>
                                )}
                            </Box>
                            <form
                                onSubmit={(event: React.FormEvent) => {
                                    event.preventDefault();
                                }}
                                style={{width: '100%'}}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        gap: 2,
                                        [theme.breakpoints.down('sm')]: {
                                            flexDirection: 'column',
                                        }
                                    }}
                                >
                                    <TextField
                                        required
                                        variant="outlined"
                                        label="Vorname"
                                        name="firstName"
                                        sx={{width: {xs: '100%', md: '50%'}}}
                                        type={"text"}
                                        onChange={(e) => handleChange(e, index)}
                                        value={formData[index].firstName ?? ''}
                                        disabled={props.readOnly}
                                    />
                                    <TextField
                                        required
                                        variant="outlined"
                                        label="Nachname"
                                        name="lastName"
                                        sx={{width: {xs: '100%', md: '50%'}}}
                                        type={"text"}
                                        onChange={(e) => handleChange(e, index)}
                                        value={formData[index].lastName ?? ''}
                                        disabled={props.readOnly}
                                    />
                                </Box>
                                <TextField
                                    required
                                    variant="outlined"
                                    label="Klasse"
                                    name="class"
                                    sx={{width: '100%'}}
                                    type={"text"}
                                    onChange={(e) => handleChange(e, index)}
                                    value={formData[index].class ?? ''}
                                    disabled={props.readOnly}
                                />
                            </form>
                        </Box>
                    ))}
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Button
                        color="primary"
                        onClick={() => {
                            const student: IUserChild = {} as IUserChild;
                            formData.push(student);
                            setFormData([...formData]);
                        }}
                        sx={{
                            backgroundColor: colors.primary[400],
                            color: colors.grey[100],
                            [`:focus`]: {
                                backgroundColor: colors.primary[400],
                            },
                            [`:hover`]: {
                                backgroundColor: { xs: colors.primary[400], sm: colors.greenAccent[600]},
                            },
                            height: "50px",
                            width: "250px",
                        }}
                        disabled={props.disabled || props.readOnly}
                        // sx={{display: {xs: 'none', md: 'block'}}}
                    >
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}>
                            <AddOutlined />
                            <Typography sx={{mr: "5px"}}>Weiteres Kind hinzufügen</Typography>
                        </Box>
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

export default Student;