import React, {createContext, useContext, useEffect, useRef, useState} from 'react';
import rest from "../http-common";
import {useAuthContext} from "./AuthProvider";
import {AxiosResponse} from "axios";
import {Box} from "@mui/material";
import ErrorMessage from "../../components/ErrorMessage";
import {IUser, IUserCustomer, IUserAdmin, IUserManager, IUserChild, UserRole} from "../../types/UserTypes";

const UserContext = createContext({});
export function UserProvider({children}: any) {
    // Order
    const [user, setUser] = useState<IUser | IUserAdmin | IUserCustomer | IUserManager | IUserChild>();
    const [students, setStudents] = useState<IUserChild[]>([]);
    const [submittedStudents, setSubmittedStudents] = useState<IUserChild[]>([]);

    // Admin
    const [users, setUsers] = useState<IUser[]>([]);

    const [error, setError] = useState<string>("");
    const [showError, setShowError] = useState<boolean>(false);
    const { userId, organisationId, isUserLoggedIn, revokeAccessToken, userRole, initialised }: any = useAuthContext();

    useEffect(() => {
        if (showError) {
            setTimeout(() => {
                setShowError(false);
            }, 5000);
        }
    },[showError]);

    useEffect(() => {
        if(submittedStudents.length > 0) {
            addStudentsToCustomer(submittedStudents, students, isUserLoggedIn, user as IUser);
            setSubmittedStudents([]);
        }
    }, [submittedStudents]);

    // Admin
    useEffect(() => {
        if(isUserLoggedIn) {
            if(userRole === UserRole.admin) {
                getUsers().then(() => {
                    console.log("Users loaded");
                });
            } else if (userRole === UserRole.manager) {
                getUsers(organisationId).then(() => {
                    console.log("Users loaded");
                });
            }
        }
    },[isUserLoggedIn, userRole, organisationId]);

    // API calls
    const getUser = () => {
        return rest.get("/users/" + userId).then((res: AxiosResponse<IUser>) => {
            setUser(res.data);
            return res.data;
        }).catch((err) => {
            if(err.response.status === 401) {
                revokeAccessToken();
            } else {
                setError(err.response.data);
                setShowError(true);
            }
        });
    };

    const getUserByID = (userId: string) => {
        return rest.get("/users/" + userId).then((res: AxiosResponse<IUser>) => {
            return res.data;
        }).catch((err) => {
            if(err.response.status === 401) {
                revokeAccessToken();
            } else {
                setError(err.response.data);
                setShowError(true);
            }
        });
    };

    const getUsers = (organisationId?: string) => {
        let url = "/users";
        if(organisationId) {
            url += "/?organisation=" + organisationId;
        }
        return rest.get(url).then((res: AxiosResponse<IUser[]>) => {
            setUsers(res.data);
            return res.data;
        }).catch((err) => {
            if(err.response.status === 401) {
                // revokeAccessToken();
            }
            setError(err.response.data);
            setShowError(true);
        });
    }

    const createCustomer = (formData: IUserCustomer) => {
        return rest.post("/users", {
            "eMail": formData.eMail,
            "password": formData.password,
            "firstName": formData.firstName,
            "lastName": formData.lastName,
            "role": UserRole.customer,
            "organisation": organisationId,
        }).then((res: AxiosResponse<IUser>) => {
            setUser(res.data);
        }).catch((err) => {
            if(err.response.status === 401) {
                revokeAccessToken();
            }
            setError(err.response.data);
            setShowError(true);
        });
    };

    const addStudentsToCustomer = (studentsSubmitted: IUserChild[], students: IUserChild[], userLoggedIn: boolean, user: IUser) => {
        if(userLoggedIn && user && user?._id) {
            let promises: Promise<void | AxiosResponse>[] = [];
            // Add new students that are not already in the list
            for (const student of studentsSubmitted) {
                console.log("Student: ", student);
                if(student._id === undefined) {
                     promises.push(addStudentToCustomer(student, user._id ?? '').then((res: any) => {
                        console.log("Student added: ", res);
                        // Replace entry from submittedStudents
                        setStudents([...students.filter((s) => s.firstName !== student.firstName && s.lastName !== student.lastName && s.class !== student.class), res.data]);
                    }));
                }
            }

            // Remove students that are not in the list anymore
            for (const student of students) {
                if(!studentsSubmitted.includes(student)) {
                    promises.push(deleteStudentFromCustomer(student, user._id ?? '').then((res: any) => {
                        console.log("Student deleted: ", res);
                        setStudents(students.filter((s) => s._id !== student._id));
                    }));
                }
            }

            // Reload user & students
            Promise.all(promises).then(() => {
                getUser();
            });
        }
    };

    const addStudentToCustomer = (student: IUserChild, id: string): Promise<void | AxiosResponse> => {
        console.log("Student to add: ", student._id);
        if(student.firstName === "" || student.lastName === "" || student.class === "" || student._id) return new Promise<void>((resolve, reject) => {resolve();});
        return rest.post("/users/" + id + "/children", {
            "eMail": student.eMail,
            "password": student.password,
            "firstName": student.firstName,
            "lastName": student.lastName,
            "role": UserRole.child,
            "organisation": organisationId,
            "parentCustomer": id,
            "class": student.class,
        }).then((res: AxiosResponse<IUserChild>) => {
            return res;
            // if(students.includes(res.data)) return;
            // setStudents([...students, res.data]);
        }).catch((err) => {
            if (err.response.status === 401) {
                revokeAccessToken();
            }
            setError(err.response.data);
            setShowError(true);
        });
    };

    const deleteStudentFromCustomer = (student: IUserChild, id: string) => {
        if(student._id === "") return new Promise<void>((resolve, reject) => {resolve();});
        return rest.delete("/users/" + id + "/children/" + student._id).then((res: AxiosResponse<IUserChild>) => {
            return res;
            // setStudents(students.filter((s) => s._id !== student._id));
        }).catch((err) => {
            if (err.response.status === 401) {
                revokeAccessToken();
            }
            setError(err.response.data);
            setShowError(true);
        });
    };

    const getAllChildren = (user: IUserCustomer) => {
        if(isUserLoggedIn) {
            const children: string[] | undefined = (user as IUserCustomer)?.children;
            const temp: IUserChild[] = [];
            if(children && children.length > 0) {
                // (async () => {
                //     for (const child of students) {
                //         await getUserByID(child).then((res) => {
                //             temp.push(res as IUserChild);
                //         }).catch((err) => {
                //             if (err.response.status === 401) {
                //                 revokeAccessToken();
                //             }
                //             setError(err.response.data);
                //             setShowError(true);
                //         });
                //     }
                //     setStudents(temp);
                // })();

                // setStudents([]);
                for (const child of children) {
                    getUserByID(child).then((res) => {
                        temp.push(res as IUserChild);
                        temp.sort((a, b) => {
                            if(a.firstName < b.firstName) return -1;
                            if(a.firstName > b.firstName) return 1;
                            if(a.lastName < b.lastName) return -1;
                            if(a.lastName > b.lastName) return 1;
                            return 0;
                        });
                        setStudents(temp);
                    }).catch((err) => {
                        if (err.response.status === 401) {
                            revokeAccessToken();
                        }
                        setError(err.response.data);
                        setShowError(true);
                    });
                }
            }
        }

        return new Promise<void>((resolve, reject) => {resolve();});
    }

    const filterUsersByRole = (users: IUser[], role: UserRole) => {
        const result: IUser[] = [];
        for (const user of users) {
            if(user.role === role) {
                result.push(user);
            }
        }
        return result;
    }

    useEffect(() => {
        // if(userId && userId !== "" && userId !== undefined) {
        if(isUserLoggedIn) {
            getUser().then(() => {
                console.log("User loaded");
            });
        }
    }, [isUserLoggedIn]);

    useEffect(() => {
        if(user) {
            getAllChildren(user as IUserCustomer);
        }
    }, [user]);

    // Clear data when user logs out
    useEffect(() => {
        if(!isUserLoggedIn){
            setUser(undefined);
            setStudents([]);
            setUsers([]);
        }
    }, [isUserLoggedIn]);

    return (
        <UserContext.Provider
            value={{
                user,
                students,
                users,
                createCustomer,
                setSubmittedStudents,
                filterUsersByRole,
            }}
        >
            {/* Alert */}
            {showError && (
                <Box
                    sx={{
                        position: "fixed",
                        bottom: 0,
                        right: 0,
                        margin: 4,
                        zIndex: 2147483001,
                        maxWidth: "100%",
                        // width: "00px"
                    }}
                >
                    <ErrorMessage error={error} severity={"error"} />
                </Box>
            )}
            {children}
        </UserContext.Provider>
    );
}

export const useUserContext = () => useContext(UserContext);