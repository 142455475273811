import React, {useContext, useEffect, useState} from "react";
import {ColorModeContext, themeSettings, useMode} from "./theme";
import { CssBaseline, ThemeProvider } from '@mui/material';
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/sidebar/MyProSidebar";
import {Route, Routes} from "react-router-dom";
import Dashboard from "./scenes/backend/dashboard/Dashboard";
import {MyProSidebarProvider} from "./scenes/global/sidebar/sidebarContext";
import Login from "./scenes/login/Login";
import {useAuthContext} from "./api/provider/AuthProvider";
import Backend from "./scenes/backend/Backend";
import Order from "./scenes/order";
// import Orders from "./scenes/orders/Orders";


export default function App() {
    const [theme, colorMode]: any = useMode();
    const { isOrgLoggedIn, isUserLoggedIn, loggedIn, userRole, initialised }: any = useAuthContext();

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {
                    initialised &&
                    (
                        <>
                            {loggedIn() ? (
                                isOrgLoggedIn ? (
                                    <Order />
                                ) : (
                                    (userRole === "admin" || userRole === "manager") ? (
                                        <Backend />
                                    ) : (
                                        <Order />
                                    )
                                )
                            ) : (
                                <Login />
                            )}
                        </>
                    )
                }
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}