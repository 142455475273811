import React from "react";
import PageHeader from "../../../../components/PageHeader";
import {Box, useTheme} from "@mui/material";
import {tokens} from "../../../../theme";
import {useProductContext} from "../../../../api/provider/ProductProvider";
import {DataGrid, GridToolbarContainer, GridToolbarExport} from "@mui/x-data-grid";
import {useOrderContext} from "../../../../api/provider/OrderProvider";
import IOrder, {OrderStatus} from "../../../../types/OrderType";
import {IProduct} from "../../../../types/ProductTypes";
import {OrderItem} from "../../../../../../../src/core/models/order";

function BackendProductsOrder(props: any) {
    const [organisationId, setOrganisationId] = React.useState<string>("");
    const [selectedProducts, setSelectedProducts] = React.useState<string[]>([]);
    // const [availableProducts, setAvailableProducts] = React.useState<any>([]);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { availableProducts }: any = useProductContext();

    const { orders }: any = useOrderContext();

    // useEffect(() => {
    //     console.log("productVisibility", productVisibility);
    //     if (availableProducts) {
    //         setAvailableProducts(productVisibility);
    //     }
    // }, [availableProducts]);

    // Calculate the number of products that were ordered
    const calculateNumberOfProducts = (product: IProduct, orders: IOrder[], orderStatus: OrderStatus[]): number => {
        let amount: number = 0;

        // Iterate over all orders
        orders.forEach((order: IOrder) => {
            if(orderStatus.includes(order.status)) {
                // Iterate over all products of the order
                order.orderItems.forEach((orderItem: OrderItem) => {
                    if (orderItem.productId === product._id) {
                        amount += 1;
                    }
                });
            }
        });

        return amount;
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }


    const columns = [
        {
            field: "pictures",
            headerName: "",
            flex: 0.3,
            sortable: false,
            renderCell: (params: any) => {
                return (
                    <img
                        src={params.row.pictures[0]}
                        alt={params.row.name}
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                        }}
                    />
                );
            }
        },
        // {
        //     field: "_id",
        //     headerName: "ID",
        //     flex: 1,
        // },
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            minWidth: 200,
        },
        {
            field: "modelIdentifier",
            headerName: "ModelIdentifier",
            flex: 0.5,
            minWidth: 100,
        },
        {
            field: "type",
            headerName: "Kategorie",
            flex: 0.4,
            minWidth: 70,
        },
        {
            field: "storage",
            headerName: "Speicher",
            flex: 0.3,
            minWidth: 60,
        },
        {
            field: "color",
            headerName: "Farbe",
            flex: 0.5,
            minWidth: 70,
        },
        {
            field: "duration",
            headerName: "Laufzeit",
            flex: 0.3,
            minWidth: 70,
        },
        {
            field: "price",
            headerName: "Preis",
            flex: 0.3,
            valueGetter: (params: any) => {
                return params.row.price.total + " " + ((params.row.price.currency === "EUR") ? "€" : "$");
            },
            minWidth: 60
        },
        {
            field: "amountPaid",
            headerName: "Anzahl in bezahlten Bestellungen",
            flex: 0.5,
            minWidth: 70,
            valueGetter: (params: any) => {
                return calculateNumberOfProducts(params.row, orders, [OrderStatus.paid]);
            }
        },
        {
            field: "amountAuthorised",
            headerName: "Anzahl in authorisierten Bestellungen",
            flex: 0.5,
            minWidth: 70,
            valueGetter: (params: any) => {
                return calculateNumberOfProducts(params.row, orders, [OrderStatus.paymentAuthorised]);
            }
        },
        {
            field: "amountOpen",
            headerName: "Anzahl in offenen Bestellungen",
            flex: 0.5,
            minWidth: 70,
            valueGetter: (params: any) => {
                return calculateNumberOfProducts(params.row, orders, [OrderStatus.open, OrderStatus.checkedOut]);
            }
        },
        {
            field: "amountTotal",
            headerName: "Anzahl gesamt in Bestellungen",
            flex: 0.5,
            minWidth: 70,
            valueGetter: (params: any) => {
                return calculateNumberOfProducts(params.row, orders, [OrderStatus.paid, OrderStatus.delivered, OrderStatus.paymentAuthorised, OrderStatus.open, OrderStatus.checkedOut]);
            }
        }
    ]

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <PageHeader title="PRODUKT-BESTELLUNG" subtitle="Übersicht über Produkte, die bestellt wurden." />
            </Box>

            <Box>
                Auswahl der Organisation für Admins
            </Box>

            <Box
                sx={{
                    mt: 6,
                    height: {xs: "75vh", md: "80vh"},
                    "& .MuiDataGrid-root": {
                        border: "none"
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none"
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.primary[400],
                        color: colors.greenAccent[100],
                        borderBottom: "none",
                        borderRadius: "10px 10px 0 0"
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: theme.palette.background.default,
                    },
                    "& .MuiDataGrid-footerContainer": {
                        backgroundColor: colors.primary[400],
                        color: colors.greenAccent[100],
                        borderTop: "none",
                        borderRadius: "0 0 10px 10px"
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.greenAccent[200]} !important`
                    }
                }}
            >
                <DataGrid
                    loading={!(availableProducts.length > 0)}
                    getRowId={(row) => row._id}
                    columns={columns}
                    rows={availableProducts}
                    slots={{
                        toolbar: CustomToolbar
                    }}
                />
            </Box>
        </Box>
    );
}

export default BackendProductsOrder;