import {Box, Typography, useTheme} from "@mui/material";
import {tokens} from "../../../theme";
import {CheckCircleOutlined, CircleOutlined} from "@mui/icons-material";
import React from "react";

function Category(props: any) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: colors.primary[400],
                borderRadius: 3,
                p: 3,
                width: "100%",
                boxSizing: "border-box",
                mt: {xs: 6, md: 3}
            }}
        >
            <Typography variant="h3" sx={{color: colors.primary[100]}}>{props.title}</Typography>
            { props.isRequired ? (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <CheckCircleOutlined sx={{color: colors.greenAccent[400]}}/>
                    <Typography variant="h5" sx={{color: colors.primary[100], ml: 1}}>Erforderlich</Typography>
                </Box>
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <CircleOutlined sx={{color: colors.greenAccent[400]}}/>
                    <Typography variant="h5" sx={{color: colors.primary[100], ml: 1}}>Optional</Typography>
                </Box>
            )}
        </Box>
    );
}

export default Category;