import React from 'react';
import OrderStepper from "./OrderStepper";
import {
    Box,
    Step,
    stepConnectorClasses,
    stepIconClasses,
    StepLabel,
    Stepper,
    Typography,
    useTheme
} from "@mui/material";
import {tokens} from "../../../theme";
import IOrderTimestamp from "../../../types/OrderTimestampType";
import {
    CreditScoreOutlined, EditNoteOutlined,
    LocalShippingOutlined, InventoryOutlined, HomeWorkOutlined, CreditCardOutlined
} from "@mui/icons-material";

function OrderStatusDisplay(props: any) {
    const status = [
        'Bestellung aufgegeben',
        'Bestellung verifiziert',
        'Zahlung authorisiert',
        'Zahlung eingegangen',
        'Bestellung versendet',
        'Bestellung abgeschlossen'
    ];

    return (
        <StatusStepper steps={status} activeStep={props.activeStep ?? 0} timestamps={props.orderTimestamps} />
    );
}

function StatusStepper(props: any) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // const timestampLabels: string[] = [
    //     'openAt',
    //     'checkedOutAt',
    //     'paidAt',
    //     'cancelledAt',
    //     'shippedAt',
    //     'deliveredAt'
    // ];


    return (
        <>
            <Box sx={{ width: '100%', mt: 2, display: {xs: 'none', sm: 'block' }}}>
                <Stepper activeStep={props.activeStep} alternativeLabel
                         sx={{
                             [`& .${stepConnectorClasses.completed}`]: {
                                 color: colors.greenAccent[400] + "!important",
                             },
                             [`& .${stepConnectorClasses.active}`]: {
                                 color: colors.blueAccent[400] + "!important",
                             },
                         }}
                >
                    {props.steps.map((label: string, index: number) => {
                        const labelProps: {
                            optional?: React.ReactNode;
                            error?: boolean;
                            icon?: React.ReactNode;
                        } = {};

                        if(props.timestamps) {
                            let timestamps: IOrderTimestamp = props.timestamps;
                            let date: Date | undefined;
                            switch (index) {
                                case 0: date = new Date(timestamps.openAt); break;
                                case 1: date = new Date(timestamps.checkedOutAt); break;
                                case 2: date = new Date(timestamps.paymentAuthorisedAt); break;
                                case 3: date = new Date(timestamps.paidAt); break;
                                case 4: date = new Date(timestamps.shippedAt); break;
                                case 5: date = new Date(timestamps.deliveredAt); break;
                                default: break;
                            }

                            if(date !== undefined && date.toLocaleDateString() !== 'Invalid Date' && date.toLocaleTimeString() !== 'Invalid Date'){
                                labelProps.optional = (
                                    <Typography variant="caption"
                                                // color={colors.greenAccent[400]}
                                    >
                                        {date.toLocaleDateString()} {date.toLocaleTimeString()}
                                    </Typography>
                                );
                            }
                        }

                        switch (index) {
                            case 0: labelProps.icon = <EditNoteOutlined />; break;
                            case 1: labelProps.icon = <InventoryOutlined />; break;
                            case 2: labelProps.icon = <CreditCardOutlined />; break;
                            case 3: labelProps.icon = <CreditScoreOutlined />; break;
                            case 4: labelProps.icon = <LocalShippingOutlined />; break;
                            case 5: labelProps.icon = <HomeWorkOutlined />; break;
                            default: break;
                        }

                        return (
                            <Step key={label}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Box>
            <Box sx={{ width: '100%', display: {xs: 'flex', sm: 'none' }, justifyContent: 'center'}}>
                <Stepper activeStep={props.activeStep} orientation={'vertical'}
                         sx={{
                             [`& .${stepConnectorClasses.completed}`]: {
                                 color: colors.greenAccent[400] + "!important",
                             },
                             [`& .${stepConnectorClasses.active}`]: {
                                 color: colors.blueAccent[400] + "!important",
                             },
                         }}
                >
                    {props.steps.map((label: string, index: number) => {
                        const labelProps: {
                            optional?: React.ReactNode;
                            error?: boolean;
                            icon?: React.ReactNode;
                        } = {};

                        if(props.timestamps) {
                            let timestamps: IOrderTimestamp = props.timestamps;
                            let date: Date | undefined;
                            switch (index) {
                                case 0: date = new Date(timestamps.openAt); break;
                                case 1: date = new Date(timestamps.checkedOutAt); break;
                                case 2: date = new Date(timestamps.paymentAuthorisedAt); break;
                                case 3: date = new Date(timestamps.paidAt); break;
                                case 4: date = new Date(timestamps.shippedAt); break;
                                case 5: date = new Date(timestamps.deliveredAt); break;
                                default: break;
                            }

                            if(date !== undefined && date.toLocaleDateString() !== 'Invalid Date' && date.toLocaleTimeString() !== 'Invalid Date'){
                                labelProps.optional = (
                                    <Typography variant="caption"
                                        // color={colors.greenAccent[400]}
                                    >
                                        {date.toLocaleDateString()} {date.toLocaleTimeString()}
                                    </Typography>
                                );
                            }
                        }

                        switch (index) {
                            case 0: labelProps.icon = <EditNoteOutlined />; break;
                            case 1: labelProps.icon = <InventoryOutlined />; break;
                            case 2: labelProps.icon = <CreditCardOutlined />; break;
                            case 3: labelProps.icon = <CreditScoreOutlined />; break;
                            case 4: labelProps.icon = <LocalShippingOutlined />; break;
                            case 5: labelProps.icon = <HomeWorkOutlined />; break;
                            default: break;
                        }

                        return (
                            <Step key={label}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Box>
        </>
    );
}

export default OrderStatusDisplay;