import React, {useEffect, useState} from 'react';
import {
    Box,
    formLabelClasses,
    IconButton,
    InputAdornment,
    inputClasses,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import PageHeader from "../../components/PageHeader";
import {tokens} from "../../theme";
import YupPassword from 'yup-password'
import * as yup from "yup";
import {useFormik} from "formik";
import {useLoadingContext} from "../../components/LoadingProvider";
import {Visibility, VisibilityOff} from "@mui/icons-material";

YupPassword(yup) // extend yup

interface FormElements extends HTMLFormControlsCollection {
    eMail: HTMLInputElement;
    password: HTMLInputElement;
    passwordVerification: HTMLInputElement;
    firstName: HTMLInputElement;
    lastName: HTMLInputElement;
    street: HTMLInputElement;
    houseNumber: HTMLInputElement;
    additionalInformation: HTMLInputElement;
    postalCode: HTMLInputElement;
    city: HTMLInputElement;
}
interface UserDataFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}

function Data({submitData, user, invoiceAddress, ...props}: any) {
    const [userExists, setUserExists] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {setShowLoading}: any = useLoadingContext();

    const validationSchemaOrg = yup.object({
        firstName: yup.string().required("Bitte geben Sie einen Vornamen ein.").min(2, "Der Vorname muss mindestens 2 Zeichen lang sein.").max(50, "Der Vorname darf maximal 50 Zeichen lang sein."),
        lastName: yup.string().required("Bitte geben Sie einen Nachnamen ein.").min(2, "Der Nachname muss mindestens 2 Zeichen lang sein.").max(50, "Der Nachname darf maximal 50 Zeichen lang sein."),
        eMail: yup.string().required("Bitte geben Sie eine E-Mail Adresse ein.").email("Bitte geben Sie eine gültige E-Mail Adresse ein."),
        password: yup.string().required("Bitte geben Sie ein Passwort ein.").min(8, "Das Passwort muss mindestens 8 Zeichen lang sein.").minLowercase(1, "Das Passwort muss mindestens einen Kleinbuchstaben enthalten.").minUppercase(1, "Das Passwort muss mindestens einen Großbuchstaben haben.").minNumbers(1, "Das Passwort muss mindestens eine Zahl enthalten.").max(50, "Das Passwort darf maximal 50 Zeichen lang sein."),
        street: yup.string().required("Bitte geben Sie eine Straße ein.").min(2, "Die Straße muss mindestens 2 Zeichen lang sein.").max(50, "Die Straße darf maximal 50 Zeichen lang sein."),
        houseNumber: yup.string().required("Bitte geben Sie eine Hausnummer ein.").min(1, "Die Hausnummer muss mindestens 1 Zeichen lang sein.").max(10, "Die Hausnummer darf maximal 10 Zeichen lang sein.").minNumbers(1, "Die Hausnummer muss mindestens eine Zahl enthalten."),
        additionalInformation: yup.string().max(75, "Die Adresszusatzzeile darf maximal 75 Zeichen lang sein."),
        postalCode: yup.string().required("Bitte geben Sie eine Postleitzahl ein.").minNumbers(5, "Eine Postleitzahl sollte aus 5 Zahlen bestehen.").min(5, "Die Postleitzahl muss genau 5 Zeichen lang sein.").max(5, "Die Postleitzahl muss genau 5 Zeichen lang sein."),
        city: yup.string().required("Bitte geben Sie eine Stadt ein.").min(2, "Die Stadt muss mindestens 2 Zeichen lang sein.").max(50, "Die Stadt darf maximal 50 Zeichen lang sein."),
        passwordVerification: yup.string().required('Bitte geben Sie Ihr Passwort erneut ein.').oneOf([yup.ref('password')], 'Die Passwörter stimmen nicht überein.'),
    });

    const validationSchemaUser = yup.object({
        street: yup.string().required("Bitte geben Sie eine Straße ein.").min(2, "Die Straße muss mindestens 2 Zeichen lang sein.").max(50, "Die Straße darf maximal 50 Zeichen lang sein."),
        houseNumber: yup.string().required("Bitte geben Sie eine Hausnummer ein.").min(1, "Die Hausnummer muss mindestens 1 Zeichen lang sein.").max(10, "Die Hausnummer darf maximal 10 Zeichen lang sein.").minNumbers(1, "Die Hausnummer muss mindestens eine Zahl enthalten."),
        additionalInformation: yup.string().max(75, "Die Adresszusatzzeile darf maximal 75 Zeichen lang sein."),
        postalCode: yup.string().required("Bitte geben Sie eine Postleitzahl ein.").minNumbers(5, "Eine Postleitzahl sollte aus 5 Zahlen bestehen.").min(5, "Die Postleitzahl muss genau 5 Zeichen lang sein.").max(5, "Die Postleitzahl muss genau 5 Zeichen lang sein."),
        city: yup.string().required("Bitte geben Sie eine Stadt ein.").min(2, "Die Stadt muss mindestens 2 Zeichen lang sein.").max(50, "Die Stadt darf maximal 50 Zeichen lang sein."),
    });

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            street: '',
            houseNumber: '',
            additionalInformation: '',
            postalCode: '',
            city: '',
            eMail: '',
            password: '',
            passwordVerification: '',
        },
        validationSchema: userExists ? validationSchemaUser : validationSchemaOrg,
        onSubmit: (values) => {
            if(formik.isValid){
                submitData(values);
            }
        },
    });

    useEffect(() => {
        // Submit data on unmount
        return () => {
            formik.handleSubmit();
        }
    }, []);

    // Prefill form with user / address data if available
    useEffect(() => {
        if(user && invoiceAddress){
            formik.setFieldValue('firstName', user.firstName, false);
            formik.setFieldValue('lastName', user.lastName, false);
            formik.setFieldValue('eMail', user.eMail, false);
            formik.setFieldValue('street', invoiceAddress.street, false);
            formik.setFieldValue('houseNumber', invoiceAddress.houseNumber, false);
            formik.setFieldValue('additionalInformation', invoiceAddress.additionalInformation, false);
            formik.setFieldValue('postalCode', invoiceAddress.postalCode, false);
            formik.setFieldValue('city', invoiceAddress.city, false);
            setUserExists(true);
        } else if (user) {
            formik.setFieldValue('firstName', user.firstName, false);
            formik.setFieldValue('lastName', user.lastName, false);
            formik.setFieldValue('eMail', user.eMail, false);
            setUserExists(true);
        } else if (invoiceAddress) {
            formik.setFieldValue('street', invoiceAddress.street).then(() => {
                formik.validateField('city');
            });
            formik.setFieldValue('houseNumber', invoiceAddress.houseNumber).then(() => {
                formik.validateField('city');
            });
            formik.setFieldValue('additionalInformation', invoiceAddress.additionalInformation).then(() => {
                formik.validateField('city');
            });
            formik.setFieldValue('postalCode', invoiceAddress.postalCode).then(() => {
                formik.validateField('city');
            });
            formik.setFieldValue('city', invoiceAddress.city).then(() => {
                formik.validateField('city');
            });
        }

        // Set all fields untouched for initially hidden validation errors
        formik.setTouched({
            firstName: false,
            lastName: false,
            street: false,
            houseNumber: false,
            additionalInformation: false,
            postalCode: false,
            city: false,
            eMail: false,
            password: false,
            passwordVerification: false,
        });

        setShowLoading(false);

    }, [user, invoiceAddress]);

    useEffect(() => {
        console.log("formik.isValid", formik.isValid);
        console.log("formik.errors", formik.errors);
        props.validate(formik.isValid);
    }, [formik.isValid]);

    useEffect(() => {
        formik.validateForm();
    }, [userExists]);

    return (
        <Box >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: 3,
                    p: {xs: 1, md: 3},
                    // width: `calc(100% - 24px)`,
                    boxSizing: "border-box"
                }}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <PageHeader title="PERSÖNLICHE DATEN" subtitle="Bitte geben Sie Ihre persönlichen Daten ein" />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        boxSizing: "border-box",
                        '& form': {
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                        },
                        [`& .${formLabelClasses.focused}`]: {
                            color: colors.grey[100],
                        },
                        [`& .${inputClasses.focused}`]: {
                            color: colors.grey[100] + ' !important',
                            '& fieldset': {
                                borderColor: colors.grey[100] + ' !important',
                            }
                        },
                    }}
                >
                    <Box
                        sx={{
                            width: {xs: '100%', md: '50%'},
                        }}
                    >
                        <Typography variant="body1" sx={{color: colors.grey[200]}}>Die Daten werden zur Rechnungserstellung benötigt und um mit Ihnen Kontakt aufnehmen zu können. Die Daten werden nicht an Dritte weitergegeben und nur für die Abwicklung der Bestellung und den anschließenden Support verwendet.</Typography>
                        <form
                            onSubmit={(event: React.FormEvent<UserDataFormElement>) => {
                                event.preventDefault();
                            }}
                            style={{width: '100%'}}
                        >
                            <Typography variant="h5" sx={{color: colors.grey[100], mt: 3}}>Name</Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    gap: 2,
                                    [theme.breakpoints.down('sm')]: {
                                        flexDirection: 'column',
                                    }
                                }}
                            >
                                <TextField
                                    required
                                    disabled={userExists || props.readOnly}
                                    variant="outlined"
                                    label="Vorname"
                                    name="firstName"
                                    sx={{width: {xs: '100%', md: '50%'}}}
                                    type={"text"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.firstName ?? ''}
                                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                    helperText={formik.touched.firstName && formik.errors.firstName}
                                />
                                <TextField
                                    required
                                    disabled={userExists || props.readOnly}
                                    variant="outlined"
                                    label="Nachname"
                                    name="lastName"
                                    sx={{width: {xs: '100%', md: '50%'}}}
                                    type={"text"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.lastName ?? ''}
                                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                    helperText={formik.touched.lastName && formik.errors.lastName}
                                />
                            </Box>
                            <Typography variant="h5" sx={{color: colors.grey[100], mt: 2}}>Adresse</Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    gap: 2,
                                    [theme.breakpoints.down('sm')]: {
                                        flexDirection: 'column',
                                    }
                                }}
                            >
                                <TextField
                                    required
                                    disabled={props.readOnly}
                                    variant="outlined"
                                    label="Straße"
                                    name="street"
                                    sx={{width: {xs: '100%', md: '75%'}}}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.street ?? ''}
                                    error={formik.touched.street && Boolean(formik.errors.street)}
                                    helperText={formik.touched.street && formik.errors.street}
                                />
                                <TextField
                                    required
                                    disabled={props.readOnly}
                                    variant="outlined"
                                    label="Hausnummer"
                                    name="houseNumber"
                                    sx={{width: {xs: '100%', md: '25%'}}}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.houseNumber ?? ''}
                                    error={formik.touched.houseNumber && Boolean(formik.errors.houseNumber)}
                                    helperText={formik.touched.houseNumber && formik.errors.houseNumber}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    gap: 2,
                                    [theme.breakpoints.down('sm')]: {
                                        flexDirection: 'column',
                                    }
                                }}
                            >
                                <TextField
                                    required
                                    disabled={props.readOnly}
                                    variant="outlined"
                                    label="Postleitzahl"
                                    name="postalCode"
                                    sx={{width: {xs: '100%', md: '25%'}}}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.postalCode ?? ''}
                                    error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
                                    helperText={formik.touched.postalCode && formik.errors.postalCode}
                                />
                                <TextField
                                    required
                                    disabled={props.readOnly}
                                    variant="outlined"
                                    label="Stadt"
                                    name="city"
                                    sx={{width: {xs: '100%', md: '75%'}}}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.city ?? ''}
                                    error={formik.touched.city && Boolean(formik.errors.city)}
                                    helperText={formik.touched.city && formik.errors.city}
                                />
                            </Box>
                            <TextField
                                variant="outlined"
                                disabled={props.readOnly}
                                label="Zusatz-Informationen"
                                name="additionalInformation"
                                placeholder="z.B. Appartmentnummer, Stockwerk, etc."
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.additionalInformation ?? ''}
                                error={formik.touched.additionalInformation && Boolean(formik.errors.additionalInformation)}
                                helperText={formik.touched.additionalInformation && formik.errors.additionalInformation}
                            />
                            <Typography variant="h5" sx={{color: colors.grey[100], mt: 2}}>Konto</Typography>
                            <Typography variant="body1" sx={{color: colors.grey[200]}}>Bitte geben Sie Ihre E-Mail Adresse und ein Passwort ein, mit dem Sie sich in Zukunft anmelden können, um den Status Ihrer Bestellung verfolgen zu können.</Typography>
                            <TextField
                                required
                                disabled={userExists || props.readOnly}
                                variant="outlined"
                                label="E-Mail"
                                name="eMail"
                                type="email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.eMail ?? ''}
                                error={formik.touched.eMail && Boolean(formik.errors.eMail)}
                                helperText={formik.touched.eMail && formik.errors.eMail}
                            />
                            <Box display={userExists? "none": "flex"}
                                sx={{
                                    flexDirection: "column",
                                    gap: 2
                                }}
                            >
                                <TextField
                                    required
                                    variant="outlined"
                                    label="Passwort"
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    autoComplete="off"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password ?? ''}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                    InputProps={{ // <-- This is where the toggle button is added.
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    tabIndex={-1}
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <TextField
                                    required
                                    variant="outlined"
                                    label="Passwort wiederholen"
                                    name="passwordVerification"
                                    type={showPassword ? "text" : "password"}
                                    autoComplete="off"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.passwordVerification ?? ''}
                                    error={formik.touched.passwordVerification && Boolean(formik.errors.passwordVerification)}
                                    helperText={formik.touched.passwordVerification && formik.errors.passwordVerification}
                                    InputProps={{ // <-- This is where the toggle button is added.
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    tabIndex={-1}
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Box>
                        </form>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default Data;