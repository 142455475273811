import {Alert, Box} from "@mui/material";

function ErrorMessage({ error, severity }: any) {
    return (
        <Box>
            <Alert severity={severity}>{error}</Alert>
        </Box>
    );
}

export default ErrorMessage;