import React from 'react';
import {Box, Step, stepClasses, stepConnectorClasses, StepLabel, Stepper, Typography, useTheme} from "@mui/material";
import {tokens} from "../../../theme";

function OrderStepper(props: any) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box sx={{ width: '100%', mt: 2 }}>
            <Stepper activeStep={props.activeStep} alternativeLabel
                sx={{
                    [`& .${stepConnectorClasses.completed}`]: {
                        color: colors.greenAccent[400] + "!important",
                    },
                    [`& .${stepConnectorClasses.active}`]: {
                        color: colors.blueAccent[400] + "!important",
                    }
                }}
            >
                {props.steps.map((label: string, index: number) => {
                    const labelProps: {
                        optional?: React.ReactNode;
                        error?: boolean;
                    } = {};
                    if (props.isStepFailed(index)) {
                        labelProps.optional = (
                            <Typography variant="caption" color="error">
                                Bitte überprüfen Sie Ihre Eingaben.
                            </Typography>
                        );
                        labelProps.error = true;
                    }

                    return (
                        <Step key={label}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </Box>
    );
}

export default OrderStepper;