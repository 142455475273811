import React from 'react';
import {Box, Divider, Typography, useTheme} from "@mui/material";
import {IProduct} from "../../../../types/ProductTypes";
import IOrder from "../../../../types/OrderType";
import {tokens} from "../../../../theme";
import {Currency} from "../../../../types/PriceType";
import MultilineText from "../../../../components/MultilineText";

function BackendOrderItems(props: any) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const getProductsFromOrder = (filteredProducts: IProduct[], order: IOrder) => {
        const orderItems = order.orderItems;
        const result: IProduct[] = [];

        if(orderItems) {
            for (const orderItem of orderItems) {
                if(props.child?._id) {
                    if(orderItem.childId === props.child._id) {
                        if (filteredProducts && filteredProducts.find(product => product._id === orderItem.productId)) {
                            result.push(filteredProducts.find(product => product._id === orderItem.productId) as IProduct);
                        }
                    }
                } else {
                    if (filteredProducts && filteredProducts.find(product => product._id === orderItem.productId)) {
                        result.push(filteredProducts.find(product => product._id === orderItem.productId) as IProduct);
                    }
                }
            }
        }

        return result;
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: 2,
            }}
        >
            {getProductsFromOrder(props.devices, props.order).length > 0 &&
                (
                    <>
                        <SummaryCategory title="Geräte" />
                        {getProductsFromOrder(props.devices, props.order).map((device: IProduct, index: number) => (
                            <SummaryItem key={index} product={device}/>
                        ))}
                    </>
                )
            }
            {getProductsFromOrder(props.accessories, props.order).length > 0 &&
                (
                    <>
                        <SummaryCategory title="Zubehör" />
                        {getProductsFromOrder(props.accessories, props.order).map((device: IProduct, index: number) => (
                            <SummaryItem key={index} product={device}/>
                        ))}
                    </>
                )
            }
            {getProductsFromOrder(props.services, props.order).length > 0 &&
                (
                    <>
                        <SummaryCategory title="Dienste" />
                        {getProductsFromOrder(props.services, props.order).map((device: IProduct, index: number) => (
                            <SummaryItem key={index} product={device}/>
                        ))}
                    </>
                )
            }
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mt: 1
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        gap: 2,
                        width: {xs: "100%", md: "35%"},
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            backgroundColor: colors.primary[300],
                            borderRadius: 3,
                            p: 2,
                        }}
                    >
                        <Typography variant="h6">Gesamt netto</Typography>
                        <Box
                            sx={{
                                display: "flex",
                            }}
                        >
                            <Typography variant="h5" sx={{color: colors.greenAccent[400]}}>
                                {(((props.order?.totalPrice?.total * 100) - (props.order?.totalPrice?.tax * 100)) / 100).toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                            </Typography>
                            <Typography variant="h5" sx={{color: colors.greenAccent[400]}}>
                                {props.order?.totalPrice?.currency === Currency.USD ? '$' : '€'}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            // backgroundColor: colors.primary[400],
                            borderRadius: 3,
                            px: 2
                        }}
                    >
                        <Typography variant="h6">MwSt. 0%</Typography>
                        <Box
                            sx={{
                                display: "flex",
                            }}
                        >
                            <Typography variant="h5" sx={{color: colors.greenAccent[400]}}>
                                {props.order?.totalPrice?.tax.toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                            </Typography>
                            <Typography variant="h5" sx={{color: colors.greenAccent[400]}}>
                                {props.order?.totalPrice?.currency === Currency.USD ? '$' : '€'}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            backgroundColor: colors.primary[300],
                            borderRadius: 3,
                            p: 2,
                        }}
                    >
                        <Typography variant="h6">Gesamt brutto</Typography>
                        <Box
                            sx={{
                                display: "flex",
                            }}
                        >
                            <Typography variant="h6" sx={{color: colors.greenAccent[400]}}>
                                {props.order?.totalPrice?.total.toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                            </Typography>
                            <Typography variant="h6" sx={{color: colors.greenAccent[400]}}>
                                {props.order?.totalPrice?.currency === Currency.USD ? '$' : '€'}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

function SummaryCategory(props: any) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: 2,
                backgroundColor: colors.primary[300],
                borderRadius: 3,
                p: 2,
                mt: 1
            }}
        >
            <Typography variant="h5" sx={{color: colors.grey[100]}}>{props.title}</Typography>
        </Box>
    );
}

function SummaryItem(props: any){
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            sx={{
                backgroundColor: colors.primary[300],
                borderRadius: 3,
                p: 2,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
            }}
        >
            {/* Picture */}
            <Box
                sx={{
                    width: {xs: "30%", md: 100}
                }}
            >
                <img src={props.product?.pictures[0]} alt={props.product?.name} style={{width: "100%", height: "100%", objectFit: "contain"}}/>
            </Box>
            <Divider orientation="vertical" flexItem sx={{color: colors.primary[100], mx: 3, borderWidth: "1.5px"}} />
            {/* Description */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: {xs: "column", md: "row"},
                    justifyContent: "space-between",
                    gap: 2,
                    width: {xs: "70%", md: "100%"}
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        gap: 1,
                    }}
                >
                    <Typography variant="h6" fontWeight="bold" sx={{color: colors.grey[100]}}>{props.product?.name}</Typography>
                    <Typography variant="body1" sx={{color: colors.grey[100]}}>
                        {props.product?.color ? ('Farbe: ' + props.product?.color) : undefined}
                    </Typography>
                    <Typography variant="body1" sx={{color: colors.grey[100]}}>
                        {props.product?.storage ? ('Speicher: ' + props.product?.storage) : undefined}
                    </Typography>
                </Box>

                {/* Price */}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: {xs: "row", md: "column"},
                        justifyContent: "flex-start",
                        alignContent: "flex-end",
                        minWidth: {xs: undefined, md: "50px"}
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                        }}
                    >
                        <Box
                            sx={{
                                display: {xs: "none", md: "flex"},
                            }}
                        >
                            <Typography variant="h5" sx={{color: colors.greenAccent[400]}}>
                                {props.product?.price.total.toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                            </Typography>
                            <Typography variant="h5" sx={{color: colors.greenAccent[400]}}>
                                {props.product?.price.currency === Currency.USD ? '$' : '€'}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: {xs: "flex", md: "none"},
                            }}
                        >
                            <Typography variant="h6" sx={{color: colors.greenAccent[400]}}>
                                {props.product?.price.total.toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                            </Typography>
                            <Typography variant="h6" sx={{color: colors.greenAccent[400]}}>
                                {props.product?.price.currency === Currency.USD ? '$' : '€'}
                            </Typography>
                        </Box>
                    </Box>
                    {/*<Typography variant="h6" sx={{color: colors.grey[100], ml: {xs: 1, md: 0}}}>{"inkl. "+props.product?.price.tax.toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2}) + (props.product?.price.currency === Currency.USD ? '$' : '€') + " MwSt."}</Typography>*/}
                </Box>
            </Box>
        </Box>
    );
}

export default BackendOrderItems;