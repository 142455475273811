import React, {useEffect, useRef, useState} from 'react';
import {Box, Button, Typography, useTheme} from "@mui/material";
import {tokens} from "../../theme";
import PageHeader from "../../components/PageHeader";
import {
    AddCircleOutlined, AspectRatioOutlined, Battery50Outlined, CameraOutlined, CandlestickChartOutlined,
    CheckCircleOutlined,
    CircleOutlined, DevicesOutlined, DomainOutlined,
    DoneOutline, FactoryOutlined, InstallMobileOutlined, MemoryOutlined,
    NavigateBeforeOutlined, PhotoCameraOutlined, ScheduleOutlined, StorageOutlined,
} from "@mui/icons-material";
import Specifications from "./components/Specifications";
import Category from "./components/Category";
import Product from "./components/Product";
import {ITablet, IProductVariant, IProductWithVariants, ProductType} from "../../types/ProductTypes";
import Price from "../../types/PriceType";
import {useLoadingContext} from "../../components/LoadingProvider";

function Devices(props: any) {
    const [devices, setDevices] = useState<IProductWithVariants[]>([]);
    const [selection, setSelection] = useState<Map<string, string>>(new Map<string, string>());
    const [givenSelection, setGivenSelection] = useState<Map<string, string>>(new Map<string, string>());
    const [formValid, setFormValid] = useState<boolean>(false);

    const selectionRef = useRef();
    const formValidRef = useRef<boolean>(false);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {setShowLoading}: any = useLoadingContext();

    useEffect(() => {
        aggregateDevices(props.tablets);

        if(props.tablets){
            setShowLoading(false);
        }
    }, [props.tablets]);

    useEffect(() => {
        (selectionRef as any).current = selection;
    }, [selection]);

    useEffect(() => {
        formValidRef.current = formValid;
    }, [formValid]);

    useEffect(() => {
        if(selection && selection.size > 0) {
            if (selection.size === props.students.length) {
                props.validate(true);
                setFormValid(true);
            } else {
                if (props.requiredProductCategories) {
                    if (props.requiredProductCategories.includes(ProductType.tablet)) {
                        props.validate(false);
                        setFormValid(false);
                    } else {
                        props.validate(true);
                        setFormValid(true);
                    }
                }
            }
        } else if(givenSelection && givenSelection.size > 0) {
            if (givenSelection.size === props.students.length) {
                props.validate(true);
                setFormValid(true);
            } else {
                if (props.requiredProductCategories) {
                    if (props.requiredProductCategories.includes(ProductType.tablet)) {
                        props.validate(false);
                        setFormValid(false);
                    } else {
                        props.validate(true);
                        setFormValid(true);
                    }
                }
            }
        } else {
            let valid: boolean = true;
            if(props.requiredProductCategories) {
                valid = props.requiredProductCategories.includes(ProductType.tablet);
            }

            props.validate(!valid);
            setFormValid(!valid);
        }

        if(props.readOnly) {
            props.validate(true);
            setFormValid(true);
        }
    }, [selection, givenSelection]);

    useEffect(() => {
        return () => {
            if (formValidRef.current && !props.readOnly) {
                props.onSelection([selectionRef.current]);
            }
        }
    }, []);

    useEffect(() => {
        if (props.selections) {
            setGivenSelection(props.selections);
        }
    }, [props.selections]);

    // ----- HELPER METHODS -----//
    const aggregateDevices = (tablets: ITablet[]) => {
        const temp: IProductWithVariants[] = [];

        for (const tablet of tablets) {
            if(!temp.find((item: IProductWithVariants) => item.product.brand === tablet.brand && (item.product as ITablet).modelSeries === tablet.modelSeries && (item.product as ITablet).modelGeneration === tablet.modelGeneration)) {
                const variants: IProductVariant[] = [];
                const variant: IProductVariant = {
                    _id: tablet._id ?? tablet.name,
                    color: tablet.color,
                    storage: tablet.storage,
                    price: tablet.price,
                    modelIdentifier: tablet.modelIdentifier,
                }
                variants.push(variant);
                temp.push({product: tablet, variants: variants});
            } else {
                const index = temp.findIndex((item: IProductWithVariants) => item.product.brand === tablet.brand && (item.product as ITablet).modelSeries === tablet.modelSeries && (item.product as ITablet).modelGeneration === tablet.modelGeneration);
                const variant: IProductVariant = {
                    _id: tablet._id ?? tablet.name,
                    color: tablet.color,
                    storage: tablet.storage,
                    price: tablet.price,
                    modelIdentifier: tablet.modelIdentifier,
                }
                temp[index].variants.push(variant);
            }
        }

        setDevices(temp);
    }

    const deviceSelectionHandler = (deviceSelection: Map<string, string>, variants: IProductVariant[]) => {
        if(deviceSelection) {
            // Concatenate the selected device with the current selection
            const temp = new Map<string, string>();

            if(givenSelection && selection.size === 0){
                givenSelection.forEach((value: string, key: string) => {
                    temp.set(key, value);
                });
            }
            selection.forEach((value: string, key: string) => {
                temp.set(key, value);
            });
            console.log("deviceSelection: ", deviceSelection);
            if(deviceSelection.size > 0) {
                variants.forEach((variant: IProductVariant) => {
                    temp.forEach((value: string, key: string) => {
                        if (value === variant._id) {
                            temp.delete(key);
                        }
                    });
                });
            }
            deviceSelection.forEach((value: string, key: string) => {
                temp.set(key, value);
            });
            setSelection(temp);
        }
    }

    return (
        <Box >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: 3,
                    p: {xs: 1, md: 3},
                    // width: `calc(100% - 24px)`,
                    boxSizing: "border-box"
                }}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <PageHeader title="GERÄT AUSWÄHLEN" subtitle="Bitte wählen Sie das gewünschte Gerät aus." />
                </Box>

                {devices.length > 0 ? (
                    <Category title="Tablets" isRequired={props.requiredProductCategories.includes(ProductType.tablet) ?? true}/>
                ) : (
                    <Box
                        sx={{
                            backgroundColor: colors.primary[400],
                            borderRadius: 3,
                            p: 3,
                        }}
                    >
                        <Typography variant="h5" sx={{color: colors.grey[100]}}>Es sind leider keine Geräte verfügbar.</Typography>
                    </Box>
                )}

                {devices.map((device: any, index: number) => {
                    return (
                        <Product key={index} product={device.product} variants={device.variants} students={props.students} onSelection={deviceSelectionHandler} selections={selection.size > 0 ? selection : givenSelection} readOnly={props.readOnly}/>
                    )
                })}
            </Box>
        </Box>
    );
}

export default Devices;