import React from "react";
import PageHeader from "../../components/PageHeader";
import {Box} from "@mui/material";

function BackendComingSoon(props: any) {

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <PageHeader title="COMING SOON" subtitle="Dieser Inhalt steht in Kürze zur Verfügung" />
            </Box>
        </Box>
    );
}

export default BackendComingSoon;