import React from 'react';
import {Box, Typography} from "@mui/material";

function MultilineText({text, variant, color}: any) {
    console.log("MultilineText: ", text);
    console.log("MultilineText: ", text?.split("\\n"));

    if(text) {
        return (
            <Box>
                {text?.split("\\n")?.map((i: string, key: number) => {
                    return <Typography variant={variant} key={key} color={color}>{i}</Typography>;
                })}
            </Box>
        );
    } else {
        return (<></>);
    }
}

export default MultilineText;