
// docs https://github.com/azouaoui-med/react-pro-sidebar
import {useEffect, useState} from "react";
import { Menu, Sidebar, MenuItem } from "react-pro-sidebar";
import { useProSidebar } from "react-pro-sidebar";

import { useSidebarContext } from "./sidebarContext";

import { Link } from "react-router-dom";
import { tokens } from "../../../theme";
import { useTheme, Box, Typography, IconButton } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import {useUserContext} from "../../../api/provider/UserProvider";
import {useLoadingContext} from "../../../components/LoadingProvider";
import {
    CorporateFareOutlined, CreditCardOutlined,
    DevicesOutlined,
    EscalatorWarningOutlined, Groups2Outlined,
    ImportantDevicesOutlined, InfoOutlined, InventoryOutlined,
    ReceiptLongOutlined, VisibilityOutlined
} from "@mui/icons-material";
const Item = ({ title, to, icon, selected, setSelected }: any) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <MenuItem
            active={selected === title}
            style={{ color: colors.grey[100] }}
            onClick={() => setSelected(title)}
            icon={icon}
            routerLink={<Link to={to} />}
        >
            <Typography>{title}</Typography>
        </MenuItem>
    );
};

const MyProSidebar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [selected, setSelected] = useState("Dashboard");
    const { sidebarRTL, setSidebarRTL, sidebarImage }: any = useSidebarContext();
    const { collapseSidebar, toggleSidebar, collapsed, broken } = useProSidebar();
    const { user }: any = useUserContext();
    const [ userName, setUserName ] = useState<string>("");

    const {setShowLoading}: any = useLoadingContext();

    useEffect(() => {
        setShowLoading(false);
    }, []);

    useEffect(() => {
        if (user) {
            setUserName(user.firstName + ' ' + user.lastName);
        }
    }, [user]);

    return (
        <Box
            sx={{
                position: "sticky",
                display: "flex",
                height: "100vh",
                top: 0,
                bottom: 0,
                zIndex: 10000,
                "& .sidebar": {
                    border: "none",
                },
                "& .menu-icon": {
                    backgroundColor: "transparent !important",
                },
                "& .menu-item": {
                    // padding: "5px 35px 5px 20px !important",
                    backgroundColor: "transparent !important",
                },
                "& .menu-anchor": {
                    color: "inherit !important",
                    backgroundColor: "transparent !important",
                },
                "& .menu-item:hover": {
                    color: `${colors.blueAccent[500]} !important`,
                    backgroundColor: "transparent !important",
                },
                "& .menu-item.active": {
                    color: `${colors.greenAccent[500]} !important`,
                    backgroundColor: "transparent !important",
                },
            }}
        >
            <Sidebar
                breakPoint="md"
                backgroundColor={colors.primary[400]}
                image={sidebarImage}
            >
                <Menu>
                    <MenuItem
                        icon={collapsed ? (
                            <MenuOutlinedIcon />
                        ) : undefined}
                        style={{
                            margin: "10px 0 20px 0",
                            color: colors.grey[100],
                        }}
                        onClick={collapsed ? (() => collapseSidebar()): undefined}
                    >
                        {!collapsed && (
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                ml="15px"
                            >
                                <Typography variant="h3" color={colors.grey[100]}>
                                    Bestellportal
                                </Typography>
                                <IconButton
                                    onClick={
                                        broken ? () => toggleSidebar() : () => collapseSidebar()
                                    }
                                >
                                    <CloseOutlinedIcon />
                                </IconButton>
                            </Box>
                        )}
                    </MenuItem>
                    {/*{!collapsed && (*/}
                    {/*    <Box mb="25px">*/}
                    {/*        <Box*/}
                    {/*            display="flex"*/}
                    {/*            justifyContent="center"*/}
                    {/*            alignItems="center"*/}
                    {/*            sx={{*/}
                    {/*                "& .avater-image": {*/}
                    {/*                    backgroundColor: colors.primary[500],*/}
                    {/*                },*/}
                    {/*            }}*/}
                    {/*        >*/}
                    {/*            <img*/}
                    {/*                className="avater-image"*/}
                    {/*                alt="profile user"*/}
                    {/*                width="100px"*/}
                    {/*                height="100px"*/}
                    {/*                src={"../../assets/user.png"}*/}
                    {/*                style={{ cursor: "pointer", borderRadius: "50%" }}*/}
                    {/*            />*/}
                    {/*        </Box>*/}
                    {/*        <Box textAlign="center">*/}
                    {/*            <Typography*/}
                    {/*                variant="h3"*/}
                    {/*                color={colors.grey[100]}*/}
                    {/*                fontWeight="bold"*/}
                    {/*                sx={{ m: "10px 0 0 0" }}*/}
                    {/*            >*/}
                    {/*                {userName}*/}
                    {/*            </Typography>*/}
                    {/*        </Box>*/}
                    {/*    </Box>*/}
                    {/*)}*/}
                    <Box paddingLeft={collapsed ? undefined : "10%"}>
                        <Item
                            title="Dashboard"
                            to="/"
                            icon={<HomeOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />

                        <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{ m: "15px 20px 5px 20px" }}
                        >
                            Organisation
                        </Typography>
                        <Item
                            title="Organisation"
                            to="/organisation"
                            icon={<InfoOutlined />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Produktsichtbarkeit"
                            to="/products/visibility"
                            icon={<VisibilityOutlined />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Bestellungen"
                            to="/orders"
                            icon={<ReceiptLongOutlined />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Produkt-Bestellungen"
                            to="/orders/products"
                            icon={<DevicesOutlined />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Kunden"
                            to="/customers"
                            icon={<PeopleOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Schüler*innen"
                            to="/students"
                            icon={<EscalatorWarningOutlined />}
                            selected={selected}
                            setSelected={setSelected}
                        />

                        <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{ m: "15px 20px 5px 20px" }}
                        >
                            Administration
                        </Typography>
                        <Item
                            title="Produkte verwalten"
                            to="/products"
                            icon={<ImportantDevicesOutlined />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Organisationen"
                            to="/organisations"
                            icon={<CorporateFareOutlined />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Benutzer verwalten"
                            to="/users"
                            icon={<Groups2Outlined />}
                            selected={selected}
                            setSelected={setSelected}
                        />

                        <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{ m: "15px 20px 5px 20px" }}
                        >
                            Integrationen
                        </Typography>
                        <Item
                            title="Snipe IT"
                            to="/snipe-it"
                            icon={<InventoryOutlined />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="GoCardless"
                            to="/gocardless"
                            icon={<CreditCardOutlined />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                    </Box>
                </Menu>
            </Sidebar>
        </Box>
    );
};

export default MyProSidebar;