import React from "react";
import PageHeader from "../../../../components/PageHeader";
import {Box, useTheme} from "@mui/material";
import {DataGrid, GridToolbarContainer, GridToolbarExport} from "@mui/x-data-grid";
import {useUserContext} from "../../../../api/provider/UserProvider";
import {tokens} from "../../../../theme";
import {UserRole} from "../../../../types/UserTypes";

function BackendCustomers(props: any) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { users, filterUsersByRole }: any = useUserContext();

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    const columns = [
        {
            field: "_id",
            headerName: "ID",
            flex: 1,
            minWidth: 200
        },
        {
            field: "firstName",
            headerName: "Vorname",
            flex: 0.5,
            minWidth: 150
        },
        {
            field: "lastName",
            headerName: "Nachname",
            flex: 0.5,
            minWidth: 150
        },
        {
            field: "eMail",
            headerName: "E-Mail",
            flex: 1,
            minWidth: 200
        },
        {
            field: "organisation",
            headerName: "Organisation",
            flex: 1,
            minWidth: 200
        },
        {
            field: "children",
            headerName: "Anzahl Kinder",
            flex: 0.5,
            valueGetter: (params: any) => {
                return params.row.children?.length ?? 0;
            },
            minWidth: 50
        }
    ];

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <PageHeader title="KUNDEN & KUNDINNEN" subtitle="Informationen über Ihre Kundinnen und Kunden." />
            </Box>

            <Box
                sx={{
                    mt: 6,
                    height: "80vh",
                    "& .MuiDataGrid-root": {
                        border: "none"
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none"
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.primary[400],
                        color: colors.greenAccent[100],
                        borderBottom: "none",
                        borderRadius: "10px 10px 0 0"
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: theme.palette.background.default,
                    },
                    "& .MuiDataGrid-footerContainer": {
                        backgroundColor: colors.primary[400],
                        color: colors.greenAccent[100],
                        borderTop: "none",
                        borderRadius: "0 0 10px 10px"
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.greenAccent[200]} !important`
                    }
                }}
            >
                <DataGrid
                    loading={!(users.length > 0)}
                    getRowId={(row) => row._id}
                    columns={columns}
                    rows={filterUsersByRole(users, UserRole.customer)}
                    slots={{
                        toolbar: CustomToolbar
                    }}
                />
            </Box>
        </Box>
    );
}

export default BackendCustomers;