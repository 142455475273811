import {Box, Button, Typography, useTheme} from "@mui/material";
import {tokens} from "../../../theme";
import Specifications from "./Specifications";
import React, {useEffect, useState} from "react";
import {
    IProduct,
    IProductVariant,
    IProductWithVariants,
    IService,
    ITablet,
    ProductType
} from "../../../types/ProductTypes";
import {AddCircleOutlined, EditOutlined, PersonOutline} from "@mui/icons-material";
import Price, {Currency} from "../../../types/PriceType";
import ProductSelectionPopup from "./ProductSelectionPopup";
import {IUserChild} from "../../../types/UserTypes";
import MultilineText from "../../../components/MultilineText";

function Product(props: any) {
    const [product, setProduct] = useState<IProduct | null>(null);
    const [variants, setVariants] = useState<IProductVariant[]>([]);
    const [lowestPrice, setLowestPrice] = useState<Price>({
        total: 0,
        tax: 0,
        currency: Currency.EUR
    } as Price);
    const [color, setColor] = useState<string>();
    const [storage, setStorage] = useState<string>();

    const [openPopup, setOpenPopup] = useState(false);
    // const [selections, setSelections] = useState<Map<string, string>>(new Map());

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const displayToText = (product: IProduct | null): string | undefined => {
        if(product) {
            if(product.type === ProductType.tablet) {
                let result: string = (product as ITablet)?.display?.displaySize + ", " + (product as ITablet)?.display?.displayResolution + ", " + (product as ITablet)?.display?.displayTechnology;
                return result;
            }
        }

        return undefined;
    }

    useEffect(() => {
        setProduct(props.product);
        setVariants(props.variants);
    }, [props.product]);

    useEffect(() => {
        // Calculate the lowest price of a product
        let lowestPrice: Price = {
            total: 0,
            tax: 0,
            currency: Currency.EUR
        }

        for (const productElement of variants) {
            if (lowestPrice.total === 0 || productElement.price.total < lowestPrice.total) {
                lowestPrice = productElement.price;
            }
        }
        setLowestPrice(lowestPrice);

        // Aggregate all colors & storages of a product
        const temp: string[] = [];
        const tempStorage: string[] = [];
        for (const variant of variants) {
            if (!temp.includes(variant.color as string)) {
                temp.push(variant.color as string);
            }
            if(product?.type === ProductType.tablet) {
                // @ts-ignore
                if (!tempStorage.includes(variant.storage)) {
                    // @ts-ignore
                    tempStorage.push(variant.storage);
                }
            }
        }
        temp.sort((a, b) => {
            return a < b ? -1 : a > b ? 1 : 0;
        });
        tempStorage.sort((a, b) => {
            if(a.length < b.length) return -1;
            if(a.length > b.length) return 1;
            return a < b ? -1 : a > b ? 1 : 0;
        });
        setColor(temp.join(", "));
        setStorage(tempStorage.join(", "));
    }, [variants]);

    const filterSelection = (selections: Map<string, string>): Map<string, string> => {
        if(props.selections) {
            const map: Map<string, string> = new Map();

            // Filter out the variants that are not in set
            props.selections.forEach((value: string, key: string) => {
                if(variants.find((variant: IProductVariant) => variant._id === value)) {
                    map.set(key, value);
                }
            });

            return map;
        }

        return new Map<string, string>();
    };

    const selectedByStudents = (selection: Map<string, string>): string[] => {
        if(selection) {
            const result: string[] = [];
            selection.forEach((value, key) => {
                if(variants.find((variant) => variant._id === value)) {
                    result.push(props.students.find((student: IUserChild) => student._id === key)?.firstName);
                }
            });
            return result;
        } else {
            return [];
        }
    }

    return (
        <>
            {/* Students who selected product */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mr: 3,
                    mb: {xs: -6, md: -3},
                    mt: {xs: 3, md: 0},
                    gap: 1,
            }}

            >
                {selectedByStudents(filterSelection(props.selections)).map((student: string, index: number) => (
                    <Box
                        key={index}
                        sx={{
                            backgroundColor: colors.blueAccent[400],
                            borderTopLeftRadius: 3,
                            borderTopRightRadius: 3,
                            pl: 1,
                            pr: 1,
                            pt: "1px",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <PersonOutline sx={{color: '#fff'}} />
                        <Typography color={'#fff'}>{student}</Typography>
                    </Box>
                ))}
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "stretch",
                    width: "100%",
                    boxSizing: "border-box",
                    gap: 3,
                    flexWrap: {xs: "wrap", md: "nowrap"},
                    mt: {xs: 3, md: 0},
                    color: colors.grey[100],
                    borderRadius: 3,
                    backgroundColor: selectedByStudents(filterSelection(props.selections)).length > 0 ? "transparent" : "transparent",
                }}
            >
                { /* Image */ }
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: {xs: "100%", md: 300},
                        minHeight: 300,
                        maxHeight: {xs: 300, md: 500},
                        borderRadius: 3,
                        backgroundColor: colors.primary[400],
                        p: 3,
                        boxSizing: "border-box",
                        border: selectedByStudents(filterSelection(props.selections)).length > 0 ? "3px solid" : "3px hidden",
                        borderColor: colors.blueAccent[400],
                    }}
                >
                    <img src={product?.pictures[0]} alt={product?.name} style={{width: "100%", height: "100%", objectFit: "contain"}}/>
                </Box>
                { /* Text & Preis */ }
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "stretch",
                        width: {xs: "100%", md: "calc(100% - 300px - 24px)"},
                        backgroundColor: colors.primary[400],
                        borderRadius: 3,
                        p: 3,
                        boxSizing: "border-box",
                        gap: 2,
                        flexDirection: {xs: "column", sm: "row"},
                        border: selectedByStudents(filterSelection(props.selections)).length > 0 ? "3px solid" : "3px hidden",
                        borderColor: colors.blueAccent[400],
                    }}
                >
                    { /* Text */ }
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            flexDirection: "column",
                            gap: 3,
                            width: "100%",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                flexDirection: "column",
                                gap: 1
                            }}
                        >
                            <Typography variant="h3" sx={{color: colors.primary[100]}}>{product?.name}</Typography>
                            <MultilineText text={product?.description} variant="body1" color={colors.primary[100]}/>
                            {/*<Typography variant="body1" sx={{color: colors.primary[100]}}>{product?.description}</Typography>*/}
                        </Box>
                        <Specifications
                            os={(product as ITablet)?.operatingSystem}
                            cpu={(product as ITablet)?.processor}
                            ram={(product as ITablet)?.ram}
                            storage={storage}
                            battery={(product as ITablet)?.battery}
                            display={displayToText(product)}
                            camera={(product as ITablet)?.camera}
                            brand={(product?.type !== ProductType.tablet) ? product?.brand : undefined}
                            colors={color}
                            runtime={(product as IService)?.duration}
                            fitsFor={(product as IService)?.fitsFor}
                            variant={(product as IService)?.variant}
                        />
                    </Box>

                    { /* Preis */ }
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: {xs: "flex-end", sm: "center"},
                            flexDirection: "column",
                            gap: 3,
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "flex-end",
                                flexDirection: "column",
                                gap: 1,
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "baseline",
                                    gap: 1
                                }}
                            >
                                <Typography variant="h3" sx={{color: colors.primary[100]}}>Ab </Typography>
                                <Typography variant="h2" sx={{color: colors.greenAccent[400]}}>
                                    {lowestPrice.total.toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                </Typography>
                                <Typography variant="h2" sx={{color: colors.greenAccent[400]}}>
                                    {product?.price.currency === Currency.USD ? '$' : '€'}
                                </Typography>
                            </Box>
                            {/*<Typography variant="h5" sx={{color: colors.primary[100]}}>{"inkl. " + product?.price.tax.toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2}) + (props.product?.price.currency === Currency.USD ? '$' : '€') +" MwSt."}</Typography>*/}
                        </Box>
                        <Button
                            color="primary"
                            onClick={() => {setOpenPopup(true); }}
                            // sx={{display: {xs: 'none', md: 'block'}}}
                            sx={{
                                backgroundColor: colors.greenAccent[600],
                                color: colors.grey[100],
                                [`:focus`]: {
                                    backgroundColor: colors.greenAccent[600],
                                },
                                [`:hover`]: {
                                    backgroundColor: { xs: colors.greenAccent[600], sm: colors.greenAccent[700]},
                                },
                                height: "50px",
                                width: {xs: "100%", sm: "150px"},
                                display: props.hidden ? "none" : undefined,
                            }}
                            disabled={props.readOnly || props.disabled}
                        >
                            {selectedByStudents(filterSelection(props.selections)).length > 0 ? <EditOutlined /> : <AddCircleOutlined />}
                            <Typography sx={{mr: "5px", ml: "5px"}}>{selectedByStudents(filterSelection(props.selections)).length > 0 ? "Anpassen" : "Auswählen"}</Typography>
                        </Button>
                    </Box>
                </Box>
            </Box>

            <ProductSelectionPopup
                open={openPopup}
                onClose={() => {setOpenPopup(false);}}
                onSubmit={(selection: Map<string, string>) => {
                    setOpenPopup(false);

                    if(selection.size > 0){
                        props.onSelection(selection, variants);
                    }
                }}
                variants={variants}
                students={props.students}
                selections={filterSelection(props.selections)}
                product={product}
            />
        </>
    );
}

export default Product;