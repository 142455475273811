import React, { useState, createContext, useContext } from "react";
// @ts-ignore
import { ProSidebarProvider } from "react-pro-sidebar";
import MyProSidebar from "./MyProSidebar";

const SidebarContext = createContext({});

export const MyProSidebarProvider = ({ children }: any) => {
    const [sidebarBackgroundColor, setSidebarBackgroundColor] =
        useState(undefined);
    const [sidebarImage, setSidebarImage] = useState(undefined);
    return (
        <ProSidebarProvider>
            <SidebarContext.Provider
                value={{
                sidebarBackgroundColor,
                    setSidebarBackgroundColor,

                    sidebarImage,
                    setSidebarImage,
            }}
            >
                <div
                    style={{
                    display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <MyProSidebar />
                    {children}
                </div>
            </SidebarContext.Provider>
        </ProSidebarProvider>
    );
};

export const useSidebarContext = () => useContext(SidebarContext);