import Price from "./PriceType";
import Display from "./DisplayType";

export interface IProduct {
    _id?: string;
    name: string;
    description: string;
    brand: string;
    pictures: string[];
    price: Price;
    type: ProductType;
}

export interface ITablet {
    _id?: string;
    name: string;
    description: string;
    brand: string;
    color: string;
    pictures: string[];
    price: Price;
    type: ProductType;
    modelIdentifier: string;
    storage: string;
    processor: string;
    ram: string;
    battery: string;
    camera: string;
    modelSeries: string;
    modelGeneration: string;
    operatingSystem: string;
    display: Display;
}

export interface IAccessory {
    _id?: string;
    name: string;
    description: string;
    brand: string;
    color?: string;
    pictures: string[];
    price: Price;
    type: ProductType;
    fitsFor: string[];
}

export interface IService {
    _id?: string;
    name: string;
    description: string;
    brand: string;
    color: string;
    pictures: string[];
    price: Price;
    type: ProductType;
    fitsFor: Array<string>;
    duration: string;
    variant?: string;
}

export enum ProductType {
    tablet = 'tablet',
    case = 'case',
    pencil = 'pencil',
    otherAccessories = 'otherAccessories',
    insurance = 'insurance',
    software = 'software',
    deviceSecurity = 'deviceSecurity',
    internetSecurity = 'internetSecurity',
}

export interface IProductVariant {
    _id: string;
    color?: string;
    storage?: string;
    price: Price;
    modelIdentifier: string;
}

export interface IProductWithVariants {
    product: ITablet | IAccessory | IService;
    variants: IProductVariant[];
}