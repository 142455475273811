import React from "react";
import { Box, IconButton, useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import {useProSidebar} from "react-pro-sidebar";
import {useAuthContext} from "../../api/provider/AuthProvider";

function Topbar(props:any ) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const { toggleSidebar, broken } = useProSidebar();
    const { logout }: any = useAuthContext();

    return (
        <Box display="flex" justifyContent="space-between" p={2}>
            {/* Menu Button */}
            <Box display="flex">
                {broken && (
                    <IconButton
                        sx={{ margin: "0 6 0 2", pr: "15px" }}
                        onClick={() => toggleSidebar()}
                    >
                        <MenuOutlinedIcon />
                    </IconButton>
                )}

                {/* Search Bar */}
                <Box sx={{display: "flex",
                    backgroundColor: colors.primary[400],
                    borderRadius: "3px"}}
                >
                    <InputBase sx={{ ml: 2, flex: 1}} placeholder="Search"></InputBase>
                    <IconButton sx={{ p: '1' }}>
                        <SearchIcon />
                    </IconButton>
                </Box>
            </Box>
            {/* Icons */}
            <Box display="flex">
                <IconButton onClick={colorMode.toggleColorMode}>
                    {theme.palette.mode === "dark" ? <DarkModeOutlinedIcon /> : <LightModeOutlinedIcon />}
                </IconButton>
                <IconButton>
                    <NotificationsOutlinedIcon />
                </IconButton>
                <IconButton>
                    <SettingsOutlinedIcon />
                </IconButton>
                <IconButton onClick={ logout }>
                    <LogoutOutlinedIcon />
                </IconButton>
            </Box>
        </Box>
    );
}

export default Topbar;